import { toJS, runInAction } from 'mobx';
import { validateExtensionImage } from 'System/utilities/files.js';
import langstore from '../../system/i18n/translator.js';


let messageModel = {
  pictures: [],
  contents: [],
  messageType: "order",
  messageCategory: "*",
  to: null,
  cc: null,
  validations: [],
  parentid: null
};



const getMessageUsers = async function () {

  if (!this.config.showMessaggi) {
    return;
  }

  let c_utente = this.userInfo.c_utente;
  let users = [];
  let usersPortali = [];

  let orderDetails = this.dataOrder.dettaglioOrdine || {};
  let { c_corrispondente_ordine, email_corrispondente, ds_corrispondente_ordine } = orderDetails;
  let { c_cliente } = orderDetails;

  // Info cliente in testata ordine
  let usersAzienda = [{
    t: "GEMO",
    label: "Azienda",
    id: c_corrispondente_ordine,
    email: email_corrispondente,
    ds: ds_corrispondente_ordine
  }];

  // Info clienti/agenti da API
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'messaggi/utenti',
    cacheAge: 1,
    userToken: this.userToken,
    params: {
      c_cliente
    }
  });

  if (response && response.data && response.data.utenti) {
    usersPortali = response.data.utenti[0]
      .filter(u => u.c_utente != c_utente)
      .map((u) => {
        return ({
          t: "AGENTE",
          label: "Cliente",
          id: u.c_utente,
          email: u.e_mail_utente,
          ds: u.ds_utente
        })
      });
  }


  users = [...usersAzienda, ...usersPortali];
  this.messageUsers = users;
  return;
}

const getAttachmentData = function (p) {
  let url = this.config.paths.apiURL + "messaggi/allegati?t=" + this.userToken + "&a=" + btoa(p);
  let attachmentExtension = p.split('.').pop();
  let attachmentUrl = url;
  let attachmentPreviewUrl = p;
  if (p.search("png") > 0 || p.search("jpg") > 0 || p.search("jpeg") > 0) {
    attachmentPreviewUrl = url;
  }
  let attachmentType = (attachmentUrl == attachmentPreviewUrl ? "image" : "file");

  return {
    attachmentUrl,
    attachmentPreviewUrl,
    attachmentExtension,
    attachmentType
  };
}

const updateMessageContent = function (data) {
  let content = toJS(this.messageWriting);
  let final = Object.assign(content, data);
  this.messageWriting = final;
}

const updateMessageRowContent = function (data) {
  let content = toJS(this.messageRowsWriting);
  if (data.hasOwnProperty("content")) {
    content.content = data.content;
  }
  if (data.item) {
    content.items[data.item] = data.value;
  }
  this.messageRowsWriting = content;
}

const removeRowsFromMessage = function (key) {
  let message = toJS(this.messageWriting);
  let contents = message.contents;
  contents.splice(key, 1);
  message.contents = contents;
  this.messageWriting = message;
}

const addRowsToMessage = function () {

  let isValid = this.validateMessageRows();
  if (isValid == false) {
    return;
  }

  let message = toJS(this.messageWriting);
  let content = toJS(this.messageRowsWriting);
  let messageKey = content.messageKey;

  let items = Object.keys(content.items).reduce((tot, k) => {
    if (content.items[k] !== false) {
      tot[k] = true;
    }
    return tot;
  }, {});
  content.items = items;

  // insert new item
  if (typeof messageKey !== "undefined") {
    message.contents[messageKey] = content;
  }
  // replace old item
  else {
    message.contents.push(content);
  }

  this.messageWriting = message;

  console.log("ddd", message);

  console.log("dddd", "addRowsToMessage");


  this.modalClose();
}

const editMessageRows = function (key) {

  let message = toJS(this.messageWriting);
  let contents = message.contents;
  let messageContent = contents[key];

  this.messageRowsWriting = {
    content: messageContent.content,
    items: messageContent.items,
    messageKey: key
  };

  this.modalOpen("ModalMessageRows");
}

const initOrderForMessages = function () {
  this.messageActive = null;
  this.messageUsers = [];
  this.toggleMessageContentSidebar(false);
  this.toggleMessageWriteSidebar(false);
}

const initMessageOrder = function (parentid) {
  let messageWriting = messageModel;
  this.messageActive = null;
  if (parentid) {
    messageWriting.parentid = parentid;
  }
  this.messageWriting = messageWriting;
  this.toggleMessageSidebar(false);
  this.toggleMessageContentSidebar(false);
  this.toggleMessageWriteSidebar(true);
  this.focusText(".ts_message_sidebar_textarea");
}

const initMessageRows = function () {
  this.messageActive = null;
  let m1 = messageModel;
  let m2 = {};
  Object.assign(m2, m1);
  m2.messageType = "rows";
  this.messageWriting = m2;
  this.toggleMessageSidebar(false);
  this.toggleMessageContentSidebar(false);
  this.toggleMessageWriteSidebar(true);
  this.initMessageRowWrite();
}

const resetMessageWrite = function () {
  this.messageActive = null;
  this.messageWriting = messageModel;
  this.toggleMessageWriteSidebar(false);
}

const resetMessageRowsWrite = function () {
  this.messageRowsWriting = {
    content: "",
    items: {}
  };
}

const initMessageSend = function () {
  let isValid = this.validateMessage();
  const messageTypes = this.messagesTypes;
  const orderDetails = this.dataOrder.dettaglioOrdine || {};
  const {
    c_corrispondente_ordine,
    ds_corrispondente_ordine,
    email_corrispondente
  } = orderDetails;

  this.messageWriting.messageCategory = messageTypes[0].c_tipo_messaggio;
  this.messageWriting.to = {
    ds: ds_corrispondente_ordine,
    id: c_corrispondente_ordine,
    t: "GEMO",
    email: email_corrispondente
  };

  if (isValid == false) {
    return;
  }

  this.modalOpen("ModalMessageSend");
}

const initMessageRowWrite = function () {
  this.resetMessageRowsWrite();
  this.modalOpen("ModalMessageRows");
  this.focusText(".ts_message_textarea_smaller", 100);
}

const toggleMessageWriteSidebar = function (override = null) {
  let b = this.uiSidebarMessageWriteOpen;
  let n = override === null ? !b : override;
  this.uiSidebarMessageWriteOpen = n;
}

const toggleMessageSidebar = function (override = null) {
  let b = this.uiSidebarMessagesOpen;
  let n = override === null ? !b : override;
  this.uiSidebarMessagesOpen = n;
  this.toggleMessageContentSidebar(false);
}

const toggleMessageContentSidebar = function (override = null) {
  let b = this.uiContentMessagesOpen;
  let n = override === null ? !b : override;
  this.uiContentMessagesOpen = n;
  if (n === false) {
    this.messageActive = null;
  }
}

const viewMessage = function (message) {
  this.setMessageRead(message, 1);
  this.uiContentMessagesOpen = true;
  this.messageActive = message.id_messaggio;
}

const setMessageRead = async function (message, fg_letto = 1) {
  if (message.destinatario == this.userInfo.c_utente) {
    let messages = this.dataOrderMessages;
    messages.map(m => {
      if (m.id_messaggio == message.id_messaggio) {
        m.fg_letto = fg_letto;
      }
      return m
    });
    this.dataOrderMessages = messages;
    let response = await this.dataLayer({
      url: this.config.paths.apiURL + 'messaggi/update',
      cacheAge: 1,
      userToken: this.userToken,
      params: {
        id_messaggio: message.id_messaggio,
        fg_letto
      }
    });
  }

}

const messageListOrder = async function (dataOrder) {

  if (!this.config.showMessaggi) {
    return [];
  }

  const orderDetails = dataOrder.dettaglioOrdine || {};
  const {
    n_ordine,
    c_numeratore,
    n_anno
  } = orderDetails;

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'messaggi/ordine',
    cacheAge: 1,
    userToken: this.userToken,
    params: {
      n_ordine,
      c_numeratore,
      n_anno
    }
  });

  let out = [];
  if (response && response.data && response.data.messaggi) {
    out = response.data.messaggi;
  }

  return out;
}

const messageListUnread = async function (options) {

  const { dataLayerOptions } = options || {};

  if (!this.config.showMessaggi) {
    return;
  }

  // API call

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'messaggi/nuovi',
    cacheAge: 0,
    userToken: this.userToken,
    options: dataLayerOptions
  });

  let out = [];
  if (response && response.data && response.data.messaggi) {
    out = response.data.messaggi[0];
  }

  this.messagesNew = out;
}

const messageUpdateContent = async function (data) {

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'messaggi/update',
    cacheAge: 0,
    userToken: this.userToken,
    params: data
  });
}

const messageSend = async function (orderData) {

  let message = toJS(this.messageWriting);
  let formattedMessage = this.getMessageText(message, orderData);

  let userInfo = this.userInfo;

  let messageData = {
    inserito_da: "PORTALE AGENTI",
    mittente: userInfo.c_utente,
    ds_mittente: userInfo.name,
    fg_tipo_mittente: 3,
    testo: formattedMessage,
    destinatario: message.to.id,
    ds_destinatario: message.to.ds,
    fg_tipo_destinatario: message.to.t == "GEMO" ? 1 : 2,
    destinatario_cc: message.cc ? message.cc.id : null,
    ds_destinatario_cc: message.cc ? message.cc.ds : null,
    fg_tipo_destinatario_cc: message.cc ? (message.cc.t == "GEMO" ? 1 : 2) : 1,
    c_tipo_messaggio: message.messageCategory,
    n_anno: orderData.dettaglioOrdine.n_anno,
    c_numeratore: orderData.dettaglioOrdine.c_numeratore,
    n_ordine: orderData.dettaglioOrdine.n_ordine,
    id_messaggio_padre: message.parentid ? message.parentid : null,
    attachments: message.pictures || []
  }

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'messaggi/insert',
    cacheAge: 0,
    userToken: this.userToken,
    params: messageData
  });

  // business logic
  this.modalCloseAll();
  if (response) {
    if (response.status == "OK") {
      this.notificationAdd("Messaggio inviato", "messages_confirm_send", 2000, "success");
    }
    else {
      this.notificationAdd("Errore", "messages_error_send", 2000, "error");
    }
  }
  this.routeReload();
}

const messageUpdateStatus = async function (status, message) {

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'messaggi/status',
    cacheAge: 0,
    userToken: this.userToken,
    params: {
      status, message
    }
  });
}

const messageAddPictures = async function (id, file) {

  let data = toJS(this.messageWriting);
  var reader = new FileReader();
  var source = "";
  var store = this;

  reader.onload = function (e) {
    source = e.target.result;

    let pictures = data.pictures || [];
    let f = data.pictures.find((p) => p.id === id);
    let extension = file.name.split('.').pop();
    let type = validateExtensionImage(file.name) ? "image" : "file";

    // case: file already loaded
    if (f) {
      return;
    }

    if (file.size >= 1024 * 1024 * 2) {
      store.notificationAdd(langstore.t("reclami_imgformat_size", "Il file è troppo pesante"), "nuovoReclamoAddPictures", 2000, "error");
      return;
    }

    // case: file not valid
    // if( !validateExtensionImage(file.name) ){
    //   store.notificationAdd(langstore.t("reclami_imgformat_error","Il file non è un'immagine"),"nuovoReclamoAddPictures",2000,"error");
    //   return;
    // }

    let item = {
      source: source,
      name: file.name,
      id,
      extension,
      type
    }
    data.pictures.push(item);
    store.messageWriting = data;

  }

  reader.readAsDataURL(file);
}

const MessageRemovePictures = async function (id) {
  let data = toJS(this.messageWriting) || {};

  data.pictures = (data.pictures || []).filter((v) => {
    return v.id !== id;
  });

  this.messageWriting = data;

  // necessario azzerare stato input altrimenti non ricarica la stessa immagine
  // i dati immagine sono gestiti nello store e non nell'input
  let input = document.querySelector("#reclamo_file_input");
  input.value = "";
}

const updateTipologieMessaggi = async function (options) {

  const { dataLayerOptions } = options || {};

  if (!this.config.showMessaggi) {
    return;
  }

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'messaggi/tipologie',
    cacheAge: 0,
    userToken: this.userToken,
    options: dataLayerOptions
  });

  let out = [];
  if (response && response.data && response.data.tipologieMessaggi) {
    out = this.messagesTypes = response.data.tipologieMessaggi[0];
  }

  this.messagesTypes = out;
}

const validateMessage = function () {

  this.resetMessageValidation();

  let data = toJS(this.messageWriting) || {};
  let validations = data.validations || [];
  let isValid = true;

  // No message

  if (data.messageType == "order" && (data.contents.length == 0 || data.contents[0] == "")) {
    if (!validations.includes("message")) {
      validations.push("message");
    }
    isValid = false;
  }

  if (data.messageType == "rows" && (data.contents.length == 0)) {
    if (!validations.includes("message")) {
      validations.push("message");
    }
    isValid = false;
  }

  data.validations = validations;
  this.messageWriting = data;
  return isValid;
}

const validateMessageRows = function () {

  this.resetMessageValidation();

  let dataMessage = toJS(this.messageWriting) || {};
  let data = toJS(this.messageRowsWriting) || {};
  let validations = dataMessage.validations || [];
  let isValid = true;
  let selecteditems = Object.values(data.items).filter((i) => i == true);

  if (data.content == "" || selecteditems.length == 0) {
    if (!validations.includes("messagerow")) {
      validations.push("messagerow");
    }
    isValid = false;
  }

  dataMessage.validations = validations;
  this.messageWriting = dataMessage;
  return isValid;

}

const resetMessageValidation = function () {
  this.updateMessageContent({
    validations: []
  })
}

const focusText = function (selector, time = 10) {
  setTimeout(function () {
    document.querySelector(selector).focus();
  }, time);
}

const getMessageText = function (message, orderData) {
  let contents = message.contents;
  let final = [];

  contents.map(c => {
    if (typeof c === 'string') {
      final.push(c);
    }
    else if (typeof c === "object") {
      let m = getMessageItemText(c, orderData);
      final.push(m)
    }
  });

  return final.join("\n\n");

}

const getMessageItemText = function (content, orderData) {
  let orderRows = orderData.righeOrdine;
  let rigaText = langstore.t("reclami_messaggio_prefixrighe", "Riga");
  let items = Object.keys(content.items).filter((o) => {
    return content.items[o] = true;
  });
  items = items.map(item => {
    let row = orderRows.find(r => {
      return r.RowNumber == item;
    })
    return rigaText + " " + row.RowNumber + "-" + row.n_progressivo + " " + row.c_articolo_alias + " " + row.ds_articolo;
  }).join("\n");
  return items + "\n" + content.content;
}

const getMessageAttachment = function (path) {
  window.open(path);
}


export {
  initOrderForMessages,
  messageListOrder,
  messageListUnread,
  messageUpdateContent,
  messageSend,
  messageUpdateStatus,
  toggleMessageSidebar,
  toggleMessageContentSidebar,
  viewMessage,
  setMessageRead,
  initMessageOrder,
  initMessageRows,
  toggleMessageWriteSidebar,
  resetMessageWrite,
  messageAddPictures,
  MessageRemovePictures,
  initMessageRowWrite,
  initMessageSend,
  updateMessageContent,
  resetMessageRowsWrite,
  updateMessageRowContent,
  addRowsToMessage,
  removeRowsFromMessage,
  updateTipologieMessaggi,
  validateMessage,
  validateMessageRows,
  resetMessageValidation,
  editMessageRows,
  focusText,
  getMessageText,
  getMessageItemText,
  getMessageAttachment,
  getAttachmentData,
  getMessageUsers
}