import * as user from 'Application/actions/user.js';
import * as agents from 'Application/actions/agents.js';
import * as invoices from 'Application/actions/invoices.js';
import * as ddt from 'Application/actions/ddt.js';
import * as orders from 'Application/actions/orders.js';
import * as reclami from 'Application/actions/reclami.js';
import * as tweb from 'Application/actions/tweb.js';
import * as email from 'Application/actions/email.js';
import * as profile from 'Application/actions/profile.js';
import * as reports from 'Application/actions/reports';
import * as eldom from 'Application/actions/eldom';
import * as media from 'Application/actions/media';
import * as stats from 'Application/actions/stats';
import * as sso from 'Application/actions/sso';
import * as password from 'Application/actions/password';
import * as dashboard from 'Application/actions/dashboard';

export default {
  ...user,
  ...agents,
  ...invoices,
  ...ddt,
  ...orders,
  ...reclami,
  ...tweb,
  ...email,
  ...profile,
  ...reports,
  ...eldom,
  ...media,
  ...stats,
  ...sso,
  ...password,
  ...dashboard
}
