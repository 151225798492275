import { autorun, extendObservable, toJS, runInAction } from "mobx"


export default function(store){

	const animationDelay = 500;
	let content = document.querySelector(".ts_body");

	let getContentWidth = ()=>{
		if(content === null){
			content = document.querySelector(".ts_body");
		}
		if(content !== null){
			setTimeout(()=>{
			    runInAction( ()=> {
      				store.uiContentWidth = content.clientWidth;
							store.uiDocumentWidth = document.body.clientWidth;
			    });
			},animationDelay)
		}
	}

	autorun( ()=> {
		let uiSidebarExpanded = store.uiSidebarExpanded;
		let route = store.route;
		getContentWidth();
	})

	window.onresize = function(event) {
		getContentWidth();
	};


	// todo: trovare evento migliore
	function listenDom(){
		if(content === null){
			getContentWidth();
			setTimeout(()=>{
				listenDom();
			},1000);
		}
	}
	listenDom();
}



