import React from 'react'
import {inject, observer} from 'mobx-react'


export default inject("rootstore","langstore")(observer((props) => {

  return(
    <div className="ts_breadcrumb">
  
    	{props.rootstore.pathCurrent.map( (route,i)=>{

    		if(props.rootstore.pathCurrent.length === (i+1) ){
	    		return(
	    		     <p 
	    		     	className="ts_breadcrumb_inactive ts_label1"
	    		     	key={route.idMenu}><span>{props.langstore.t(route.titleKey,route.titleString)}</span></p>
	    		)
    		}
    		else{
	    		return(
	    		     <p 
	    		     	className="ts_breadcrumb_active ts_label1"
	    		     	onClick={ ()=> props.rootstore.routeChangeWithLink(route.path,route.external,route.target) }
	    		     	key={route.idMenu}><span>{props.langstore.t(route.titleKey,route.titleString)}</span> <i className="icon-keyboard_arrow_right icon_s"/></p>
	    		)
    		}

    	})}
    </div>
  )
}));