import { runInAction, toJS } from 'mobx';
import { Request, Response } from 'System/utilities/network.js';
import langstore from 'System/i18n/translator.js';
import { openDB, deleteDB } from 'idb'
import { getParameterByName, removeQueryString } from 'System/utilities/urls.js';
import { is_touch_device } from 'System/utilities/devices.js';

const init = async function (_token, welcomeMessage) {

  this.loadingAdd('appinit');

  let upgraded = await this.appUpgrade();
  let token = this.userToken;

  if (_token) { token = _token; }
  if (upgraded === true) { token = false; }


  if (token) {

    // esempio carico risorse --> vedere stessa azione in application
    // await Promise.all([
    //   // this.updateCapigruppo(),
    //   // this.updateNumeratori(),
    //   // this.updateStatiOrdine(),
    //   // this.updateLanguages() // serve?
    // ]);

    let menu = this.menuLoad();

    // al login language è aggiornato in base alle info del profilo; altrimenti default IT (todo prendere default da API)
    let changed = await this.languageChange(this.language);

    if (welcomeMessage) {
      this.notificationAdd(`${langstore.t("user_welcome", "Bentornato")} ${welcomeMessage}`, 'userformlogin');
    }

  }

  runInAction(() => {
    this.appReady = true;
    this.loadingRemove('appinit');
  });

  // this.routeReload(); // serve?
}

const initCore = function () {
  this.uiTouch = is_touch_device();
}

const clearStorage = function (appIndex) {
  Object.keys(localStorage).forEach(lsk => {
    if (lsk.indexOf(`${appIndex}_`) === 0) {
      localStorage.removeItem(lsk);
    }
  });
}

const menuLoad = function () {
  let menus = toJS(this.menus)
  let routes = toJS(this.routes);

  //console.log(menus)
  if (menus) {
    for (var i = 0; i < menus.length; i++) {
      let menu = menus[i];
      let routeAdded = false;
      for (var ii = 0; ii < routes.length; ii++) {
        let id = routes[ii].id;
        if (routes[ii].path === menu.path) {
          menus[i].routeId = id;
          break;
        }
      }
    }
  }

  this.menus = menus;
}

const userLogin = async function () {
  let loadingKey = 'userlogin';
  let url = this.config.paths.apiURL + "profilo/login";
  let userName = this.formData.loginEmail;
  let userPassword = this.formData.loginPassword;
  let params = { username: userName, password: userPassword };


  // spinner
  this.loadingAdd(loadingKey);


  // API call
  let response = await this.dataLayer({
    url: url,
    cacheAge: 0,
    params: params,
  });

  let login = await this.userAuth(response, loadingKey);
}

const trySsoLogin = async function () {
  const sso_token = getParameterByName('ssoToken');

  if (sso_token) {
    this.ssoLoginRunning = true;

    let upgraded = await this.appUpgrade();
    if (upgraded === true) { this.userToken = null; }

    let loadingKey = 'userlogin';
    let url = this.config.paths.apiURL + "profilo/ssoLogin";
    let params = { sso_token }

    this.loadingAdd(loadingKey);

    let response = await this.dataLayer({
      url: url,
      cacheAge: 0,
      params: params,
    });

    let login = await this.userAuth({
      data: response.data
    });
    this.routeChange(this.config.routes.index);
    this.loadingRemove(loadingKey);
    this.ssoLoginRunning = false;
  }
}

const userAuth = async function (response, loadingKey) {
  // business logic
  runInAction(() => {
    if (response) {
      this.dataOrder = response.data.dettaglioOrdine;
    }
    if (loadingKey)
      this.loadingRemove(loadingKey);
  });


  // data management

  runInAction(() => {
    if (response) {
      var profilo = response.data.profilo;

      var userInfo = {
        surname: profilo.cognome,
        name: profilo.ds_utente,
        fax: profilo.fax,
        username: profilo.username,
        phone: profilo.telefono,
        services: profilo.services,
        c_utente: profilo.c_utente,
        email: profilo.email
      }

      this.userInfo = userInfo;
      this.userToken = response.data.token;
      // QUICK FIX TO BE CHECKED
      if (profilo.linguaISO) {
        if (profilo.linguaISO.trim() == '*') {
          this.language = this.config.languages.languageDefault
        } else {
          this.language = profilo.linguaISO
        }
      }

      this.userService = profilo.currentService;
    }

    if (loadingKey)
      this.loadingRemove(loadingKey);


    // init
    this.init(response.data.token, profilo.ds_utente);
  });
}

const userLogout = async function (options) {

  const { noLogoutNotification } = options || {};

  if (this.userToken) {

    var store = this;
    var requestBody = new Request();
    var request = {
      method: 'post', headers: {
        "Content-Type": "application/json",
        "x-access-token": store.userToken
      }
    };
    var url = store.config.paths.apiURL + "profilo/logout";

    request.body = JSON.stringify(requestBody);

    fetch(url, request).then(function (response) {
      return response.json();
    }).then(function (result) {
      var response = new Response(result);
    });

    if (!noLogoutNotification) {
      this.notificationAdd(langstore.t("user_goodbye", "Utente scollegato"), 'userreset');
    }
  }

  const loadingKey = "disconnecting";


  this.loadingAdd(loadingKey);

  if (this.networkDBStore) {
    await this.networkDBStore.clear('networkdata');
  }

  let indexCurrent = localStorage.getItem(`app_index_${this.namespaceIndex}`) || "__new__";

  clearStorage(indexCurrent);

  this.loading = [];
  if (!getParameterByName('ssoToken') && removeQueryString(this.route) != "/sso/gemo") {
    this.route = "/login";
  }

  this.loadingRemove(loadingKey);

  runInAction(() => {
    this.userToken = null;
    this.userService = null;
    this.userTokenRoot = null;
    this.uerInfoRoot = {};
    this.userServiceRoot = null;
    this.formData = {};
    this.menus = null;
    this.cacheParams = null;
    this.userInfo = {
      name: "",
      email: "",
      title: ""
    };
    this.selfCliente = null;
  });
}

const userReset = async function () {
  runInAction(() => {
    this.appReset();
    this.userLogout();
  });
}

const userProjectionClose = function () {

  let rootToken = this.userTokenRoot;
  let rootInfo = this.uerInfoRoot;
  let rootService = this.userServiceRoot;

  runInAction(() => {
    this.userToken = rootToken;
    this.uerInfoRoot = rootInfo;
    this.userServiceRoot = rootService;
    this.userTokenRoot = null;
    this.uerInfoRoot = {};
    this.userServiceRoot = null;
  });
}

const userProjectionOpen = function () {

  let userToken = this.userTokenRoot;
  let userInfo = this.uerInfoRoot;
  let userService = this.userServiceRoot;

  runInAction(() => {
    // projected as self until new selection is made
    // this.userToken = userToken;
    // this.uerInfoRoot = userInfo;
    // this.userServiceRoot = userService;    

    this.userTokenRoot = userToken;
    this.uerInfoRoot = userInfo;
    this.userServiceRoot = userService;
  });
}

const languageChange = async function (language, options) {

  const { dataLayerOptions } = options || {};

  this.loadingAdd("languageChange");

  var store = this;
  var requestBody = new Request({ data: { language: language } });
  var request = {
    method: 'post', headers: {
      "Content-Type": "application/json",
      "x-access-token": store.userToken
    }
  };
  var url = store.config.paths.apiURL + "profilo/setlingua";

  request.body = JSON.stringify(requestBody);

  fetch(url, request).then(function (response) {
    return response.json();
  }).then(function (result) {
    var response = new Response(result);
  });

  runInAction(() => {
    this.language = language;
  });


  // get language bundle

  const token = this.userToken;
  let params = {
    "lingua": [language]
  }
  let bundle = {};
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + "languages/traduzioni",
    cacheAge: 0,
    params: params,
    userToken: token,
    options: dataLayerOptions
  });

  if (response && response.data && response.data.traduzioni && response.data.traduzioni[language]) {
    bundle = response.data.traduzioni[language];
    langstore.addLanguageBundle(language, bundle);
    langstore.changeLanguage(language);
  }

  if (token) {
    await this.updateStatiOrdine(options);
    await this.updateStatiReclamo(options);
  }

  this.loadingRemove("languageChange");

  return true;
}

const languageSetDefault = async function (language) {
  const loadingKey = "languageSetDefault";

  try {
    this.loadingAdd(loadingKey);

    const { config, userToken } = this;
    const apiUrl = config.paths.apiURL.replace(/\/$/g, '');

    // SET DEFAULT LANGUAGE
    const setLangRequestBody = new Request({ data: { language } });
    let setLangResult = await fetch(
      `${apiUrl}/profilo/setlinguadefault`,
      {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userToken
        },
        body: JSON.stringify(setLangRequestBody)
      }
    )
    setLangResult = await setLangResult.json();

    const { data } = new Response(setLangResult);

    const { linguaIso } = data;

    // GET TRANSLATION
    const getTranslationRequestBody = new Request({ data: { lingua: [linguaIso] } });
    let getTranslationResult = await fetch(
      `${apiUrl}/languages/traduzioni`,
      {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userToken
        },
        body: JSON.stringify(getTranslationRequestBody)
      }
    )
    getTranslationResult = await getTranslationResult.json();

    const languageBundle = getTranslationResult?.data?.traduzioni?.[linguaIso];

    if (!languageBundle) {
      langstore.addLanguageBundle(linguaIso, languageBundle);
      langstore.changeLanguage(linguaIso);
    }

    if (userToken) {
      await this.updateStatiOrdine();
      await this.updateStatiReclamo();
    }
  } finally {
    this.loadingRemove(loadingKey);
  }
}

const passwordReset = async function () {
  let loadingKey = 'passwordReset';
  let url = this.config.paths.apiURL + "password/reset";

  // spinner
  this.loadingAdd(loadingKey);

  // API call
  let response = await this.dataLayer({
    url: url,
    cacheAge: 0,
    params: {
      c_utente: this.formData.loginID
    },
    rawResponse: true
  });

  this.loadingRemove(loadingKey);

  // business logic
  runInAction(() => {

    if (response) {
      if (response.status == "OK") {
        this.routeChange("/passwordresetrequestok");
      }
      else {
        this.modalOpen("ModalDefault", { title: langstore.t("user_resetpwerrortitle", "C'è stato un problema"), content: response.message });
      }
    }

    this.loadingRemove(loadingKey);

  });
}

const appReset = async function (appIndex) {

  const deleteTimeout = setTimeout(() => {
    console.error("Cannot delete IndexedDB. Please make sure all connections have been closed.")
  }, 5000);

  console.log("deleting db", appIndex);

  try {
    // delete localstorage (all)
    clearStorage(appIndex);
    // delete inxedDB (all)
    if (appIndex) {
      // Close all connections to the idb
      this.networkDBStore.close();

      await deleteDB(appIndex, {});
    }
  } catch (e) {
    this.log(e, "WARNING");
  } finally {
    clearTimeout(deleteTimeout);
  }

  return true;

}

const appUpgrade = async function () {

  // get current version
  let indexCurrent = localStorage.getItem(`app_index_${this.namespaceIndex}`) || "__new__";

  // get config version
  let indexNew = this.fullNamespace;

  // same version, do nothing
  if (indexNew === indexCurrent) {
    return false;
  }

  // process different version
  this.userLogout({ noLogoutNotification: true });
  await this.appReset(indexCurrent);
  this.log(`upgraded from ${indexCurrent} to ${indexNew}`, "OK");

  // popup aggiornamento
  // TODO

  localStorage.setItem(`app_index_${this.namespaceIndex}`, String(indexNew));
  return true;
}


export {
  initCore,
  init,
  userLogin,
  userAuth,
  trySsoLogin,
  userLogout,
  menuLoad,
  userReset,
  userProjectionClose,
  languageChange,
  languageSetDefault,
  passwordReset,
  appUpgrade,
  appReset
}