import Loadable from 'react-loadable';
import Loading from '../system/views/loading.jsx';

/*
 * ------------------------------------------------------------------------------------------------------
 * Client section
 * ------------------------------------------------------------------------------------------------------
 */

const Clientlist = Loadable({
  loader: () => import(/* webpackChunkName: "Clients" */ "./views/clientlist.jsx"),
  loading: Loading
});

const Clientdetail = Loadable({
  loader: () => import(/* webpackChunkName: "Clients" */ "./views/clientdetail.jsx"),
  loading: Loading
});

const Clientcontacts = Loadable({
  loader: () => import(/* webpackChunkName: "Clients" */ "./views/clientcontacts.jsx"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 * Orders section
 * ------------------------------------------------------------------------------------------------------
 */

const Confirmedorders = Loadable({
  loader: () => import(/* webpackChunkName: "Orders" */ "./views/confirmedorders.jsx"),
  loading: Loading
});

const Sostituzioni = Loadable({
  loader: () => import(/* webpackChunkName: "Orders" */ "./views/sostituzioni.jsx"),
  loading: Loading
});

const PortafoglioOrdini = Loadable({
  loader: () => import(/* webpackChunkName: "Orders" */ "./views/portafoglioordini.jsx"),
  loading: Loading
});

const EventiOrdine = Loadable({
  loader: () => import(/* webpackChunkName: "Orders" */ "./views/eventiordine.jsx"),
  loading: Loading
});

const Orderdetail = Loadable({
  loader: () => import(/* webpackChunkName: "Orders" */ "./views/orderdetail.jsx"),
  loading: Loading
});

const Totalizzazioni = Loadable({
  loader: () => import(/* webpackChunkName: "Orders" */ "./views/totalizzazioni.jsx"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 * Invoices section
 * ------------------------------------------------------------------------------------------------------
 */

const Invoices = Loadable({
  loader: () => import(/* webpackChunkName: "Invoices" */ "./views/invoices.jsx"),
  loading: Loading
});

const Payments = Loadable({
  loader: () => import(/* webpackChunkName: "Invoices" */ "./views/payments.jsx"),
  loading: Loading
});

const InvoicePreview = Loadable({
  loader: () => import(/* webpackChunkName: "Invoices" */ "./views/invoicePreview.jsx"),
  loading: Loading
});

const EstrattoConto = Loadable({
  loader: () => import(/* webpackChunkName: "Invoices" */ "./views/estrattoConto.jsx"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 * Complaints section
 * ------------------------------------------------------------------------------------------------------
 */

const PortafoglioReclami = Loadable({
  loader: () => import(/* webpackChunkName: "Complaints" */ "./views/portafoglioreclami.jsx"),
  loading: Loading
});

const NuovoReclamo = Loadable({
  loader: () => import(/* webpackChunkName: "Clients" */ "./views/nuovoreclamo/nuovoreclamo.jsx"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 * Transport document section
 * ------------------------------------------------------------------------------------------------------
 */

const DDT = Loadable({
  loader: () => import(/* webpackChunkName: "DDT" */ "./views/ddt.jsx"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 * Dashboard
 * ------------------------------------------------------------------------------------------------------
 */

const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */ "./views/dashboard2.jsx"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 * GtlfViewer section
 * ------------------------------------------------------------------------------------------------------
 */

const GtlfViewer = Loadable({
  loader: () => import(/* webpackChunkName: "GtlfViewer" */ "./views/gltfviewer.jsx"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 * Reports section
 * ------------------------------------------------------------------------------------------------------
 */
const Reports = Loadable({
  loader: () => import(/* webpackChunkName: "Reports" */ "./views/reports.jsx"),
  loading: Loading
});

/*
 * ------------------------------------------------------------------------------------------------------
 * Home Appliances section
 * ------------------------------------------------------------------------------------------------------
 */
const Eldom = Loadable({
  loader: () => import(/* webpackChunkName: "Eldom" */ "./views/eldom"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 * Media management section
 * ------------------------------------------------------------------------------------------------------
 */

const MediaManagement = Loadable({
  loader: () => import(/* webpackChunkName: "MediaManagement" */ "./views/MediaManagement"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 * Stats section
 * ------------------------------------------------------------------------------------------------------
 */
const Stats = Loadable({
  loader: () => import(/* webpackChunkName: "Stats" */ "./views/Stats"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 * Portal SSO section
 * ------------------------------------------------------------------------------------------------------
 */
const PortalClientiSso = Loadable({
  loader: () => import(/* webpackChunkName: "PortalSso" */ "./views/PortalSso/PortalClientiSso.jsx"),
  loading: Loading
});

const PortalAgentiSso = Loadable({
  loader: () => import(/* webpackChunkName: "PortalSso" */ "./views/PortalSso/PortalAgentiSso.jsx"),
  loading: Loading
});


const views = {
  Clientlist,
  Clientdetail,
  Clientcontacts,
  Confirmedorders,
  Sostituzioni,
  PortafoglioOrdini,
  PortafoglioReclami,
  NuovoReclamo,
  Orderdetail,
  EventiOrdine,
  Invoices,
  Payments,
  DDT,
  Dashboard,
  GtlfViewer,
  InvoicePreview,
  EstrattoConto,
  Totalizzazioni,
  Reports,
  Eldom,
  MediaManagement,
  Stats,
  PortalClientiSso,
  PortalAgentiSso
}


export default views;