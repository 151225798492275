import Loadable from 'react-loadable';
import Loading from '../system/views/loading.jsx';
import NavigationTitle from '../system/components/navigationtitle.jsx';
import NavigationPath from '../system/components/NavigationPath.jsx';
import SidebarMenu from '../system/components/sidebarmenu.jsx';
import Projection from '../system/views/projection.jsx';

const Index = Loadable({
  loader: () => import(/* webpackChunkName: "Reference" */ "./views/index.jsx"),
  loading: Loading
});

const Login = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ "./views/login.jsx"),
  loading: Loading
});

const Profile = Loadable({
  loader: () => import(/* webpackChunkName: "Profile" */ "./views/profile.jsx"),
  loading: Loading
});


const Comunicazioni = Loadable({
  loader: () => import(/* webpackChunkName: "Comunicazioni" */ "./views/comunicazioni.jsx"),
  loading: Loading
});


const Downloads = Loadable({
  loader: () => import(/* webpackChunkName: "Downloads" */ "./views/downloads.jsx"),
  loading: Loading
});


const Reference = Loadable({
  loader: () => import(/* webpackChunkName: "Reference" */ "./views/reference.jsx"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 *Password reset
 * ------------------------------------------------------------------------------------------------------
 */

const PasswordReset = Loadable({
  loader: () => import(/* webpackChunkName: "PasswordReset" */ "./views/passwordreset.jsx"),
  loading: Loading
});


const PasswordResetRequestOk = Loadable({
  loader: () => import(/* webpackChunkName: "PasswordReset" */ "./views/passwordresetrequestok.jsx"),
  loading: Loading
});


/*
 * ------------------------------------------------------------------------------------------------------
 * Preview section
 * ------------------------------------------------------------------------------------------------------
 */

const Preview = Loadable({
  loader: () => import(/* webpackChunkName: "Preview" */ "./views/preview.jsx"),
  loading: Loading
});


const Previewfile = Loadable({
  loader: () => import(/* webpackChunkName: "Preview" */ "./views/previewfile.jsx"),
  loading: Loading
});

/*
 * ------------------------------------------------------------------------------------------------------
 * GEMO SSO
 * ------------------------------------------------------------------------------------------------------
 */
const SsoGemoPage = Loadable({
  loader: () => import(/* webpackChunkName: "SsoGemo" */ "./views/sso/SsoGemoPage"),
  loading: Loading
});


let views = {
  Index,
  Loading,
  SidebarMenu,
  NavigationTitle,
  NavigationPath,
  Profile,
  Projection,
  Login,
  Comunicazioni,
  Downloads,
  Reference,
  PasswordReset,
  PasswordResetRequestOk,
  Preview,
  Previewfile,
  SsoGemoPage
}

export default views;