import { runInAction } from 'mobx';
import langstore from '../../system/i18n/translator.js';


const options = {
  dataLayerOptions: {
    noLogoutNotification: true
  }
}

async function initWithToken() {
  // carico risorse
  await Promise.all([
    this.updateComunicazioni(options),
    this.updateCapigruppo(options),
    this.updateNumeratori(options),
    this.updateNumeratoriBolla(options),
    this.updateNumeratoriFattura(options),
    this.updateStatiOrdine(options),
    this.updateStatiReclamo(options),
    this.updateLanguages(options),
    this.updateEventiOrdini(options),
    this.updateMenu(options),
    this.updateAutorizzazioni(),
    this.updateEnabledEmails(options),
    this.getInfoUtente(options),
    this.updateTipologieMessaggi(options),
    this.messageListUnread(options),
    this.updateClassificazioniOrdine(options),
  ]);

  await this.menuModifiers();

  this.menuLoad();

  // attivo processi ping
  this.startPings(options);

  // al login language è aggiornato in base alle info del profilo; altrimenti default IT (todo prendere default da API)
  await this.languageChange(this.language, options);
};

async function initWithoutToken() {
  await this.updateLanguages(options);

  const { languages: activeLanguages } = this;

  /*
    Settaggio lingua di default
    Assume che lingua segnalata nel config (tipicamente "it") esista sempre
  */
  let languageTemp = this.config.languages.languageDefault;

  /*
    Cerca lingua di sistema;
    Se non è "it", lingua default diventa "en", ma solo se "en" esiste nelle lingue supportate
  */
  let languageSystem = navigator.language.split("-");
  languageSystem = languageSystem[0];
  if (languageSystem != "it") {
    for (var i = 0; i < activeLanguages.length; i++) {
      if ("en" === activeLanguages[i].iso) {
        languageTemp = activeLanguages[i].iso;
      }
    }
  }

  /*
    Se lingua di sistem esiste nella lista lingue, scelgo quella
    Sovrascrivo lingua default
  */
  for (var i = 0; i < activeLanguages.length; i++) {
    if (languageSystem === activeLanguages[i].iso) {
      languageTemp = activeLanguages[i].iso;
    }
  }

  /*
    Se c'è una lingua in cache ed esiste nella lista lingue, scelgo quella
    Sovrascrive lingua sistema
  */
  if (this.language) {
    for (var i = 0; i < activeLanguages.length; i++) {
      if (this.language === activeLanguages[i].iso) {
        languageTemp = activeLanguages[i].iso;
      }
    }
  }

  /*
    Cambio lingua ed avvio app
  */
  await this.languageChange(languageTemp, options);
};

const init = async function (_token, welcomeMessage) {
  const loadingKey = 'appinit';

  try {
    this.loadingAdd(loadingKey);

    let token = _token || this.userToken;

    // Check if version changed. If true, upgrade and reset app
    const upgraded = await this.appUpgrade();

    // If upgraded then set token to false
    if (upgraded === true) {
      token = false;
    }

    if (token) {
      await this.initWithToken();
    } else {
      await this.initWithoutToken();
    }

    if (welcomeMessage) {
      this.notificationAdd(`${langstore.t("user_welcome", "Bentornato")} ${welcomeMessage}`, 'userformlogin');
    }
  } finally {
    this.loadingRemove(loadingKey);

    runInAction(() => {
      this.appReady = true;
    });
  }
}


export {
  init,
  initWithToken,
  initWithoutToken
}