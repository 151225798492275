import { toJS, runInAction } from 'mobx';
import { currencyFormat } from '../../system/utilities/currency.js';
import langstore from '../../system/i18n/translator.js';

const addCurrency = (object, childPropName) => {
	const _object = { ...object };

	if (typeof childPropName == "string") { // if child name is correctly defined
		if (_object[childPropName]) { // if child actually exists
			_object[childPropName].DS_im_netto = currencyFormat(_object[childPropName].im_netto, _object[childPropName].c_valuta);
		}
	} else {
		_object.DS_im_netto = currencyFormat(_object.im_netto, _object.c_valuta);
	}


	return _object;
}

const totalizzazioniInit = async function () {

	let loadingKey = 'totalizzazioniInit';
	this.loadingAdd(loadingKey);

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/portafoglio/totalizzazioni',
		cacheAge: 0,
		userToken: this.userToken
	});

	// business logic
	runInAction(() => {
		if (response) {
			this.dataOrders = response.data;
		}
		this.loadingRemove(loadingKey);
	});

}

const estrattoContoInit = async function () {


	let loadingKey = 'estrattoContoInit';
	this.loadingAdd(loadingKey);

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'pratiche/praticheagente',
		cacheAge: 0,
		userToken: this.userToken
	});

	// business logic
	runInAction(() => {
		if (response) {
			this.dataOrders = response.data;
		}
		this.loadingRemove(loadingKey);
	});

}

const orderVestizione = async function (row, index) {

	if (row) {

		let options = {};
		let vestizione = toJS(this.dataOrderVestizione);

		let _params = {
			"scheda": row.c_scheda,
			"articolo": row.c_articolo_alias,
			"magazzino": row.c_magazzino_or
		};

		// API call
		let response = await this.dataLayer({
			url: this.config.paths.apiURL + 'vestizione',
			cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
			params: _params,
			userToken: this.userToken
		});

		// business logic
		runInAction(() => {
			if (response) {
				vestizione[index] = response.data;
				this.dataOrderVestizione = vestizione;
			}
		});

	}
}

const fileListModalOpen = function (docs) {

	runInAction(() => {
		this.dataDocumentInfo = {};
		this.dataDocumentActive = docs;
		this.modalOpen("ModalPratiche");
	});

}

const fileList = async function (params, options) {

	let _params = {
		c_pratica: params.c_pratica,
		c_pratica_guid: params.c_pratica_guid
	}

	let files = toJS(this.dataDocumentInfo);
	let index = params.c_pratica_guid;
	files[index] = {
		loadingState: "loading",
		data: []
	}
	this.dataDocumentInfo = files;

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'pratiche/infofiles',
		cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
		params: _params,
		userToken: this.userToken
	});

	let sorted = (response.data && response.data.info) ? response.data.info : [];
	sorted.sort((a, b) => {
		return (
			a.c_versione - b.c_versione || a.c_riga - b.c_riga
		)
	});

	// business logic
	runInAction(() => {
		if (response) {
			files[index] = {
				loadingState: "complete",
				data: sorted
			}
			this.dataDocumentInfo = files;
		}
	});

}

const orders = async function (params, options) {

	let cacheAge = options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default;
	const cacheID = "orderParams";
	let loadingKey = 'orderParams';
	let localParams = this.routeParamsCacheGet(cacheID);
	let dates = this.rangeToDates(this.config.format.dateRangeDefault);

	await this.updateEventiOrdini();
	if (this.dataEventiOrdine.length >= 1) {
		cacheAge = 1;
	}

	let _params = {
		"PageNumber": localParams.PageNumber || 1,
		"da_dt_ordine_cliente": localParams.da_dt_ordine_cliente || dates.fr,
		"a_dt_ordine_cliente": localParams.a_dt_ordine_cliente || dates.to,
	}
	if (localParams.n_anno) {
		_params['n_anno'] = parseInt(localParams.n_anno);
	}
	if (localParams.c_numeratore) {
		_params['c_numeratore'] = Array.isArray(localParams.c_numeratore) ? localParams.c_numeratore : [localParams.c_numeratore];
	}
	if (localParams.n_ordine) {
		_params['n_ordine'] = parseInt(localParams.n_ordine);
	}
	if (localParams.c_stato_ordine) {
		_params['fg_stato_ordine'] = toJS(localParams.c_stato_ordine);
	}
	if (localParams.c_stato_ordine_portale) {
		_params['stati_ordine_portale'] = toJS(localParams.c_stato_ordine_portale);
	}
	if (localParams.c_nome) {
		_params['c_nome'] = [parseInt(localParams.c_nome)];
	}
	if (localParams.ragione_sociale) {
		_params['ragione_sociale'] = [toJS(localParams.ragione_sociale)];
	}
	if (localParams.c_cliente_gruppo) {
		_params['c_cliente_gruppo'] = toJS(localParams.c_cliente_gruppo);
	}
	if (localParams.riferimento_cliente) {
		_params['riferimento_cliente'] = localParams.riferimento_cliente;
	}

	// spinner
	this.loadingAdd(loadingKey);

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/attivi',
		cacheAge: cacheAge,
		params: _params,
		userToken: this.userToken
	});

	// business logic
	runInAction(() => {
		if (response) {
			this.routeParamsCacheSave2(cacheID, _params);

			this.dataOrders = response.data;
		}
		this.loadingRemove(loadingKey);
	});

}

const portafoglioordini = async function (params, options) {

	if (options && options.noLoad === true) {
		this.uiGridOptionsExpanded = true;
		return;
	}

	let cacheAge = options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default;
	const cacheID = "portafoglioordiniParams";
	let loadingKey = 'portafoglioordini';
	let localParams = this.routeParamsCacheGet(cacheID);
	let dates = this.rangeToDates(this.config.format.dateRangeDefault);

	await this.updateEventiOrdini();
	if (this.dataEventiOrdine.length >= 1) {
		cacheAge = 1;
	}

	let _params = {
		"PageNumber": localParams.PageNumber || 1,
		"da_dt_ordine_cliente": localParams.da_dt_ordine_cliente || dates.fr,
		"a_dt_ordine_cliente": localParams.a_dt_ordine_cliente || dates.to
	}

	if (localParams.n_anno) {
		_params['n_anno'] = parseInt(localParams.n_anno);
	}
	if (localParams.c_numeratore) {
		_params['c_numeratore'] = Array.isArray(toJS(localParams.c_numeratore)) ? localParams.c_numeratore : [localParams.c_numeratore];
	}
	if (localParams.n_ordine) {
		_params['n_ordine'] = parseInt(localParams.n_ordine);
	}
	if (localParams.c_stato_ordine) {
		_params['fg_stato_ordine'] = toJS(localParams.c_stato_ordine);
	}
	if (localParams.c_stato_ordine_portale) {
		_params['stati_ordine_portale'] = toJS(localParams.c_stato_ordine_portale);
	}
	if (localParams.c_nome) {
		_params['c_nome'] = [parseInt(localParams.c_nome)];
	}
	if (localParams.ragione_sociale) {
		_params['ragione_sociale'] = [toJS(localParams.ragione_sociale)];
	}
	if (localParams.c_cliente_gruppo) {
		_params['c_cliente_gruppo'] = toJS(localParams.c_cliente_gruppo);
	}
	if (localParams.riferimento_cliente) {
		_params['riferimento_cliente'] = localParams.riferimento_cliente;
	}

	this.loadingAdd(loadingKey);

	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/portafoglio',
		cacheAge: cacheAge,
		params: _params,
		userToken: this.userToken
	});

	runInAction(() => {
		if (response) {
			this.routeParamsCacheSave2(cacheID, _params);

			this.dataPortafoglioordini = response.data;
		}
		this.loadingRemove(loadingKey);
	});

}

const ordersets = async function (params, options) {
	if (options && options.noLoad === true) {
		this.uiGridOptionsExpanded = true;
		return;
	}

	let loadingKey = 'ordersets';
	let localParams = this.routeParamsCacheGet("ordersetsParams");
	let dates = this.rangeToDates(this.config.format.dateRangeDefault);

	let _params = {
		"PageNumber": localParams.PageNumber || 1,
		"da_dt_ordine_cliente": localParams.da_dt_ordine_cliente || dates.fr,
		"a_dt_ordine_cliente": localParams.a_dt_ordine_cliente || dates.to,
		"insieme": localParams.insieme || this.config.orderSets && this.config.orderSets.defaultOrderSet
	}

	if (localParams.n_anno) {
		_params['n_anno'] = parseInt(localParams.n_anno);
	}
	if (localParams.c_numeratore) {
		_params['c_numeratore'] = Array.isArray(toJS(localParams.c_numeratore)) ? localParams.c_numeratore : [localParams.c_numeratore];
	}
	if (localParams.n_ordine) {
		_params['n_ordine'] = parseInt(localParams.n_ordine);
	}
	if (localParams.c_stato_ordine) {
		_params['fg_stato_ordine'] = toJS(localParams.c_stato_ordine);
	}
	if (localParams.c_stato_ordine_portale) {
		_params['stati_ordine_portale'] = toJS(localParams.c_stato_ordine_portale);
	}
	if (localParams.c_nome) {
		_params['c_nome'] = [parseInt(localParams.c_nome)];
	}
	if (localParams.ragione_sociale) {
		_params['ragione_sociale'] = [toJS(localParams.ragione_sociale)];
	}
	if (localParams.c_cliente_gruppo) {
		_params['c_cliente_gruppo'] = toJS(localParams.c_cliente_gruppo);
	}
	if (localParams.riferimento_cliente) {
		_params['riferimento_cliente'] = localParams.riferimento_cliente;
	}

	this.loadingAdd(loadingKey);

	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/insiemi',
		cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
		params: _params,
		userToken: this.userToken
	});

	runInAction(() => {
		if (response) {
			this.dataOrdersets = response.data;
		}
		this.loadingRemove(loadingKey);
	});

}

const sostituzioni = async function (params, options) {

	const cacheID = "sostituzioniParams";
	let loadingKey = 'ordersSostituzioni';
	let localParams = this.routeParamsCacheGet(cacheID);
	let dates = this.rangeToDates(this.config.format.dateRangeDefault);

	let _params = {
		"PageNumber": localParams.PageNumber || 1,
		"da_dt_ordine_cliente": localParams.da_dt_ordine_cliente || dates.fr,
		"a_dt_ordine_cliente": localParams.a_dt_ordine_cliente || dates.to,
	}
	if (localParams.n_anno) {
		_params['n_anno'] = parseInt(localParams.n_anno);
	}
	if (localParams.c_numeratore) {
		_params['c_numeratore'] = Array.isArray(localParams.c_numeratore) ? localParams.c_numeratore : [localParams.c_numeratore];
	}
	if (localParams.n_ordine) {
		_params['n_ordine'] = parseInt(localParams.n_ordine);
	}
	if (localParams.c_stato_ordine) {
		_params['fg_stato_ordine'] = toJS(localParams.c_stato_ordine);
	}
	if (localParams.c_stato_ordine_portale) {
		_params['stati_ordine_portale'] = toJS(localParams.c_stato_ordine_portale);
	}
	if (localParams.c_nome) {
		_params['c_nome'] = [parseInt(localParams.c_nome)];
	}
	if (localParams.ragione_sociale) {
		_params['ragione_sociale'] = [toJS(localParams.ragione_sociale)];
	}
	if (localParams.c_cliente_gruppo) {
		_params['c_cliente_gruppo'] = toJS(localParams.c_cliente_gruppo);
	}
	if (localParams.riferimento_cliente) {
		_params['riferimento_cliente'] = localParams.riferimento_cliente;
	}

	// spinner
	this.loadingAdd(loadingKey);

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/sostituzioni/attive',
		cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
		params: _params,
		userToken: this.userToken
	});

	// business logic
	runInAction(() => {
		if (response) {
			this.routeParamsCacheSave2(cacheID, _params);

			this.dataSostituzioni = response.data;
		}
		this.loadingRemove(loadingKey);
	});

}

const orderdetail = async function (params, options) {

	let loadingKey = 'orderDetail';

	// spinner
	this.loadingAdd(loadingKey);

	let _params = params && params.n_anno && params.c_numeratore && params.n_ordine
		? params
		: {
			n_anno: parseInt(this.routeParamsCurrent.orderYear),
			c_numeratore: this.routeParamsCurrent.orderNumber,
			n_ordine: parseInt(this.routeParamsCurrent.orderId),
		};

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/dettaglio',
		cacheAge: 1,
		params: _params,
		userToken: this.userToken
	});

	if (!response || !response.data) {
		this.routeChange("/portafoglioordini");
		this.loadingRemove(loadingKey);
		this.notificationAdd(langstore.t("Order_not_found", "Documento inesistente"), "orderdetail", 2000, "warning");
		return;
	}

	const data = addCurrency(response.data, "dettaglioOrdine");
	const orderDocuments = data.praticheOrdine || [];
	let otherFiles = [];
	let otherFilesFlat = [];
	let hasNullFolder = false;

	for (var i = 0; i < orderDocuments.length; i++) {

		let _params = {
			c_pratica: orderDocuments[i].c_pratica,
			c_pratica_guid: orderDocuments[i].c_pratica_guid
		}

		let files = await this.dataLayer({
			url: this.config.paths.apiURL + 'pratiche/infofiles',
			cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
			params: _params,
			userToken: this.userToken
		});

		if (files.data && files.data.info && files.data.info.length >= 1) {

			// conferma ordine
			if (orderDocuments[i].c_tipo_documento == "15") {
				data.confermaOrdine = orderDocuments[i];
				data.confermaOrdineFile = null;
				let doc = files.data.info.find((f) => {
					return f.fg_manuale == 1;
				})
				if (doc) {
					data.confermaOrdineFile = doc;
				}
			}

			// altri documenti
			// raggruppo per cartella
			// per ogni cartella ordino per versione desc e riga desc + traformo oggetto in array
			else {
				let files3 = [];
				let files2 = files.data.info.reduce((total, f) => {

					let cartella = (f.fg_manuale === 1 || !f.cartella) ? "000" : f.cartella;
					let filename = f.File_name;
					let extension = null;
					let viewable = false;
					let extensions = this.config.viewableExtensions;

					// let showthisversion = (_file.fg_manuale == "2" || _file.fg_manuale == "3");
					filename = filename.split("/").pop();
					filename = filename.split("\\").pop();
					//filename = filename.replace(/\s+/g, '-').toLowerCase();
					extension = filename.split(".").pop().toLowerCase();
					viewable = extensions.includes(extension);
					f.cartella = cartella;
					f.DS_filename = filename;
					f.viewable = viewable;
					f.pratica = orderDocuments[i];
					f.ds_tipo_doc = orderDocuments[i].ds_tipo_doc;
					f.fileLabel = f.ds_file || filename;


					if (total[cartella]) {
						total[cartella].push(f);
					}
					else {
						total[cartella] = [f];
					}

					if (cartella === "000") {
						hasNullFolder = true;
					}

					return total;
				}, {});
				for (let key in files2) {
					let sorted = files2[key].sort((a, b) => {
						return (
							a.c_versione - b.c_versione || a.c_riga - b.c_riga
						)
					});
					files3.push({ cartella: key, count: sorted.length, items: sorted });
					otherFilesFlat = otherFilesFlat.concat(sorted);
				}
				otherFiles.push(files3);
			}

		}
	}


	data.otherFilesFlat = otherFilesFlat;
	data.hasNullFolder = hasNullFolder;

	let orderMessages = await this.messageListOrder(data);

	// business logic
	runInAction(() => {
		if (response) {
			this.dataOrder = data;
			this.dataOrderMessages = orderMessages;
		}
	});

	this.initOrderForMessages();
	this.getMessageUsers();
	this.loadingRemove(loadingKey);
}


const orderConfirm = async function (order) {

	let orderStatus = this.orderEventsInfoCurrent;

	if (!orderStatus.canConfirm) {
		this.notificationAdd(langstore.t("orderconfirm_notallowed", "Operazione non permessa"), "orderConfirm", 2000, "warning");
		return false;
	}

	let _params = {
		n_anno: parseInt(this.routeParamsCurrent.orderYear),
		c_numeratore: this.routeParamsCurrent.orderNumber,
		n_ordine: parseInt(this.routeParamsCurrent.orderId),
	};

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/conferma',
		cacheAge: 0,
		params: _params,
		userToken: this.userToken
	});

	runInAction(() => {
		if (response && response.data && response.data.n_evento) {
			this.notificationAdd(langstore.t("orderconfirm_done", "Richiesta inviata correttamente"), "orderConfirm", 2000, "success");
			this.routeReload();
		}
		else {
			this.notificationAdd(langstore.t("orderconfirm_problem", "Si è veriricato un problema"), "orderConfirm", 2000, "error");
		}
	});

}

const orderCancel = async function (order) {

	let orderStatus = this.orderEventsInfoCurrent;

	if (!orderStatus.canCancel) {
		alert("Operazione non permessa");
		return false;
	}

	let _params = {
		n_anno: parseInt(this.routeParamsCurrent.orderYear),
		c_numeratore: this.routeParamsCurrent.orderNumber,
		n_ordine: parseInt(this.routeParamsCurrent.orderId),
	};

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/annulla',
		cacheAge: 0,
		params: _params,
		userToken: this.userToken
	});

	// business logic
	runInAction(() => {
		if (response && response.data && response.data.n_evento) {
			this.notificationAdd(langstore.t("orderconfirm_done", "Richiesta inviata correttamente"), "orderConfirm", 2000, "success");
			this.routeReload(null, { cacheAge: 1 });
		}
		else {
			this.notificationAdd(langstore.t("orderconfirm_problem", "Si è veriricato un problema"), "orderConfirm", 2000, "error");
			this.routeReload(null, { cacheAge: 1 });
		}
	});

}

const initEventiOrdine = async function () {
	this.loadingAdd("initEventiOrdine");
	await this.updateEventiOrdini();
	this.loadingRemove("initEventiOrdine");
}

const orderNotificaRead = async function (eventid) {

	let notifications = toJS(this.dataEventiOrdine);
	let _params = {
		n_evento: eventid
	};

	let todelete = false;
	let nn = notifications.map((notification) => {
		if (notification.n_evento == eventid && notification.read != true) {
			notification.read = true;
			todelete = true;
		}
		return notification;
	});

	this.dataEventiOrdine = nn;

	// if(!todelete){
	// 	return;
	// }

	// await this.dataLayer({
	// 	url: this.config.paths.apiURL + 'ordine/eventovisualizzato',
	// 	cacheAge: 1,
	// 	params: _params,
	// 	userToken: this.userToken
	// });

}

const orderNotificaDelete = async function (eventid) {

	let notifications = toJS(this.dataEventiOrdine);
	let _params = {
		n_evento: eventid
	};

	let todelete = false;
	let nn = notifications.reduce((total, notification) => {
		if (notification.n_evento == eventid) {
			if (notification.read != true) {
				todelete = true;
			}
		}
		else {
			total.push(notification);
		}
	}, []);

	this.dataEventiOrdine = nn;

	if (!todelete) {
		return;
	}

	await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/eventovisualizzato',
		cacheAge: 1,
		params: _params,
		userToken: this.userToken
	});
}


const orderDownloadConferma = async function (params, options) {

	let loadingKey = 'orderDownloadConferma';

	// spinner
	this.loadingAdd(loadingKey);

	let _params = {
		n_anno: parseInt(this.routeParamsCurrent.orderYear),
		c_numeratore: this.routeParamsCurrent.orderNumber,
		n_ordine: parseInt(this.routeParamsCurrent.orderId),
	};

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/dettaglio',
		cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
		params: _params,
		userToken: this.userToken
	});

	const data = addCurrency(response.data, "dettaglioOrdine");

	// business logic
	runInAction(() => {
		if (response) {
			this.dataOrder = data;
		}
		this.loadingRemove(loadingKey);
	});

}

const orderDownloadAll = async function (params, options) {

	let loadingKey = 'orderDownloadAll';

	// spinner
	this.loadingAdd(loadingKey);

	let _params = {
		n_anno: parseInt(this.routeParamsCurrent.orderYear),
		c_numeratore: this.routeParamsCurrent.orderNumber,
		n_ordine: parseInt(this.routeParamsCurrent.orderId),
	};

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/dettaglio',
		cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
		params: _params,
		userToken: this.userToken
	});

	const data = addCurrency(response.data, "dettaglioOrdine");

	// business logic
	runInAction(() => {
		if (response) {
			this.dataOrder = data;
		}
		this.loadingRemove(loadingKey);
	});

}

// const orderQueueSync = async function(params,options){

// spinner
// this.loadingAdd(loadingKey);

// let _params = {
//   n_anno: parseInt(this.routeParamsCurrent.orderYear),
//   c_numeratore: this.routeParamsCurrent.orderNumber,
//   n_ordine: parseInt(this.routeParamsCurrent.orderId),
// };

// API call
// let response = await this.dataLayer({
//   url: this.config.paths.apiURL + 'ordine/dettaglio',
//   cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
//   params: _params,
//   userToken: this.userToken
// });

// // business logic
// runInAction( ()=> {
//   if(response){
//     this.dataOrder = response.data;
//   }
//   this.loadingRemove(loadingKey);
// });

// }

const view3dModel = async function (params, options) {
	let loadingKey = 'view3dModel';
	const { config } = this;
	const { paths } = config;

	// spinner
	this.loadingAdd(loadingKey);

	const _params = {
		n_anno: parseInt(this.routeParamsCurrent.orderYear),
		c_numeratore: this.routeParamsCurrent.orderNumber,
		n_ordine: parseInt(this.routeParamsCurrent.orderId),
	};

	let response = await this.dataLayer({
		url: paths.apiURL + 'ordine/3dtoken',
		cacheAge: options && options.cacheAge ? options.cacheAge : config.cacheAPI.default,
		params: _params,
		userToken: this.userToken
	});

	// business logic
	runInAction(() => {
		this.data3dviewer = response.data;
		this.loadingRemove(loadingKey);
	});
}

const open3dView = async function (params, options) {
	const { orderYear, orderNumber, orderId } = params;

	window.open(`#/3dview/${orderYear}/${orderNumber}/${orderId}`, `${orderYear}-${orderNumber}-${orderId}`);
}

const relations = async function (params, options) {
	let loadingKey = 'getRelations';

	if (!(options && options.noLoad === true)) {
		// spinner
		this.loadingAdd(loadingKey);
	}

	let _params = {
		anno: parseInt(params.n_anno),
		numeratore: params.c_numeratore,
		numero: parseInt(params.n_ordine),
		calcolo_dati: params.calcolo_dati,
		dati_ulteriori: (params.dati_ulteriori ? true : false),
		visualizza_dati: "sunkey",
		utente_esecuzione: ""
	};

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'common/relazionibof',
		cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
		params: _params,
		userToken: this.userToken
	});

	// business logic
	runInAction(() => {
		if (response) {
			const data = {
				...response.data
			}

			if (data.dati_ulteriori) {
				data.dati_ulteriori = data.dati_ulteriori.map(d => {
					return {
						...d,
						DS_im_importo: currencyFormat(d.im_importo, d.c_valuta)
					}
				})
			}

			this.dataOrderRelations = data;
		}
		this.loadingRemove(loadingKey);
	});
}


const colliordine = async function (params, options) {
	let loadingKey = 'colliOrdine';
	const { orderYear, orderNumber, orderId } = this.routeParamsCurrent;

	// spinner
	this.loadingAdd(loadingKey);

	let _params = {
		n_anno: parseInt(orderYear),
		c_numeratore: orderNumber,
		n_ordine: parseInt(orderId),
	};

	// API call
	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'ordine/colli',
		cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
		params: _params,
		userToken: this.userToken
	});

	// business logic
	runInAction(() => {
		if (response) {
			this.colliOrdine = response.data;
		}
		this.loadingRemove(loadingKey);
	});
}

export {
	orders,
	ordersets,
	orderdetail,
	fileList,
	fileListModalOpen,
	orderVestizione,
	sostituzioni,
	portafoglioordini,
	orderConfirm,
	orderCancel,
	orderDownloadAll,
	orderDownloadConferma,
	view3dModel,
	open3dView,
	relations,
	estrattoContoInit,
	totalizzazioniInit,
	colliordine,
	initEventiOrdine,
	orderNotificaRead,
	orderNotificaDelete
}