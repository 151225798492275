import { runInAction, toJS } from 'mobx';

// close first modal in modals  array
const modalClose = function(){
  let modalItems = toJS(this.modalItems);
  
  modalItems.shift();
  
  runInAction( ()=> {
    this.modalItems = modalItems;
  });
}

// reset modals array
const modalCloseAll = function(size){
  runInAction( ()=> {
    this.modalItems = [];
  });
}

// push modal in modals array
const modalOpen = function(component,params){
  let modalItems = toJS(this.modalItems);
  
  modalItems.push({
    component: component,
    params: params
  });
  
  runInAction( ()=> {
    this.modalItems = modalItems;
  });
}

// push modal in modals array
const modalOvercome = function(component,params){
  let modalItems = toJS(this.modalItems);
  
  modalItems.unshift({
    component: component,
    params: params
  });
  
  runInAction( ()=> {
    this.modalItems = modalItems;
  });
}

export { 
  modalClose,
  modalCloseAll,
  modalOpen,
  modalOvercome
}