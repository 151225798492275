import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

export default inject("rootstore")(observer((props) => {
  return(
    <div className="ts_notifications">

      <ReactCSSTransitionGroup
        transitionName="notification"
        transitionEnterTimeout={0}
        transitionLeaveTimeout={300}>

        {props.rootstore.notifications.map( (notification,i)=> {
          return(
            <div 
              key={notification.id + i}
              className={"ts_notification_item " + notification.type}>{notification.message}</div>
          )
        })}

      </ReactCSSTransitionGroup>

    </div>
  )
}));