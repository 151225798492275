import langstore from '../system/i18n/translator.js';

/**
  Routes
**/

const routes = [{
  id: 'clienti',
  path: "/clienti(/page/:pageNumber)",
  navigation: "NavigationTitle",
  content: "Clientlist",
  sidebar: 'SidebarMenu',
  action: "clientlist",
  titleKey: "section_clienti",
  titleString: "Clienti",
  title: langstore.t("section_clienti", 'Clienti')
}, {
  id: 'sostituzioni',
  path: "/sostituzioni",
  navigation: "NavigationTitle",
  content: "Sostituzioni",
  sidebar: 'SidebarMenu',
  action: 'sostituzioni',
  title: langstore.t("section_sostituzioniattive", 'Sostituzioni attive')
}, {
  id: 'ordersets',
  path: "/insiemiordini",
  navigation: "NavigationTitle",
  content: "OrderSets",
  sidebar: 'SidebarMenu',
  action: 'ordersets',
  title: langstore.t("section_portafoglioordini", 'Portafoglio ordini')
}, {
  id: 'portafoglioordini',
  path: "/portafoglioordini",
  navigation: "NavigationTitle",
  content: "PortafoglioOrdini",
  sidebar: 'SidebarMenu',
  action: 'portafoglioordini',
  title: langstore.t("section_portafoglioordini", 'Portafoglio ordini'),
  /* options: {
    noLoad: true
  } */
}, {
  id: 'portafoglioreclami',
  path: "/portafoglioreclami",
  navigation: "NavigationTitle",
  content: "PortafoglioReclami",
  sidebar: 'SidebarMenu',
  action: 'portafoglioreclami',
  title: langstore.t("section_portafoglioreclami", 'Portafoglio reclami'),
  /* options: {
    noLoad: true
  } */
}, {
  id: 'estrattoconto',
  path: "/estrattoconto",
  navigation: "NavigationTitle",
  content: "EstrattoConto",
  sidebar: 'SidebarMenu',
  action: 'estrattoContoInit',
  title: langstore.t("section_estrattoconto", 'Estratto conto'),
  /* options: {
    noLoad: true
  } */
}, {
  id: 'totalizzazioni',
  path: "/ordini/totalizzazioni",
  navigation: "NavigationTitle",
  content: "Totalizzazioni",
  sidebar: 'SidebarMenu',
  action: 'totalizzazioniInit',
  title: langstore.t("section_totalizzazioni", 'Totalizzazioni'),
  /* options: {
    noLoad: true
  } */
}, {
  id: 'eventiordine',
  path: "/eventiordine",
  navigation: "NavigationTitle",
  content: "EventiOrdine",
  sidebar: 'SidebarMenu',
  action: 'initEventiOrdine',
  title: langstore.t("section_eventiordine", 'Eventi ordine')
}, {
  id: 'clientiDettaglio',
  path: "/clienti/dettaglio/:idClient",
  content: 'Clientdetail',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  action: "clientdetail",
  title: langstore.t("section_clientedettaglio", 'Dettaglio cliente')
}, {
  id: 'clientiRubrica',
  path: "/clienti/rubrica",
  content: 'Clientcontacts',
  action: 'clientcontacts',
  sidebar: 'SidebarMenu',
  navigation: "NavigationTitle",
  title: langstore.t("section_clientirubrica", 'Rubrica clienti')
}, {
  id: 'ordini',
  path: "/ordini",
  content: 'Confirmedorders',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  action: 'orders',
  title: langstore.t("section_ordiniattivi", 'Ordini attivi')
}, {
  id: 'orderDetail',
  path: '/ordini/dettaglio/:orderYear/:orderNumber/:orderId',
  content: "Orderdetail",
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  action: 'orderdetail',
  titleKey: 'section_ordinedettaglio',
  titleString: 'Dettaglio ordine',
  title: langstore.t("section_ordinedettaglio", 'Dettaglio ordine')
}, {
  id: 'nuovoreclamo',
  path: '/nuovoreclamo/:orderYear/:orderNumber/:orderId',
  content: "NuovoReclamo",
  action: "nuovoReclamoInit",
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  titleKey: 'section_nuovoreclamo',
  titleString: 'Nuovo reclamo',
  title: langstore.t("section_nuovoreclamo", 'Nuovo reclamo')
}, {
  id: 'invoices',
  path: '/fatture',
  action: 'invoiceList',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Invoices",
  title: langstore.t("section_elencofatture", 'Elenco fatture')
}, {
  id: 'ddt',
  path: '/ddt',
  action: 'ddtList',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "DDT",
  title: langstore.t("section_ddt", 'DDT')
}, {
  id: 'payments',
  path: '/noteaccredito',
  action: 'paymentList',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Payments",
  title: langstore.t("section_noteaccredito", 'Note di accredito')
}, {
  id: 'tweb_testata',
  path: '/tweb/testata',
  action: 'twebredirect',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Loading",
  title: langstore.t("section_redirect", 'Redirect')
}, {
  id: 'tweb_ordinisospesi',
  path: '/tweb/ordinisospesi',
  action: 'twebredirect',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Loading",
  title: langstore.t("section_redirect", 'Redirect')
}, {
  id: 'tweb_ordiniconfermati',
  path: '/tweb/ordiniconfermati',
  action: 'twebredirect',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Loading",
  title: langstore.t("section_redirect", 'Redirect')
}, {
  id: 'gtlfviewer',
  path: "/3dview/:orderYear/:orderNumber/:orderId",
  content: "GtlfViewer",
  //navigation: "NavigationTitle",
  //sidebar: 'SidebarMenu',
  action: 'view3dModel',
  //title: langstore.t("section_3dview",'Visualizza 3D')
}, {
  id: 'reports',
  path: '/reports',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Reports",
  title: 'Report statistiche',
  action: "reports"
},
{
  id: 'elettrodomestici',
  path: '/elettrodomestici',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Eldom",
  title: langstore.t("section_eldom", 'Elettrodomestici'),
  action: "eldomInit"
  // action: "eldom"
},
{
  id: 'mediamanagement',
  path: '/mediamanagement',
  navigation: "NavigationPath",
  sidebar: 'SidebarMenu',
  content: "MediaManagement",
  title: langstore.t("section_mediamanagement", 'Documenti'),
  action: "mediaManagamentInit"
  // action: "eldom"
},


/* Rotte reclami*/

{
  id: 'messaggi_scrivi',
  path: '/ordini/messaggio/:orderYear/:orderNumber/:orderId',
  navigation: "NavigationTitle",
  content: "MessaggiScrivi",
  action: "messaggiScriviInit",
  sidebar: 'SidebarMenu',
  title: 'Scrivi messaggio',
  unproctected: true
},

/* END Rotte reclami*/

{
  id: 'statistiche',
  path: '/statistiche',
  navigation: "NavigationTitle",
  content: "Stats",
  action: "getStats",
  sidebar: 'SidebarMenu',
  title: 'Statistiche'
},
{
  id: 'ssoClientsUsers',
  path: '/sso/clienti',
  navigation: 'NavigationTitle',
  content: 'PortalClientiSso',
  action: 'getSsoClientsUsers',
  sidebar: 'SidebarMenu'
}, {
  id: 'ssoAgentsUsers',
  path: '/sso/agenti',
  navigation: 'NavigationTitle',
  content: 'PortalAgentiSso',
  action: 'getSsoAgentsUsers',
  sidebar: 'SidebarMenu'
}];

export default routes;