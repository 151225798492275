import { runInAction, toJS } from "mobx";
import moment from "moment";
import { downloadResponseFile } from "System/actions/files";
import { is_touch_device } from 'System/utilities/devices.js';
import langstore from 'System/i18n/translator.js';

const getMediaStructure = async function (params, options) {
  const loadingKey = 'getMediaStructure';

  try {
    if (!options?.noLoad) {
      this.loadingAdd(loadingKey);
    }

    let response = await this.dataLayer({
      url: `${this.config.paths.apiURL}documenti/struttura`,
      cacheAge: 0,
      userToken: this.userToken
    });

    if (response?.data?.struttura) {
      return response.data.struttura;
    }
  } finally {
    this.loadingRemove(loadingKey);
  }
};

const getMediaItems = async function (params, options) {
  const loadingKey = 'getMediaItems';

  try {
    const { nodes } = params;

    if (!options?.noLoad) {
      this.loadingAdd(loadingKey);
    }

    let response = await this.dataLayer({
      url: `${this.config.paths.apiURL}documenti/media/lista`,
      cacheAge: 0,
      params: {
        nodi_struttura: nodes
      },
      userToken: this.userToken
    });

    if (response?.data?.media) {
      return buildMediaNodes(response.data.media);
    }
  } finally {
    this.loadingRemove(loadingKey);
  }
};


const getMediaItem = async function (node) {
  return await this.getMediaItems({ nodes: [node] });
};


const buildMediaStructure = (structure) => {
  return structure
    .reduce((acc, item) => {
      let {
        ds_nodo,
        id_nodo,
        id_nodo_padre,
        n_ordinamento
      } = item;

      id_nodo_padre = id_nodo_padre || "root";

      if (!acc[id_nodo_padre]) {
        acc[id_nodo_padre] = [];
      }

      acc[id_nodo_padre].push({
        ...item,
        id_nodo_padre,
        text: ds_nodo,
        type: "node",
        id: id_nodo
      });

      acc[id_nodo_padre] = acc[id_nodo_padre]
        .sort(({ n_ordinamento: ordA }, { n_ordinamento: ordB }) => {
          return ordA > ordB ? 1 : (ordA < ordB ? -1 : 0)
        });

      return acc;
    }, {});
};

const buildMediaNodes = (nodes) => {
  return nodes.map((node) => {
    let {
      id_media: id,
      ds_media_lingua: text,
      ext: type,
      dt_pubblicazione
    } = node;

    dt_pubblicazione = dt_pubblicazione ? moment(dt_pubblicazione).format("DD/MM/YYYY") : "";

    return { ...node, id, text, type, dt_pubblicazione }
  });
}


const mediaManagamentInit = async function () {
  const loadingKey = 'mediaManagamentInit';
  let structure = [];

  try {
    this.loadingAdd(loadingKey);

    structure = await this.getMediaStructure(null, { noLoad: true });

    structure = buildMediaStructure(structure);
  } catch (e) {
    console.error(e);
  } finally {
    runInAction(() => {
      this.mediaData = structure;
      this.mediaCurrentPath.push("root");
      this.loadingRemove(loadingKey);
    });
  }
};

const selectNode = async function (node, options) {
  const loadingKey = 'selectNode';

  let itemsData = [];
  const { id_nodo, id_nodo_padre } = node;

  try {
    if (!options?.noLoad) {
      this.loadingAdd(loadingKey);
    }

    itemsData = await this.getMediaItem(id_nodo);
  } finally {
    runInAction(() => {
      const indexOfParentNode = this.mediaCurrentPath.indexOf(id_nodo_padre);

      if (indexOfParentNode !== -1) {
        this.mediaCurrentPath = this.mediaCurrentPath.slice(0, indexOfParentNode + 1);
      }

      this.mediaCurrentPath.push(id_nodo);

      this.mediaTreeItemsData[id_nodo] = itemsData;
      this.loadingRemove(loadingKey);
    });
  }
}


const backNode = async function () {
  const loadingKey = 'backNode';

  let newPath = toJS(this.mediaCurrentPath);
  let itemsData = [];
  let id_nodo = "root";

  try {
    this.loadingAdd(loadingKey);

    // Remove last item from path
    newPath.pop();

    // Get new last
    [id_nodo] = newPath.slice(-1);

    // Fetch node content
    itemsData = await this.getMediaItem(id_nodo);
  } finally {
    runInAction(() => {
      this.mediaCurrentPath = newPath;
      this.mediaTreeItemsData[id_nodo] = itemsData;
      this.loadingRemove(loadingKey);
    });
  }
};


const selectMedia = async function (node, options) {
  const loadingKey = 'selectMedia';

  try {
    if (!options?.noLoad) {
      this.loadingAdd(loadingKey);
    }

    let url = this.config.paths.apiURL + 'documenti/media/download/?mode=p&id_media=' + node.id_media;
    let headers = { "Content-Type": "application/json" };
    headers["x-access-token"] = this.userToken;
    let request = { method: "GET", headers: headers };
    let response = await fetch(url, request);

    // Check response content-type
    let contentType = response.headers.get("content-type");
    contentType = contentType.split(";")[0];

    const ctRe = new RegExp("application/json", "gi");

    // If json then parse as json, otherwise handle as file
    if (ctRe.test(contentType)) {
      const jsonResp = await response.json();

      if (jsonResp && jsonResp.status != 'OK') {
        return this.notificationAdd(langstore.t("media_not_found", "Media inesistente"), 'media', null, 'error');
      }

      const { data: { file, mode } } = jsonResp;

      if (mode == 'newtab') {
        return window.open(file, '_blank');
      }

      node.file = file;
      node.mode = mode;
      this.mediaItem = node;
      return;
    }

    // Handle as file
    let contentDisposition = response.headers.get("content-disposition");
    contentDisposition = contentDisposition.split(";")[0];

    // If content disposition equals attachment then download
    if (contentDisposition == 'attachment') {
      return downloadResponseFile(response);
    }

    // Otherwise show in html
    let file = await response.blob();

    node.preview = window.URL.createObjectURL(file);
    node.contentType = file.type;
    this.mediaItem = node;
  } catch (e) {
    console.log(e);
  } finally {
    this.loadingRemove(loadingKey);
  }
};


const downloadMedia = async function (id_media) {
  const { config, userToken } = this
  const { apiURL } = config.paths;

  let location = `${apiURL}documenti/media/download`;

  const requestData = {
    id_media,
    mode: "d",
    t: userToken
  }

  let queryParams = Object.entries(requestData).map(([k, v]) => `${k}=${v}`);

  queryParams = queryParams.join("&");

  location = `${location}?${queryParams}`

  var a = document.getElementById("ts_download_link");
  a.href = location;

  if (is_touch_device()) {
    a.target = "_blank";
  }

  setTimeout(() => {
    a.click();
  }, 10);

  return;
}

const closeDetail = function () {
  this.mediaItem = null;
}

const getNewMediaCount = async function (options) {
  const { dataLayerOptions } = options || {};

  let response = await this.dataLayer({
    url: `${this.config.paths.apiURL}documenti/media/newcount`,
    cacheAge: 0,
    userToken: this.userToken,
    options: dataLayerOptions
  });

  this.dataNewMediaCount = response?.data?.n_new_media || 0;
}

export {
  getMediaStructure,
  getMediaItems,
  getMediaItem,
  mediaManagamentInit,
  selectNode,

  // getMediaItem,
  selectMedia,
  downloadMedia,
  // toggleNode,
  backNode,
  closeDetail,
  getNewMediaCount
}