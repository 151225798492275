import moment from 'moment'
import { runInAction, toJS } from 'mobx';
import { Request, Response } from '../../system/utilities/network.js';
import menuIcons from 'Application/menuIcons'

const getCustomTimes = (timeId) => {
  let date = moment();

  switch (timeId) {
    case 'now':
    case 'today':
      date = moment();
      break;

    // Subtract time
    case 'lastWeek':
      date = moment().subtract(1, 'week');
      break;
    case 'lastMonth':
      date = moment().subtract(1, 'month');
      break;
    case 'last3Months':
      date = moment().subtract(3, 'months');
      break;
    case 'lastYear':
      date = moment().subtract(1, 'year');
      break;
    case 'previous2Years':
      date = moment().subtract(2, 'years');
      break;

    // Subtract time and go to start of the period (start of month, year, etc.)
    case 'startOfWeek':
      date = moment().startOf('isoWeek');
      break;
    case 'startOfMonth':
      date = moment().startOf('month');
      break;
    case 'startOfPrevious3Months':
      date = moment().subtract(2, 'months').startOf('month');
      break;
    case 'startOfYear':
      date = moment().startOf('year');
      break;
    case 'startOfPreviousYear':
      date = moment().subtract(1, 'year').startOf('year');
      break;
    case 'startOfPrevious2Years':
      date = moment().subtract(2, 'years').startOf('year');
      break;
    default:
      date = moment(timeId, 'YYYY-MM-DD');
      break;
  }

  if (!date.isValid()) {
    console.warn("Invalid dateRangeDefault date (in config.json)")

    date = moment();
  }

  return date.format('YYYY-MM-DD');
}

const rangeToDates = function (_rangeId) {
  let rangeId = _rangeId || this.config.format.dateRangeDefault;

  let fr;
  let to = moment().format('YYYY-MM-DD');

  // Custom dateRangeDefault in config
  if (rangeId && rangeId.type === 'custom') {
    const {
      fr: rangeFr,
      to: rangeTo
    } = rangeId;

    fr = getCustomTimes(rangeFr);
    to = getCustomTimes(rangeTo);

    return { fr, to }
  }


  if (rangeId !== "custom") {

    switch (rangeId) {
      case 'year_current':
        fr = moment().startOf('year').format('YYYY-MM-DD');
        break;
      case 'month_current':
        fr = moment().startOf('month').format('YYYY-MM-DD');
        break;
      case 'week_current':
        fr = moment().startOf('week').format('YYYY-MM-DD');
        break;
      case 'day':
        fr = moment().subtract(1, 'days').format('YYYY-MM-DD');
        break;
      case 'week':
        fr = moment().subtract(7, 'days').format('YYYY-MM-DD');
        break;
      case 'month':
        fr = moment().subtract(30, 'days').format('YYYY-MM-DD');
        break;
      case 'month3':
        fr = moment().subtract(90, 'days').format('YYYY-MM-DD');
        break;
      case 'year':
        fr = moment().subtract(365, 'days').format('YYYY-MM-DD');
        break;
      case 'year2':
        fr = moment().subtract(730, 'days').format('YYYY-MM-DD');
        break;
    }

  }

  return {
    fr: fr,
    to: to,
  }
};

const datesToRange = function (from, to) {

  let rangeId = "";

  if (from && to) {

    let rangeRelative = moment(from).diff(to, "days");
    let rangeStartYear = moment().startOf('year').diff(to, "days");
    let rangeStartMonth = moment().startOf('month').diff(to, "days");
    let rangeStartWeek = moment().startOf('week').diff(to, "days");

    if (rangeRelative === rangeStartYear) {
      rangeId = "year_current";
    }
    else if (rangeRelative === rangeStartMonth) {
      rangeId = "month_current";
    }
    else if (rangeRelative === rangeStartWeek) {
      rangeId = "week_current";
    }

    else {

      switch (rangeRelative) {
        case -1:
          rangeId = 'day';
          break;
        case -7:
          rangeId = 'week';
          break;
        case -30:
          rangeId = 'month';
          break;
        case -90:
          rangeId = 'month3';
          break;
        case -365:
          rangeId = 'year';
          break;
        case -730:
          rangeId = 'year2';
          break;
      }

    }

    return rangeId;

  }
};

const gridExportExcel = function () {
  document.querySelector(".dx-icon-export-excel-button").click();
};

const formDataUpdate = function (props) {

  let formData = toJS(this.formData);

  for (var prop in props) {
    formData[prop] = props[prop];
  }
  runInAction(() => {
    this.formData = formData;
  });
};

const updateStatiReclamo = async function (options) {

  const { dataLayerOptions } = options || {};

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'common/statireclamo',
    cacheAge: this.config.cacheAPI.initData,
    params: {},
    userToken: this.userToken,
    options: dataLayerOptions
  });

  if (response && response.data && response.data.capigruppo) {

    // let out = response.data.capigruppo.map((n)=>{
    //   return {
    //     id: n.c_cliente_gruppo,
    //     label: n.ragione_sociale_1
    //   }
    // });

    console.log(response.data);

    // this.infoStatiReclamo = out
  }
  return true;
};

const updateCapigruppo = async function (options) {

  const { dataLayerOptions } = options || {};

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'cliente/capigruppo',
    cacheAge: this.config.cacheAPI.initData,
    params: {},
    userToken: this.userToken,
    options: dataLayerOptions
  });

  if (response && response.data && response.data.capigruppo) {

    let out = response.data.capigruppo.map((n) => {
      return {
        id: n.c_cliente_gruppo,
        label: n.ragione_sociale_1
      }
    });

    this.infoCapigruppo = out
  }
  return true;
};

const updateNumeratori = async function (options) {

  const { dataLayerOptions } = options || {};
  // this.infoNumeratori = [{id: "OCL", label: "Nome 1"},{id: "OCL2", label: "Nome 2"},{id: "OCL3", label: "Nome 3"}];

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'common/numeratorivendita',
    cacheAge: this.config.cacheAPI.initData,
    params: {},
    userToken: this.userToken,
    options: dataLayerOptions
  });

  if (response && response.data && response.data.numeratori) {

    let out = response.data.numeratori.map((n) => {
      return {
        id: n.c_numeratore,
        label: n.ds_numeratore
      }
    });

    this.infoNumeratori = out
  }

  return true;
};

const updateNumeratoriBolla = async function (options) {

  const { dataLayerOptions } = options || {};

  // API call
  let response = await this.dataLayer({
    url: `${this.config.paths.apiURL}common/numeratoribolla`,
    cacheAge: this.config.cacheAPI.initData,
    params: {},
    userToken: this.userToken,
    options: dataLayerOptions
  });

  const numeratoriBolla = response?.data?.numeratoriBolla || [];

  this.infoNumeratoriBolla = numeratoriBolla.map(({ c_numeratore, ds_numeratore }) => ({
    id: c_numeratore,
    label: ds_numeratore
  }));

  return true;
};

const updateNumeratoriFattura = async function (options) {

  const { dataLayerOptions } = options || {};

  // API call
  let response = await this.dataLayer({
    url: `${this.config.paths.apiURL}common/numeratorifattura`,
    cacheAge: this.config.cacheAPI.initData,
    params: {},
    userToken: this.userToken,
    options: dataLayerOptions
  });

  const numeratoriFattura = response?.data?.numeratoriFattura || [];

  this.infoNumeratoriFattura = numeratoriFattura.map(({ c_numeratore, ds_numeratore }) => ({
    id: c_numeratore,
    label: ds_numeratore
  }));

  return true;
};

const updateStatiOrdine = async function (options) {

  const { dataLayerOptions } = options || {};

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'common/statiordinevenditaportale',
    cacheAge: this.config.cacheAPI.initData,
    params: {},
    userToken: this.userToken,
    options: dataLayerOptions
  });

  if (response && response.data && response.data.stati) {

    let out = response.data.stati.map((n) => {
      return {
        id: n.fg_stato_ordine_portale,
        label: n.ds_fg_stato_ordine_portale
      }
    });

    this.infoStatiOrdine = out
  }

  return true;
};

const updateLanguages = async function (options) {

  const { dataLayerOptions } = options || {};

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'languages',
    cacheAge: this.config.cacheAPI.initData,
    params: {},
    userToken: this.userToken,
    options: dataLayerOptions
  });

  if (response) {
    this.languages = response.data.languageConfig.languages;
  }

  return true;
};

const updateMenu = async function (options) {

  const { dataLayerOptions } = options || {};

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'menu',
    cacheAge: this.config.cacheAPI.initData,
    params: {},
    userToken: this.userToken,
    options: dataLayerOptions
  });

  if (response) {
    const menu = response.data


    const menuBuilder = (parentId, newId) => {
      var i = 0;
      const block = [];

      menu
        .sort((a, b) => {
          if (a.n_ordinamento < b.n_ordinamento) {
            return -1;
          }
          if (a.n_ordinamento > b.n_ordinamento) {
            return 1;
          }
          return 0;
        })
        .forEach(m => {
          let blockArray = [];

          if (m.guid_item_padre !== parentId) {
            return;
          }

          const children = menu.filter(mu => mu.guid_item_padre == m.guid);
          const idMenu = newId ? `${newId}.${i + 1}` : `${i + 1}`;

          const initialSlash = m.c_tipoazione != 'ssoredirect' && m.c_tipoazione != 'externalurl';

          const mappedItem = {
            idMenu,
            c_menu: m.c_menu_item,
            path: `${initialSlash ? '/' : ''}${m.url}`,
            actionType: m.c_tipoazione,
            titleKey: `${m.c_chiave_traduzione}`,
            titleString: `${m.ds_menu_item}`,
            menu: `main`,
            iconClass: menuIcons[m.c_menu_item],
            params: m.parametri
          };

          if (newId) {
            mappedItem.parent = newId;
          }

          blockArray.push(mappedItem);

          if (children.length > 0) {
            blockArray = [
              ...blockArray,
              ...menuBuilder(m.guid, idMenu)
            ]
          }

          block.push(...blockArray);

          i++;
        })
      return block
    }

    this.menus = menuBuilder(null);
  }

  return true;
};

const updateAutorizzazioni = async function () {
  var request = {
    method: 'get',
    headers: {
      "Content-Type": "application/json",
      "x-access-token": this.userToken
    }
  };
  var url = this.config.paths.apiURL + "profilo/autorizzazioni";

  const response = await fetch(url, request).then(function (response) {
    return response.json();
  }).then(function (result) {
    return new Response(result);
  });

  if (response && response.data && response.data.autorizzazioni) {
    this.dataAutorizzazioni = response.data.autorizzazioni;
  }

  return true
};

const startPings = function (options) {

  const { dataLayerOptions } = options || {};

  // get ping interval
  let timer = 10000;
  if (this.config && this.config.cacheAPI && this.config.cacheAPI.processes) {
    timer = this.config.cacheAPI.processes;
  }

  let interval = setInterval(() => {
    // whitelist calls
    let notifications = toJS(this.dataEventiOrdine) || [];
    let isLoading = this.networkLoading;
    let isLogged = this.userToken;
    let isPage = this.routeParamsCurrent && this.routeParamsCurrent.routeId && (this.routeParamsCurrent.routeId == "portafoglioordini" || this.routeParamsCurrent.routeId == "orderDetail" || this.routeParamsCurrent.routeId == "ordini");
    if (isLoading.length >= 1 || isLogged == null || !isPage || notifications.length == 0) {
      return;
    }
    this.updateEventiOrdini({ dataLayerOptions });
  }, timer);
};

const updateEventiOrdini = async function (options) {

  const { dataLayerOptions } = options || {};

  if (this.config.orderEvents !== true) {
    return;
  }

  let orderevents = await this.dataLayer({
    url: this.config.paths.apiURL + '/ordine/eventiordine',
    cacheAge: 1,
    userToken: this.userToken,
    omitBody: true,
    options: dataLayerOptions
  });

  if (orderevents && orderevents.data) {
    this.dataEventiOrdine = orderevents.data;
  }

  return true;

};

const updateEnabledEmails = async function (options) {

  const { dataLayerOptions } = options || {};

  this.enabledEmails = {};

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'email/enabled',
    cacheAge: 0,
    params: {},
    userToken: this.userToken,
    options: dataLayerOptions
  });

  if (response && response.data && response.data.enabledMails) {
    this.enabledEmails = response.data.enabledMails;
  }

  return true;
};

const hasTableHiddenFilters = function (tableIndex) {
  const { hideTableColumnFilters } = this.config;

  if (hideTableColumnFilters && Array.isArray(hideTableColumnFilters)) {
    return hideTableColumnFilters.includes(tableIndex);
  }

  return false;
};

const updateClassificazioniOrdine = async function (options) {
  const { dataLayerOptions } = options || {};

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'common/classificazioniordine',
    cacheAge: this.config.cacheAPI.initData,
    params: {},
    userToken: this.userToken,
    options: dataLayerOptions
  });

  if (response?.data?.classificazioniOrdine) {
    this.dataClassificazioniOrdine = response?.data?.classificazioniOrdine;
  }

  return true;
};

export {
  formDataUpdate,
  gridExportExcel,
  updateCapigruppo,
  updateNumeratori,
  updateNumeratoriBolla,
  updateNumeratoriFattura,
  updateStatiOrdine,
  rangeToDates,
  datesToRange,
  updateLanguages,
  updateMenu,
  updateAutorizzazioni,
  updateStatiReclamo,
  updateEventiOrdini,
  updateEnabledEmails,
  hasTableHiddenFilters,
  startPings,
  updateClassificazioniOrdine
}