import Loadable from 'react-loadable';
import Loading from '../system/views/loading.jsx';


const ModalMessage = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./modals/modalmessage.jsx"),
	loading: Loading
});

const ModalDefault = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./modals/modaldefault.jsx"),
	loading: Loading
});

const ModalMessageRows = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./components/messagesidebar/modalRows.jsx"),
	loading: Loading
});

const ModalMessageSend = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./components/messagesidebar/modalSend.jsx"),
	loading: Loading
});

let modals = {
	ModalMessage,
	ModalDefault,
	ModalMessageRows,
	ModalMessageSend
}

export default modals;