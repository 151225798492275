import React from 'react'
import ReactDOM from 'react-dom'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import Route from 'route-parser';

export default inject("rootstore", "langstore")(observer((props) => {

  let opens = toJS(props.rootstore.menusOpen);
  let menuGroups = ['main'];

  function clickMenuItem(route, expandTree) {
    const {actionType} = route;

    switch(actionType) {
      case 'none':
        if(expandTree !== false){
            props.rootstore.routesTreeToggle(route.idMenu);
        }
        break;
      case 'ssoredirect':
        props.rootstore.ssoRedirect(route.path, route.params)
        //props.rootstore.routeChangeWithLink(route.path,route.external,route.target,route.params);
        break;
      case 'externalurl':
        window.open(route.path, '_blank');
        break;
      default:
        if(expandTree !== false){
          props.rootstore.routesTreeOpen(route.idMenu);
        }
        props.rootstore.routeChangeWithLink(route.path,route.external,route.target,route.params);
    }
  }

  return(
  <div>

    {menuGroups.map( (group,group_i)=>{

      let groupItems = props.rootstore.routesTreeCurrent[group];

      return(

       <nav className="ts_sidebar_menu" key={group_i}>

          {groupItems.map( (route,i)=>{
              const badge = route.badge || {};

            let isOpen = opens.indexOf(route.idMenu) >= 0;
            let path = new Route(route.path);
            let routeIsActive = path.match(props.rootstore.route) !== false;      

            return(
              <div 
                className="ts_sidebar_item"
                key={route.idMenu}>

                  {route.children && 
                    <span 
                      className={isOpen ? "ts_sidebar_expand" : "ts_sidebar_expand rotated"} 
                      onClick={ ()=>props.rootstore.routesTreeToggle(route.idMenu)}><i className="icon-keyboard_arrow_up icon_m"/></span> }

                  <div 
                    className={routeIsActive ? "ts_sidebar_item_title active" :  "ts_sidebar_item_title"} 
                    onClick={() => clickMenuItem(route)}
                  >
                    <i className={route.iconClass ? route.iconClass + " icon_s" : "icon-folder_open icon_m"}/>
                    <span className="ts_text2 ts_sidebar_item_label">{props.langstore.t(route.titleKey,route.titleString)}</span> 
                    {badge.count > 0 &&
                      <span className="ts_badge">{badge.count}</span>
                    }
                    
                    { route.counter && 
                      props.rootstore[route.counter] && 
                      props.rootstore[route.counter].length >= 1 && 
                      <span className="ts_sidebar_count">{props.rootstore[route.counter].length}</span>}
                  </div>

                 {route.children && isOpen && 
                   <div className="ts_sidebar_item_child"> 

                     {route.children.map( (routeChild,ii)=>{

                        let path = new Route(routeChild.path);
                        let routeChildIsActive = path.match(props.rootstore.route) !== false; 

                        return(

                          <div 
                            key={routeChild.idMenu} 
                            className={routeChildIsActive ? "ts_sidebar_item_title active" :  "ts_sidebar_item_title"} 
                            onClick={() => clickMenuItem(routeChild, false)}
                          > 
                            <i className={routeChild.iconClass + " icon_s" || "icon-folder_open icon_m"}/>
                            <span className="style_sidebar_subitem">{props.langstore.t(routeChild.titleKey,routeChild.titleString)}</span>
                          </div>

                        )
                     })}

                   </div>
                 }

                 </div>
            )
          })}

        </nav> 
      )

    })}

    {props.rootstore.userToken ? 
      <nav className="ts_sidebar_menu">
        <div className="ts_sidebar_item">
          <div 
            className={props.rootstore.routeIdCurrent == 'userprofile' ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"} 
            onClick={ ()=> props.rootstore.routeChange("/profile") }> 
            <i className="icon-person icon_m"/>
            <span className="ts_text2 ts_sidebar_item_label">{ props.langstore.t("user_profile","Profilo") }</span>
          </div>
        </div>

        <div className="ts_sidebar_item d-none">
          <div 
            className={props.rootstore.routeIdCurrent == 'downloads' ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"} 
            onClick={ ()=> props.rootstore.routeChange("/download") }> 
            <i className="icon-notifications icon_m"/>
            <span className="ts_text2">{ props.langstore.t("download_menu_item","Download") }</span>
            {props.rootstore.downloads.length >= 1 && <span className="ts_sidebar_count">{props.rootstore.downloads.length}</span>}
          </div>
        </div>

      </nav>
      :

      <nav className="ts_sidebar_menu">
        <div className="ts_sidebar_item">
          <div 
            className={props.rootstore.routeIdCurrent == 'userlogin' ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"} 
            onClick={ ()=> props.rootstore.routeChange("/login") }> 
            <i className="icon-person icon_s"/>
            <span className="ts_text2 ts_sidebar_item_label">{ props.langstore.t("user_login","Accedi al portale") }</span>
          </div>
        </div>
      </nav>
    }

    <nav className="ts_sidebar_menu">
      <div className="ts_sidebar_item">
        <div 
          className="ts_sidebar_item_title ts_sidebar_toggle" 
          onClick={ ()=> props.rootstore.uiSidebarToggle() }> 
          <i className="icon-keyboard_arrow_left icon_s"/>
          <span className="ts_text2 ts_sidebar_item_label">{ props.langstore.t("ui_minimize_sidebar","Minimizza") }</span>
        </div>
      </div>
    </nav>

  </div>
  )
}));

