// {
//     "dt_richiesta": "2019-11-26T17:07:16.807Z",
//     "n_evento": 330659,
//     "n_anno": 2019,
//     "c_numeratore": "OCL",
//     "n_ordine": 9481,
//     "c_stato": 10,
//     "ds_stato": "DA ELABORARE",
//     "dt_elaborazione": null,
//     "log_errore": " "
// }

/*
  Validatori per azioni
  Possono variare per cliente
  Si settano nel config
*/

const activeOrderEvents = function () {

  let params = this.routeParamsCurrent;
  let events = this.dataEventiOrdine;
  let activeEvent = false;
  let activeEventLabel = "";
  for (var i = 0; i < events.length; i++) {

    let d = events[i];

    if (d.n_anno == parseInt(params.orderYear) &&
      d.n_ordine == params.orderId &&
      d.c_numeratore == params.orderNumber &&
      d.c_stato == 10) {
      activeEvent = true;
      activeEventLabel = d.ds_stato;
    }
    // break; 
    // c_stato è sempre 10?
  }


  return {
    activeEvent: activeEvent,
    activeEventLabel: activeEventLabel
  }

}


const stc_OrderComplete = function (order) {
  let permission = (
    order.fg_ordine_annullato == 0 &&
    order.fg_stato_ordine <= 3 &&
    order.fg_stato_ordine >= 0
  );
  return {
    permission: permission,
    message: "Messaggio da definire"
  }
}

const stc_OrderCancel = function (order) {
  let permission = (
    order.fg_ordine_annullato == 0 &&
    order.fg_stato_ordine <= 50 &&
    order.fg_stato_ordine >= 0 &&
    order.n_bolla == 0
  );
  return {
    permission: permission,
    message: "Messaggio da definire"
  }
}

const stc_MessageCreate = function (order) {
  let permission = (
    order.fg_ordine_annullato == 0 &&
    order.fg_stato_ordine >= 0 &&
    order.fg_stato_ordine <= 30
  );
  return {
    permission: permission,
    message: "Messaggio da definire"
  }
}

const stc_ReclamoCreate = function (order) {
  let permission = (
    order.fg_stato_ordine >= 30
  );
  return {
    permission: permission,
    message: "Messaggio da definire"
  }
}

const orderEventValidators = {
  stc_OrderComplete,
  stc_OrderCancel,
  stc_MessageCreate,
  stc_ReclamoCreate
}

/*
  Azioni di utilità
*/

const canCreateReclamo = function (order, config) {
  let can = false;
  let validator = orderEventValidators[config.format.orderCreateReclamo];
  if (validator && order) {
    can = validator(order).permission;
  }
  return can;
}

const canCreateMessage = function (order, config) {
  let can = false;
  let validator = orderEventValidators[config.format.orderCreateMessage];
  if (validator && order) {
    can = validator(order).permission;
  }
  return can;
}

const canConfirm = function (order, config) {
  let can = false;
  let validator = orderEventValidators[config.format.orderCompleteFilter];
  if (validator && order) {
    can = validator(order).permission;
  }
  return can;
}

const canCancel = function (order, config) {
  let can = false;
  let validator = orderEventValidators[config.format.orderCancelFilter];
  if (validator && order) {
    can = validator(order).permission;
  }
  return can;
}

const findOrderEvent = function (order, events) {

  if (order && events) {

    let ev = events.find((event) => {
      return (
        event.n_anno === order.n_anno &&
        event.c_numeratore === order.c_numeratore &&
        event.n_ordine === order.n_ordine
      )
    });

    if (ev) {
      return ev;
    }

  }

  return {};
}

const findOrderEventInfo = function (order, events, config) {

  let eventInfo = {
    canCancel: false,
    canConfirm: false,
    canCreateMessage: false,
    ds_stato: "nodata",
    c_stato: 0,
    event: {}
  }

  if (order) {
    eventInfo.canCancel = canCancel(order, config);
    eventInfo.canConfirm = canConfirm(order, config);
    eventInfo.canCreateMessage = canCreateMessage(order, config);
    eventInfo.canCreateReclamo = canCreateReclamo(order, config);
  }

  if (order && events && events !== null) {
    let ev = findOrderEvent(order, events);
    if (ev) {
      eventInfo.event = ev;
      eventInfo.ds_stato = ev.ds_stato;
      eventInfo.c_stato = ev.c_stato;
    }
  }

  return eventInfo;
}


/*
  Computed per verificare lo stato delle azioni per singolo ordine
*/

const orderEventsInfoCurrent = function () {

  let order = this.dataOrder && this.dataOrder.dettaglioOrdine ? this.dataOrder.dettaglioOrdine : {};
  let events = this.dataEventiOrdine;
  return findOrderEventInfo(order, events, this.config);
}

const findOrderEventInfoAll = function () {
  let orders = this.dataOrders || [];
  let events = this.dataEventiOrdine;
  return orders.map((order) => {
    findOrderEventInfo(order, events, this.config);
  });
}

/*
  Computed per notifiche sui messaggi
*/

const orderEventNotificactionCurrent = function () {
  return this.dataEventiOrdine.find((event) => {
    event.read === false;
  });
}



export {
  findOrderEvent,
  orderEventValidators,
  findOrderEventInfo,
  findOrderEventInfoAll,
  orderEventsInfoCurrent,
  orderEventNotificactionCurrent,
  canCancel,
  canConfirm,
  activeOrderEvents,
  canCreateMessage,
  canCreateReclamo
}