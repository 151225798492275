import Loadable from 'react-loadable';
import Loading from 'System/views/loading.jsx';


const Orderdetail = Loadable({
	loader: () => import(/* webpackChunkName: "Orders" */ "./views/orderdetail.jsx"),
	loading: Loading
});

const Confirmedorders = Loadable({
  loader: () => import(/* webpackChunkName: "Orders" */ "./views/confirmedorders.jsx"),
  loading: Loading
});

const Sostituzioni = Loadable({
  loader: () => import(/* webpackChunkName: "Orders" */ "./views/sostituzioni.jsx"),
  loading: Loading
});

const PortafoglioOrdini = Loadable({
  loader: () => import(/* webpackChunkName: "Orders" */ "./views/PortafoglioOrdini"),
  loading: Loading
});

const Invoices = Loadable({
  loader: () => import(/* webpackChunkName: "Invoices" */ "./views/invoices.jsx"),
  loading: Loading
});

const Stats = Loadable({
  loader: () => import(/* webpackChunkName: "Invoices" */ "./views/Stats"),
  loading: Loading
});



const views = {
	Orderdetail,
	Confirmedorders,
	Sostituzioni,
	PortafoglioOrdini,
  Invoices,
  Stats
}

export default views;