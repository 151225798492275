import moment from 'moment'
import { runInAction, toJS } from 'mobx';
import { Request, Response } from '../../system/utilities/network.js';
import menuIcons from 'Application/menuIcons'

const getInfoUtente = async function (options) {

  const { dataLayerOptions } = options || {};

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'profilo/getuserdetails',
    cacheAge: this.config.cacheAPI.initData,
    params: {},
    userToken: this.userToken,
    options: dataLayerOptions
  });

  if (response) {
    this.dataUserDetails = response.data.details;
  }

  return true;
}

export {
  getInfoUtente
}