import React from 'react'
import ReactDOM from 'react-dom'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

// error boundary
import { ErrorBoundary } from '../components/errors.jsx';

// views
import views from "../../system/views.js";
import viewsCustom from "../../application/views.js";
var viewsClient = {}

try { viewsClient = require('ClientCustomization/views').default; } catch (ex) { }

// modals
import modals from "../../system/modals.js";
import modalsCustom from "../../application/modals.js";

var modalsClient = {}

try { modalsClient = require('ClientCustomization/modals').default; } catch (ex) { }

// overwrite system with application
Object.assign(views, viewsCustom, viewsClient);
Object.assign(modals, modalsCustom, modalsClient);


const RouterContent = inject('rootstore')(observer((props) => {

  const routes = props.rootstore.routes;

  return (
    <span>

        {routes.map((route) => {
          if (props.rootstore.routeIdCurrent === route.id) {
            const ViewCurrent = views[route.content];
            return (
              <div
                id={`ts_view-${route.id}`}
                className="ts_view" key={route.id + JSON.stringify(props.rootstore.routeParamsCurrent)}
              >
                <ErrorBoundary>
                  <ViewCurrent />
                </ErrorBoundary>
              </div>
            )
          }
        })}

    </span>
  )
}));


const RouterNavigation = inject('rootstore')(observer((props) => {

  const routes = props.rootstore.routes;

  return (
    <span>
      {routes.map((route) => {
        if (props.rootstore.routeParamsCurrent.routeId === route.id) {
          const ViewCurrent = route.navigation && views[route.navigation];
          if (ViewCurrent) {
            return (
              <ViewCurrent key={route.id + JSON.stringify(props.rootstore.routeParams)} />
            )
          }
        }
      })}
    </span>
  )
}));

const RouterSidebar = inject('rootstore')(observer((props) => {

  const routes = props.rootstore.routes;

  return (
    <span>
      {routes.map((route) => {
        if (props.rootstore.routeParamsCurrent.routeId === route.id) {
          const ViewCurrent = route.sidebar && views[route.sidebar];
          if (ViewCurrent) {
            return (
              <ViewCurrent key={route.id + JSON.stringify(props.rootstore.routeParams)} />
            )
          }
        }
      })}
    </span>
  )
}));

const RouterPopup = inject('rootstore')(observer((props) => {

  // creare configurazione popup

  const modalItems = toJS(props.rootstore.modalItems);
  let modalItem = null;
  let ModalView = null;

  if (modalItems.length >= 1) {
    modalItem = modalItems[0];
    ModalView = modals[modalItem.component];
  }

  const params = (modalItem && modalItem.params) || {};
  const frameClass = params.frameClass || '';
  const bgClass = params.bgClass || '';
  const closeOnOutsideClick = params.closeOnOutsideClick === false ? false : true;

  return (

    <ReactCSSTransitionGroup
      transitionName="notification"
      transitionEnterTimeout={300}
      transitionLeaveTimeout={0}>

      {modalItems.length >= 1 ?

        <div className="ts_modal_wrap" key={modalItem.component}>
          <div className={`ts_modal_back ${bgClass}`} onClick={() => {
            if (closeOnOutsideClick) {
              props.rootstore.modalCloseAll();
            }
          }} />
          <div className={`ts_modal_frame ${frameClass}`}>
            <ModalView params={modalItem.params} />
          </div>
        </div>

        : null}

    </ReactCSSTransitionGroup>

  )

}));

export { RouterContent, RouterNavigation, RouterSidebar, RouterPopup }

