


const hashString = function(__string) {
    var hash = 0;
    if (__string.length == 0) {
        return hash;
    }
    for (var i = 0; i < __string.length; i++) {
        var char = __string.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
        hash = Math.abs(hash);
    }
    return hash;
}

const getUrlParams = function() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

const getUrlParamsDecoded = function() {
    var vars = {};
    var parts = (window.location.href).replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = decodeURIComponent(value);
    });
    return vars;
}


function cutString( str, n, useWordBoundary=true ){
  if (str.length <= n) { return str; }
  const subString = str.slice(0, n-1); // the original check
  return (useWordBoundary 
    ? subString.slice(0, subString.lastIndexOf(" ")) 
    : subString) + "...";
};


export {hashString,getUrlParams,getUrlParamsDecoded,cutString}