import { runInAction } from 'mobx';
import { Request, Response } from '../../system/utilities/network.js';
import { toJS } from 'mobx';
import langstore from '../../system/i18n/translator.js';
import { is_touch_device } from '../../system/utilities/devices.js'

var saveData = (function () {

  function arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  var a = document.getElementById("ts_download_link");

  return function (blob, filename) {

    let reader = new FileReader();
    reader.onload = (e) => {
      let url2 = reader.result;
      var url = arrayBufferToBase64(url2);
      url = "data:application/octet-stream;base64," + url;
      a.href = url;
      a.download = filename;
      setTimeout(() => {
        a.click();
      }, 100);
    }
    reader.readAsArrayBuffer(blob);

  };

}());

const showDataTouch = async function (requestData) {

  function arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  let url = this.config.paths.apiURL + "pratiche/downloadfile";
  let headers = { "Content-Type": "application/json", "x-access-token": this.userToken };
  let request = { method: 'post', headers: headers };
  let requestBody = new Request({ data: requestData });
  request.body = JSON.stringify(requestBody);

  let _response = await fetch(url, request);
  let _headers = _response.headers;
  let blob = await _response.blob();
  let filename = _headers.get("Content-Disposition");
  let contentType = _headers.get("Content-Type");

  filename = filename ? filename.split("filename=").pop() : "temp";

  if (blob.size < 200 || filename == "temp") {
    this.notificationAdd(langstore.t("file_invalid", "File non trovato"), "fileinvalid2", 3000, "error");
  }
  else {

    var a = document.getElementById("ts_download_link");
    let reader = new FileReader();

    reader.onload = (e) => {
      let url2 = reader.result;
      var url = arrayBufferToBase64(url2);
      url = "data:" + contentType + ";base64," + url;
      a.href = url;
      a.download = filename;
      setTimeout(() => {
        a.click();
      }, 100);
    }
    reader.readAsArrayBuffer(blob);

  }
};

var showData = (function () {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  return function (blob) {
    var url = window.URL.createObjectURL(blob);
    a.target = "_blank";
    a.href = url;
    setTimeout(() => {
      a.click();
    }, 100);

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 10000);
  };
}());

const filePreview = async function () {

  this.modalCloseAll();
  this.loadingAdd("filePreview2222");

  let url2 = this.config.paths.apiURL + "pratiche/downloadfile";
  let headers = { "Content-Type": "application/json", "x-access-token": this.userToken };
  let request = { method: 'post', headers: headers };
  let routeParams = this.routeParamsCurrent.filedata || {};
  let requestData = JSON.parse(atob(routeParams));
  let requestBody = new Request({ data: requestData });

  request.body = JSON.stringify(requestBody);
  let _response = await fetch(url2, request);
  let blob = await _response.blob();
  let _headers = _response.headers;
  let type = "";
  let _title = routeParams;

  _headers.forEach(function (val, key) {
    if (key === "content-type") { type = val; }
    if (key === "content-disposition") {
      _title = val;
      _title = val.split("filename=");
      _title = _title.length >= 1 ? _title[1] : _title[0];
    }
  });

  this.loadingRemove("filePreview");

  if (blob.size < 200) {
    this.notificationAdd(langstore.t("file_invalid", "File non trovato"), "fileinvalid2", 3000, "error");
  }
  else {
    // this.filePreview(item,_title,file,blob,_type);

    function arrayBufferToBase64(buffer) {
      let binary = '';
      let bytes = new Uint8Array(buffer);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    // objecturl test
    let file = requestData;
    let item = requestData;
    let title = _title;
    var url = window.URL.createObjectURL(blob);
    this.previewData = { title, url, item, file, type };


    // filereader test
    // let reader = new FileReader();
    // reader.onload = (e)=> {
    //   let url2 = reader.result;
    //   var url = arrayBufferToBase64(url2);
    //   url = "data:application/pdf;base64,"+url;
    //   this.previewData = {title,url,item,file};
    //   this.routeChange("/previewfile");
    // }
    // reader.readAsArrayBuffer(blob);

  }
}

const mediaPreview = async function (url) {

  this.modalCloseAll();
  this.loadingAdd("filePreview2222");

  let url2 = url;
  let headers = { "Content-Type": "application/json", "x-access-token": this.userToken };
  let request = { method: 'post', headers: headers };
  let routeParams = this.routeParamsCurrent.filedata || {};
  let requestData = JSON.parse(atob(routeParams));
  let requestBody = new Request({ data: requestData });

  request.body = JSON.stringify(requestBody);
  let _response = await fetch(url2, request);
  let blob = await _response.blob();
  let _headers = _response.headers;
  let type = "";
  let _title = routeParams;

  _headers.forEach(function (val, key) {
    if (key === "content-type") { type = val; }
    if (key === "content-disposition") {
      _title = val;
      _title = val.split("filename=");
      _title = _title.length >= 1 ? _title[1] : _title[0];
    }
  });

  this.loadingRemove("filePreview");

  if (blob.size < 200) {
    this.notificationAdd(langstore.t("file_invalid", "File non trovato"), "fileinvalid2", 3000, "error");
  }
  else {
    // this.filePreview(item,_title,file,blob,_type);

    function arrayBufferToBase64(buffer) {
      let binary = '';
      let bytes = new Uint8Array(buffer);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    // objecturl test
    let file = requestData;
    let item = requestData;
    let title = _title;
    var url = window.URL.createObjectURL(blob);
    this.previewData = { title, url, item, file, type };


    // filereader test
    // let reader = new FileReader();
    // reader.onload = (e)=> {
    //   let url2 = reader.result;
    //   var url = arrayBufferToBase64(url2);
    //   url = "data:application/pdf;base64,"+url;
    //   this.previewData = {title,url,item,file};
    //   this.routeChange("/previewfile");
    // }
    // reader.readAsArrayBuffer(blob);

  }
}

const downloadAll = async function (items, fileType) {

  let url = this.config.paths.apiURL + "pratiche/downloadfile";
  let headers = { "Content-Type": "application/json", "x-access-token": this.userToken };
  let request = { method: 'post', headers: headers };

  let fileConfig = {
    key: "DS_order_docs",
    dsKey: "DS_n_ordine",
    errorLabel: langstore.t("modal_errorfiles_label_order", {
      defaultValue: "Ci sono file mancanti per l'ordine {{orderCode}}",
      orderCode: items[0]["DS_n_ordine"]
    }),
    errorLabel_plural: langstore.t("modal_errorfiles_label_order_plural", "Ci sono file mancanti per gli ordini:")
  }

  switch (fileType) {
    case 'invoice':
      fileConfig.key = "DS_invoice_docs";
      fileConfig.dsKey = "DS_invoice_code";
      fileConfig.errorLabel = langstore.t("modal_errorfiles_label_invoice", {
        defaultValue: "Ci sono file mancanti per la fattura {{invoiceCode}}",
        invoiceCode: items[0]["DS_invoice_code"]
      });
      fileConfig.errorLabel_plural = langstore.t("modal_errorfiles_label_invoice_plural", "Ci sono file mancanti per le fatture:");
      break;
    case 'ddt':
      fileConfig.key = "DS_ddt_docs";
      fileConfig.dsKey = "DS_pk_esterno";
      fileConfig.errorLabel = langstore.t("modal_errorfiles_label_ddt", {
        defaultValue: "Ci sono file mancanti per il DDT {{ddtCode}}",
        ddtCode: items[0]["DS_pk_esterno"]
      });
      fileConfig.errorLabel_plural = langstore.t("modal_errorfiles_label_ddt_plural", "Ci sono file mancanti per i DDT:");
      break;
  }

  let files = [];
  for (var i = items.length - 1; i >= 0; i--) {
    let item = items[i];
    let docs = item[fileConfig.key];
    if (docs) {
      for (var ii = docs.length - 1; ii >= 0; ii--) {
        let doc = docs[ii];
        files.push({
          "DS_code": item[fileConfig.dsKey],
          "c_pratica": doc.c_pratica,
          "c_pratica_guid": doc.c_pratica_guid
        });
      }
    }
  }

  if (files.length == 0) {
    this.notificationAdd(langstore.t("download_nofiles", "La selezione non contiene pratiche"), "fileinvalid0");
  }

  else {

    let requestBody = new Request({ data: files });
    request.body = JSON.stringify(requestBody);

    this.notificationAdd(langstore.t("download_start", "Download in preparazione..."), "fileinvalid1");
    let _response = await fetch(url, request);

    // Get response content type
    const resContentType = ((_response || {}).headers || { get: () => false }).get('content-type');

    if (resContentType.includes('application/json')) {
      const responseJson = await _response.json();

      // If error then manage it, otherwise unhandled response
      if (responseJson.status == 'ERROR') {
        const { data } = responseJson;

        if (responseJson.statusCode == 'MISSING_FILES') {
          this.modalOpen("ModalErrorFiles", {
            modalLabel: fileConfig.errorLabel_plural,
            closeOnOutsideClick: false,
            frameClass: "error_modal_frame",
            bgClass: "error_modal_bg",
            fileList: data.errorFiles
          });
        }
        if (responseJson.statusCode == 'MISSING_FILE') {
          this.modalOpen("ModalErrorFiles", {
            modalLabel: fileConfig.errorLabel,
            closeOnOutsideClick: false,
            frameClass: "error_modal_frame",
            bgClass: "error_modal_bg"
          });
        }
      } else {
        this.notificationAdd(langstore.t("download_error", "Errore download"), "filedownloaderror");
      }
    } else {
      let headers = _response.headers;
      let filename = headers.get("Content-Disposition");
      let blob = await _response.blob();

      filename = filename ? filename.split("filename=").pop() : "temp";

      if (blob.size < 200 || filename == "temp") {
        this.notificationAdd(langstore.t("file_invalid", "File non trovato"), "fileinvalid2", 3000, "error");
      }
      else {
        this.notificationAdd(langstore.t("download_ready", "Download pronto"), "fileinvalid3");
        saveData(blob, filename);
      }
    }
  }
}

const getDownloadLocation = async function (item, file, mode) {
  const { paths: configPaths } = this.config;
  const { c_pratica, c_pratica_guid } = item;
  const { c_versione, c_riga } = file;

  // Prepare data
  let location = `${configPaths.apiURL}pratiche/downloadfile`;

  let requestData = {
    c_pratica,
    c_pratica_guid,
    mode: "t" // test
  };

  if (c_versione) {
    requestData.c_versione = c_versione;
  }

  if (c_riga) {
    requestData.c_riga = c_riga;
  }

  let headers = {
    "Content-Type": "application/json",
    "x-access-token": this.userToken
  };

  const body = {
    data: requestData
  };

  // Test if server responds ok
  let response = await fetch(location, {
    method: "post",
    body: JSON.stringify(body),
    headers
  });

  response = await response.json();

  if (response && response.status != 'OK') {
    const {
      statusCode,
      data,
      logout
    } = response;

    if (statusCode === 'MISSING_FILE') {
      if (data) {
        const { errorFiles } = data;

        const errorObj = {
          modalLabel: langstore.t("file_not_found", "File non trovato:"),
          closeOnOutsideClick: false,
          frameClass: "error_modal_frame",
          bgClass: "error_modal_bg",
          fileList: errorFiles,
          valueField: "fileName"
        };

        if (errorFiles.length > 1) {
          errorObj.modalLabel = langstore.t("file_not_found_plural", "File non trovati:");
        }

        this.modalOvercome("ModalErrorFiles", errorObj);
      }
    }

    if (logout) {
      this.modalCloseAll();
      this.userLogout();
    }

    throw response;
  }

  requestData = {
    ...requestData,
    t: this.userToken,
    mode
  };

  const queryParams = Object.entries(requestData).map(([key, value]) => `${key}=${value}`).join("&");

  return `${location}?${queryParams}`;
}

const downloadSingle = async function (item, ...props) {
  try {
    const [prop1, prop2] = props;

    const title = prop2 ? prop1 : null;
    const file = prop2 || prop1;

    const location = await this.getDownloadLocation(item, file, "d");

    var a = document.getElementById("ts_download_link");
    a.href = location;
    if (is_touch_device()) {
      a.target = "_blank";
    }
    setTimeout(() => {
      a.click();
    }, 10);
  } catch (err) {
    console.log(err);
  }
}

const downloadResponseFile = async (response) => {
  let fileName = "file";
  const contentDisposition = response.headers.get("content-disposition");

  for (const part of contentDisposition.split(";")) {
    const [key, value] = part.trim().split("=");

    if (key == "filename") {
      fileName = value;
      break;
    }
  }

  const a = document.createElement("a");
  document.body.appendChild(a);

  if (!a.style) {
    a.style = "display: none";
  }

  const blob = await response.blob();

  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

const showSingle = async function (item, ...props) {
  try {
    const [prop1, prop2] = props;

    const title = prop2 ? prop1 : null;
    const file = prop2 || prop1;

    const location = await this.getDownloadLocation(item, file, "p");

    const windowReference = window.open();

    setTimeout(() => {
      windowReference.document.title = "File preview";
      windowReference.location = `${location}`;
    }, 10);
  } catch (err) {
    console.log(err);
  }
}

const downloadClear = function () {
  this.downloads = [];
}

export {
  getDownloadLocation,
  downloadSingle,
  showSingle,
  downloadResponseFile,
  downloadAll,
  downloadClear,
  filePreview,
  mediaPreview,
  showDataTouch
}
