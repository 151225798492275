import Loadable from 'react-loadable';
import Loading from '../system/views/loading.jsx';


const ModalPratiche = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./modals/modalpratiche.jsx"),
	loading: Loading
});

const ModalNoteRiga = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./modals/modalnoteriga.jsx"),
	loading: Loading
});

const ModalFatture = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./modals/modalfatture.jsx"),
	loading: Loading
});

const ModalEmail = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./modals/modalemail.jsx"),
	loading: Loading
});

const ModalErrorFiles = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./modals/modalerrorfiles.jsx"),
	loading: Loading
});

const ModalConfirmReclamo = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./modals/ModalConfirmReclamo.jsx"),
	loading: Loading
});

const ModalSuccessReclamo = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./modals/ModalSuccessReclamo.jsx"),
	loading: Loading
});

const ModalImageViewer = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./modals/modalImageViewer.jsx"),
	loading: Loading
});

const ModalConfirm = Loadable({
	loader: () => import(/* webpackChunkName: "Modals" */ "./modals/ModalConfirm.jsx"),
	loading: Loading
});


let modals = {
	ModalPratiche,
	ModalNoteRiga,
	ModalFatture,
	ModalEmail,
	ModalErrorFiles,
	ModalConfirmReclamo,
	ModalSuccessReclamo,
	ModalImageViewer,
	ModalConfirm
}

export default modals;