// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { observer, extendObservable, computed } from 'mobx';
import { Provider } from 'mobx-react';
import Route from 'route-parser';

import 'whatwg-fetch';
import '../system/static/polyfills/classlist-polyfill.min'; // IE className fix

// autorun and external events
import {persistentState,persistentHistory} from '../system/autorun/persistence.js';
import routeSync from '../system/autorun/routesync.js';
import statSync from '../system/autorun/statsync.js';
import keyboardEvents from '../system/autorun/keyboardevents.js';
import windowEvents from '../system/autorun/windowevents.js';
import networkEvents from '../system/autorun/networkevents.js';

// actions
import actions from '../system/actions.js';
import actionsCustom from '../../src/application/actions.js';

// state objects
import {state, computedState} from '../system/state'; 
import {state as stateCustom, computedState as computedStateCustom} from '../../src/application/state';
try {
  const _state = require('ClientCustomization/state');
  var stateClient = _state.state;
  var computedStateClient = _state.computedState;
} catch (ex) {}

// routes
import routes from '../system/routes'
import routesCustom from '../../src/application/routes';
try { var routesClient = require('ClientCustomization/routes').default; } catch (ex) {}

// Base view
import Base from '../system/views/base.jsx';


require("../system/style.js").default;

// Styles
try { require("ClientCustomization/style.js").default; } catch (ex) {}

// Translations
import langstore from '../system/i18n/translator.js';

(async () => {
  try {

    let configURL1 = "./config/config.json";
    let configURL2 = window.location.origin + "/config/config.json";
    let response = await fetch(configURL1,{cache: "reload"});
    if(response.status === 404 ){
      response = await fetch(configURL2,{cache: "reload"});
    }
    let config = await response.json();

    /**
      Routes
    **/

    // Extend system routes with custom routes
    function extendRoute(initialRoute, ...routeArrays) {
      routeArrays.forEach(ra => {
        if(ra){
          ra.forEach(routeObj => {
            const matchMap = initialRoute.map(fa => fa.id);
            const currId = routeObj.id; 
            const matchingPosition = matchMap.indexOf(currId);
    
            if(matchingPosition != -1){
              initialRoute.splice(matchingPosition, 1);
            }

            initialRoute.push(routeObj)
          })
        }
      })
    }

    extendRoute(routes, routesCustom, routesClient);

    /* for (var i = 0; i < routesCustom.length; i++) {
      
      let routeCustom = routesCustom[i];
      let idCustom = routeCustom.id;

      // overwrite system route
      for (var ii = 0; ii < routes.length; ii++) {
        let idSystem = routes[i].id;
        if(idCustom === idSystem){
          routes.splice(ii, 1);
          break;
        }
      }

      routes.push(routeCustom);
    } */


    /**
      Generate default state
    **/
    Object.assign(state,stateCustom,stateClient);
    Object.assign(computedState,computedStateCustom,computedStateClient);

    /**
      Store definition
    **/

    class RootStore {

      constructor(config,state,computedState,routes) {

        /**
          Non observable
        **/

        this.config = config;
        this.routes = routes;

        /** 
          Observable
        **/

        Object.keys(computedState).forEach( (key)=> {
          state[key] = computedState[key];
        })

        Object.keys(computedState).forEach( (key)=> {
          Object.defineProperty(state, key, {
            get: () => {
              return computedState[key].apply(this);
            }
          });
        })

        extendObservable(this,state);

      }

    } 


    /** 
      Actions 
    **/
    var actionsClient = {};
    try { actionsClient = require('ClientCustomization/actions').default; } catch (ex) {}
    
    // Extend / overwrite with custom actions
    Object.assign(actions,actionsCustom,actionsClient);

    // Create mobx state object
    Object.keys(actions).forEach(function(key) {
      RootStore.prototype[key] = actions[key];
    });

    const rootstore = new RootStore(config,state,computedState,routes);
    if(config.development.loggableStore === true){
      window.__store = rootstore;
    }

    /*
     * DEFAULT STATUS
     */

    (()=>{
      const {paths: {namespace}, app, defaultState} = config || {};
      const {version} = app;
      const client = app.client.replace(/\s/g,'').toLowerCase();
      const fullNamespace = `${namespace}-${client}-${version}`;

      if(defaultState){
        Object.keys(defaultState).forEach(key => {
          const cachedState = localStorage.getItem(`${fullNamespace}_${key}`);

          if(cachedState === null){
            rootstore[key] = defaultState[key];
          }
        });
      }
    })();


    /**  
      Autorun effects; order is important
    **/

    persistentState(rootstore,config.cacheInterface);
    persistentHistory(rootstore);
    routeSync(rootstore,routes);
    statSync(rootstore);


    /**
      Additional events
    **/

    keyboardEvents(rootstore);
    windowEvents(rootstore);
    networkEvents(rootstore);


    /**
      App ready
    **/
    rootstore.initCore();
    rootstore.init();


    rootstore.log("Rootstore inizializzato","ROOT");


    /** 
      App render 
    **/

    ReactDOM.render(
      <Provider 
        langstore={langstore}
        rootstore={rootstore}>
        <Base />
      </Provider>,
      document.getElementById('root')
    );


  } catch (e) {
    console.log("Errore in main.jsx",e);
  }

})();