import { runInAction } from 'mobx';
import { Request, Response } from '../../system/utilities/network.js';
import { toJS } from 'mobx';
import moment from 'moment';

const ddtList = async function (params, options) {
  const loadingKey = 'ddtlist';
  const localParams = this.routeParamsCacheGet("ddtParams");
  const dates = this.rangeToDates(this.config.format.dateRangeDefault);

  const _params = {
    "PageNumber": localParams.PageNumber || 1,
    "da_dt_bolla": localParams.da_dt_bolla || dates.fr,
    "a_dt_bolla": localParams.a_dt_bolla || dates.to
  };

  if (localParams.n_anno) { _params.n_anno = localParams.n_anno; }
  if (localParams.c_numeratore) { _params.c_numeratore = localParams.c_numeratore; }
  if (localParams.n_ordine) { _params.n_ordine = localParams.n_ordine; }

  if (localParams.n_anno_bolla) { _params.n_anno_bolla = localParams.n_anno_bolla; }
  if (localParams.c_numeratore_bolla) { _params.c_numeratore_bolla = localParams.c_numeratore_bolla; }
  if (localParams.n_bolla) { _params.n_bolla = localParams.n_bolla; }

  if (localParams.riferimento_cliente) { _params.riferimento_cliente = localParams.riferimento_cliente; }


  // spinner
  this.loadingAdd(loadingKey);

  // API call
  const response = await this.dataLayer({
    url: this.config.paths.apiURL + 'ddt',
    cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
    params: _params,
    userToken: this.userToken
  });

  // business logic
  runInAction(() => {
    if (response) {
      this.dataDDT = response.data;
    }
    this.loadingRemove(loadingKey);
  });

}

export { ddtList }
