import { runInAction } from "mobx";

const cacheId = "statParams";

const getOrdersStats = async function (params, options) {
  const { cacheAge, noLoad } = options || {};

  const {
    userToken,
    config
  } = this

  const {
    format,
    paths,
    cacheAPI
  } = config;

  const loadingKey = 'getOrdersStats';
  const localParams = this.routeParamsCacheGet(cacheId);

  const dates = this.rangeToDates(format.dateRangeDefault);

  if (!noLoad) {
    // spinner
    this.loadingAdd(loadingKey);
  }

  const _params = {
    da_dt_aquisizione: localParams.da_data || dates.fr,
    a_dt_aquisizione: localParams.a_data || dates.to
  }

  // API call
  const response = await this.dataLayer({
    url: `${paths.apiURL}statistics/orders`,
    cacheAge: cacheAge || cacheAPI.default,
    params: _params,
    userToken
  });

  runInAction(() => {
    if (response) {
      this.dataOrderStats = response.data;
    }

    this.loadingRemove(loadingKey);
  });
}



export {
  getOrdersStats
}