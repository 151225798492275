import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'
import {toJS} from 'mobx';


export default inject("rootstore","langstore")(observer((props) => {

  let isActive = false;
  let bookmarks = props.rootstore.bookmarks;
  let url = window.location.href.replace(props.rootstore.config.app.path,"");
  let bookmark = {};
  let state = history.state;
  let routeId = props.rootstore.routeParamsCurrent.routeId;

  for (var i = 0; i < bookmarks.length; i++) {
  	if (bookmarks[i].url === url){
  		isActive = true;
  		bookmark = bookmarks[i];
  		break;
  	}
  }

  if(isActive === true){
	  return(
		  <div 
		    onClick={ ()=> props.rootstore.bookmarkRemove(bookmark.url) }
		    className="ts_bookmark ts_mr2 ts_bookmark_active">
		    <i className="icon_s icon-star" />
		  </div>
	  )
  }
  else{
	  return(
		  <div 
		    onClick={ ()=> props.rootstore.bookmarkSave() }
		    className="ts_bookmark ts_mr2">
		    <i className="icon_s icon-star-o" />
		  </div>
	  )
  }
}));


