import { autorun, extendObservable, toJS, runInAction } from "mobx"

// autorun: local storage sync for user interface
// params: store, list of observable to sync

const persistentState = function(_this,_props) {

  let firstRun = true
  let props = _props || [];

  const {paths, app} = _this.config;

  let {client, version} = app;
  let {namespace} = paths;

  client = client.replace(/\s/g,'').toLowerCase();

  _this.namespaceIndex = `${namespace}-${client}`;
  _this.fullNamespace = `${_this.namespaceIndex}-${version}`;
  
  // UI cache
  autorun(() => {

    for (var i = 0; i < props.length; i++) {
      
      let propName = props[i];
      let propOriginalValue = _this[propName];
      let propValue = JSON.stringify(toJS(_this[propName])) || propOriginalValue;

      // if first run, take value from storage
      if (firstRun) {
        let propSaved = localStorage.getItem(`${_this.fullNamespace}_${propName}`);  
        if(propSaved && propSaved != 'undefined'){
          _this[propName] = JSON.parse(propSaved);
        }
      }

      // else, save to storage

      else{
        localStorage.setItem(`${_this.fullNamespace}_${propName}`, propValue);
      }

    }
  });

  firstRun = false;
}


// autorun: update url history and load route from history
// params: store

const persistentHistory = function historySync(_this){

  let basepath = _this.config.app.path;

  const updateParams = () => {
    let currentRoute = window.location.href.replace(basepath,"");
    if(basepath + _this.route != currentRoute){
      _this.route = currentRoute;
    }
  }

  // first run get the current state from the URL
  updateParams();

  // check back/forward buttons
  window.onpopstate = () => {
    updateParams();
  }

  // change URL when app state change
  autorun( ()=> {
    let route = _this.route;
    let currentRoute = window.location.href.replace(basepath,"");

    _this.log("Rotta richiesta: " + route,"ROTTE");

    if(route != currentRoute){
      window.history.pushState( {}, 'Tesy', basepath + route);
    }

  });
}


export {persistentHistory, persistentState}
