import {
  comunicazioniUnread,
  viewportCurrent,
  routeIdCurrent,
  bodyClassCurrent,
  routeParamsCurrent,
  routesTreeCurrent,
  pathCurrent,
  userTokenProjectionCurrent
} from '../system/computed/common.js';


let computedState = {
  comunicazioniUnread,
  routeParamsCurrent,
  routeIdCurrent,
  bodyClassCurrent,
  routesTreeCurrent,
  pathCurrent,
  userTokenProjectionCurrent,
  viewportCurrent
}


let state = {

      appReady: false,

      userToken: null,
      userService: null,
      userInfo: {
        name: "",
        email: "",
        title: ""
      },

      userTokenRoot: null,
      userInfoRoot: {},
      userServiceRoot: null,

      bookmarks: [],
      notifications: [],
      formData: {},
      transientData: {},
      networkLoading: [],
      isOnline: true, 

      downloads: [1,2],
      
      enabledEmails: {},

      messages: [],

      messageActive: null,
      messageWriting: null,
      messageRowsWriting: null,
      messageUsers: [],

      messagesNew: [],
      messagesTypes: [],

      infoCapigruppo: [],
      infoNumeratori: [],
      infoStatiOrdine: [],

      uiSidebarExpanded: true,
      uiGridOptionsExpanded: false,
      uiGridTotalizationsExpanded: false,
      uiGridEventsExpanded: true,
      uiAreasExpanded: false,
      uiShowCustomer: true,
      uiViewMode: 'table',
      uiLoginEmail: "",
      uiLoginPassword: "",
      uiTableFontSize: 3,
      uiFilter: "tesy_normal",
      uiDocumentWidth: 100,
      uiContentWidth: 100,
      uiScrollInfo: 0,
      uiGridWidth: {},
      uiTouch: false,
      uiSidebarMessagesOpen: false,
      uiContentMessagesOpen: false,
      uiSidebarMessageWriteOpen: false,
      uiActiveMessage: null,

      modalItems: [],
      menus: [],
      menusOpen: [],

      previewData: {},

      selfCliente: null,

      languages: [],
      language: 'it',
      route: "/",

      ssoLoginRunning: false,

      analytics: [],

      cacheParams: {},

      namespaceIndex: '',
      fullNamespace: '',
      networkDBStore: null
    }

 export {state, computedState}