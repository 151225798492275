import { hashString } from '../../system/utilities/strings.js'
import { Request, Response } from '../../system/utilities/network.js';
import langstore from '../../system/i18n/translator.js';
import { openDB } from 'idb'

const dataRequest = async function (data) {

  data = data || {};

  let params = data.params || {};
  let namespace = this.fullNamespace;
  let url = data.url;
  let cacheID = data.cacheID ? String(`${namespace}_${data.cacheID}`) : String(`${namespace}_${hashString(url)}_${hashString(JSON.stringify(params))}`);
  let cacheAge = data.cacheAge || 1000;
  let now = + new Date();
  let userToken = data.userToken || null;
  let result = {}
  let method = data.method || "POST";
  let tempData = null;

  this.log("Parametri " + JSON.stringify(params), "API");

  let dbPromise = {};

  try {

    // IndexedDB
    dbPromise = await openDB(namespace, 2, {
      upgrade(db) {
        db.createObjectStore('networkdata');
      }
    });

    this.networkDBStore = dbPromise;

    let tempData = await dbPromise.get('networkdata', cacheID);
    if (tempData) {
      tempData = JSON.parse(tempData);
    };

  }
  catch (e) {
    this.log(e, "WARNING");
  }


  // if cache and cache is fresh
  if (tempData && tempData.data && tempData.data !== null && tempData.timestamp && ((now - tempData.timestamp) < cacheAge)) {
    result = tempData.data;
    this.log("Sorgente dati cache", "API");
    return result;
  }

  // if no cache or cache is too old
  else {

    try {

      this.log("Sorgente dati network", "API");

      let requestBody = new Request({ data: params });

      let headers = { "Content-Type": "application/json" };
      if (userToken !== null) {
        headers["x-access-token"] = userToken;
      }
      let request = { method: method, headers: headers };
      if (data.omitBody !== true) {
        request.body = JSON.stringify(requestBody);
      }
      let _response = await fetch(url, request);
      let response = await _response.json();
      let then = + new Date();
      result = new Response(response);

      this.log(String(then - now) + "ms", "API");

      if (result.logout === false && result.status === 'OK') {
        try {
          let d = JSON.stringify({
            data: result,
            timestamp: + new Date()
          });
          dbPromise.put('networkdata', d, cacheID);
        }
        catch (e) {
          this.log("Quota localstorage è piena", "WARNING");
        }
      }

      return result;

    }
    catch (e) {
      this.log(e, "WARNING");
      return;
    }

  }
}

// wrapper for network request with logout and cache reset
const dataLayer = async function (data) {

  const { options = {} } = data || {};


  /**
   * Set all message related options to the same value as silent by default.
   */
  const { silent = false } = options;

  const {
    noLogoutNotification = silent,
    noApplicationError = silent,
    noServerError = silent
  } = options;

  let response = await this.dataRequest(data);

  if (response) {

    // logout management
    if (response.logout === true) {
      this.log((response && response.message ? response.message : "Consultare network per i dettagli"), "WARNING");
      this.userLogout({ noLogoutNotification });
      return;
    }

    // unmanaged status
    if (data.rawResponse === true) {
      return response;
    }
    // managed status
    else {
      if (response.status === 'ERROR' && !noApplicationError) {
        this.notificationAdd(langstore.t("user_apperror", "Errore dell'applicazione"), 'userformlogin', null, 'error');
        return;
      }
      else if (response.status === 'OK') {
        return response;
      }
    }


  }
  else {
    if (!noServerError) {
      this.notificationAdd(langstore.t("user_servererror", "Errore del server"), 'userformlogin', null, 'error');
    }
    this.log((response && response.message ? response.message : "Consultare network per i dettagli"), "ERRORE");
    return;
  }
}


const cleanCache = async function (params = {}) {

  try {

    // IndexedDB
    let namespace = this.fullNamespace;
    const dbPromise = await openDB(namespace, 2, {
      upgrade(db) {
        db.createObjectStore('networkdata');
      }
    });

    // case: clear all database
    // if(params.mode === "all"){
    //   this.log("Deleting database");
    //   await dbPromise.clear('networkdata');
    //   return true;;
    // }

    // case: delete specific key
    // if(params.mode === "key" && params.key){
    //   await dbPromise.delete('networkdata',params.key);
    //   return true;;
    // }

    if (params.mode === "route" && params.route) {
      let all = await dbPromise.getAllKeys('networkdata');
      for (let index of all) {
        if (index.indexOf(String(`_${params.route}_`)) >= 0) {
          await dbPromise.delete('networkdata', index);
        }
      }
    }

    return true;

  }
  catch (e) {
    this.log(e, "WARNING");
    return false;
  }
}

export { dataLayer, dataRequest, cleanCache }


