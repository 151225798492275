import { Request, Response } from '../../system/utilities/network.js';
import { toJS, runInAction } from 'mobx';
import moment from 'moment';
import langstore from '../../system/i18n/translator.js';

const twebredirect = async function (params, options) {
  let loadingKey = 'twebredirecttwebredirect';

  // spinner
  this.loadingAdd(loadingKey);

  let _params = { ...params };

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'tweb/gettoken',
    cacheAge: 0,
    params: _params,
    userToken: this.userToken
  });

  // business logic
  runInAction(() => {
    if (response && response.data && response.data.SS0) {
      const { paths, app } = this.config;
      const { hashsegment } = app;
      const { urlTWeb } = paths;

      if (!urlTWeb)
        console.error("Configuration for paths.urlTWeb missing!");

      const splitUrl = (url) => {
        const urlHashArray = url.split('#');
        const urlNoHash = urlHashArray[0];
        const urlParamArray = urlNoHash.split('?');

        const address = urlParamArray[0];
        const params = urlParamArray[1] ? urlParamArray[1] : null;

        return {
          address,
          params
        }
      }

      const paramToObj = (paramString) => {
        return paramString.split('&').reduce((acc, param) => {
          const splitParam = param.split("=");
          acc[splitParam[0]] = splitParam[1];
          return acc;
        }, {});
      }

      const pageUrl = window.location.href;
      const pagePath = hashsegment ? pageUrl.split(hashsegment)[1] : pageUrl;

      const page = splitUrl(pagePath);
      const target = splitUrl(urlTWeb);


      const targetParams = target.params ? paramToObj(target.params) : {};
      const pageParams = page.params ? paramToObj(page.params) : {};
      const twebParams = response.data

      let redirectParams = {
        ...targetParams,
        ...pageParams,
        ...twebParams
      };

      redirectParams = Object.keys(redirectParams).map(p => `${p}=${redirectParams[p]}`);

      redirectParams = redirectParams.join('&');
      redirectParams = redirectParams ? '?' + redirectParams : '';

      window.open(`${target.address}${redirectParams}`, 'ts_tWeb');
      history.back();
    }

    this.loadingRemove(loadingKey);
  });
}

export { twebredirect }