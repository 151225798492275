const Request = class Request {
    constructor(props) {
        props = props || {};
        this.time = props.time || new Date().toLocaleString();
        this.clientVersion = props.clientVersion || '0.0';
        this.data = props.data || null;
    }
}

const Response = class Response {
    constructor(props) {
        this.time = props.time || new Date().toLocaleString();
        this.logout = props.logout || false;
        this.status = props.status || Response.OK;
        this.statusCode = props.statusCode || null;
        this.message = props.message || '';
        this.data = props.data || null;
    }
}
Response.OK = 'ok';
Response.ERROR = 'error';
Response.WARN = 'warning'


export { Request, Response }