import { getUrlParamsDecoded } from "System/utilities/strings.js";
import langstore from 'System/i18n/translator.js';
import { runInAction } from "mobx";


/**
 * Request to gemo sso login
 * @param {string} path Path to the route to call, starting from ${this.config.paths.apiURL}profilo/ssologin/gemo/
 * @param {Object} params Params to be passed to the route 
 * @returns {Object} Response object
 */
let request = async function (path, params) {
  try {
    const response = await this.dataLayer({
      url: `${this.config.paths.apiURL}profilo/ssologin/gemo/${path}`,
      cacheAge: 0,
      params,
      rawResponse: true
    });

    return response;
  } catch (err) {
    throw err;
  }
}

const getGemoSsoParams = function () {
  let {
    op,
    id,
    userid,
    agente,
    ordine
  } = getUrlParamsDecoded();

  const [
    n_anno,
    c_numeratore,
    n_ordine
  ] = (ordine || '').split('-');

  // Agente must be a number
  if (isNaN(agente)) {
    throw new Error();
  }

  // Convert agente to int
  agente = parseInt(agente);

  return { op, id, userid, agente, ordine, n_anno, c_numeratore, n_ordine };
}

/**
 * Reset Gemo sso page
 * @returns {void}
 */
let setSsoPageLoading = function () {
  runInAction(() => {
    this.gemoSsoStatus = {
      title: langstore.t("ssoGemoLogin_wait", "Attendere..."),
      message: "",
      showLoginLink: false
    };
  });

  return;
}

/**
 * Get valid users for the sso login
 * @param {String} id id string param
 * @param {String} userid userid string param
 * @param {Number} agente agente number
 * @returns {Array<Object>} Array of users with relative login token
 */
const getUsers = async function (id, userid, agente) {
  try {
    const response = await request('users', { id, userid, agente });

    const { status, statusCode, data } = response || {};
    const { users } = data || {};

    if (status == "ERROR" && statusCode == "AGENT_NOT_ENABLED") {
      throw new Error("AGENT_NOT_ENABLED");
    }
    if (!users || status !== "OK" || !data || !users || users.length == 0) {
      throw new Error();
    }

    return users;
  } catch (err) {
    throw err;
  }
}

/**
 * Get authentication
 * @param {Object} user User data object (as returned from getUsers)
 * @returns {Object} Response object with authentication data
 */
const getSsoAuthResponse = async function (user) {
  try {
    const response = await request('login', user);
    const { status, data } = response || {};
    const { auth } = data || {};

    if (!response || status !== "OK" || !data || !auth) {
      throw new Error();
    }

    return response;
  } catch (err) {
    throw err;
  }
}

/**
 * Show generic error message
 * @param {String} notificationMessage Optional notification message (will be shown as snackbar)
 */
let failSsoGemo = function (notificationMessage, loadingKey) {
  runInAction(() => {
    if (notificationMessage) {
      this.notificationAdd(notificationMessage, loadingKey);
    }

    this.gemoSsoStatus = {
      title: langstore.t("gemoSsoLogin_invalidLogin_title", "Errore"),
      message: langstore.t("gemoSsoLogin_invalidLogin_message", "Si \xE8 verificato un errore. Impossibile effettuare il login."),
      showLoginLink: true
    };

    this.dataGemoSsoUsers = null;

    this.loadingRemove(loadingKey);
  });
}

const getSsoGemoParams = async function () {

}

/**
 * Get Gemo SSO users
 * @returns {Array<Object>} Array of valid gemo SSO users
 */
const getSsoGemoUsers = async function () {
  const loadingKey = "getSsoGemoUsers";

  // Bind functions
  request = request.bind(this);

  try {
    let gemoSsoParams = null;
    let users = null;

    // Set users to null
    this.dataGemoSsoUsers = null;

    // Reset sso page
    this.setSsoPageLoading();

    // Get params
    try {
      gemoSsoParams = getGemoSsoParams();
    } catch (err) {
      return this.failSsoGemo(langstore.t("sso_gemo_error_params", "Formato URL errato"), loadingKey);
    }

    const {
      op,
      id,
      userid,
      agente,
      n_anno,
      c_numeratore,
      n_ordine
    } = gemoSsoParams;

    // Preliminary checks - check if all needed params are present
    switch (op) {
      case 'DOC':
        if (!(id && userid && agente && n_anno && c_numeratore && n_ordine)) {
          return this.failSsoGemo(langstore.t("sso_gemo_error_params", "Formato URL errato"), loadingKey);
        }
        break;
      case 'LOGIN':
        if (!(id && userid && agente)) {
          return this.failSsoGemo(langstore.t("sso_gemo_error_params", "Formato URL errato"), loadingKey);
        }
        break;
      default:
        return this.failSsoGemo(langstore.t("sso_gemo_error_params", "Formato URL errato"), loadingKey);
    }

    // showLoader
    this.loadingAdd(loadingKey);

    // Get users
    try {
      await this.userLogout();

      users = await getUsers(id, userid, agente);

      const usersLength = users.length;

      if (usersLength > 1) {
        this.gemoSsoStatus = {
          title: langstore.t("ssoGemoLogin_selectUser", "Selezionare un utente"),
          message: "",
          showLoginLink: false
        };

        this.dataGemoSsoUsers = users;
      }

      if (usersLength === 1) {
        await this.loginSsoGemo(users[0]);
      }
    } catch (err) {
      // If agent not enabled then show invalid user page
      if (err.message === 'AGENT_NOT_ENABLED') {
        runInAction(() => {
          this.gemoSsoStatus = {
            title: langstore.t("gemoSsoLogin_invalidUser_title", "Utente non abilitato"),
            message: "",
            showLoginLink: true
          };
          this.loadingRemove(loadingKey);
        });
      }
      // Else show generic error
      else {
        this.failSsoGemo(null, loadingKey);
      }
    } finally {
      this.loadingRemove(loadingKey);
    }
  } catch (err) {
    console.error(err);
    this.failSsoGemo(null, loadingKey);
  }
}


/**
 * Perform Gemo SSO login action
 * @returns {void}
 */
const loginSsoGemo = async function (gemoUserObj) {
  const loadingKey = "loginSsoGemo";

  // Bind functions
  request = request.bind(this);

  try {
    let gemoSsoParams = null;
    let authResponse = null;
    const {
      index: route_index,
      document: route_document = "/ordini/dettaglio"
    } = this.config.routes;

    // Reset sso page
    this.setSsoPageLoading();

    // Get params
    try {
      gemoSsoParams = getGemoSsoParams();
    } catch (err) {
      return this.failSsoGemo(langstore.t("sso_gemo_error_params", "Formato URL errato"), loadingKey);
    }

    const {
      op,
      n_anno,
      c_numeratore,
      n_ordine
    } = gemoSsoParams;

    // showLoader
    this.loadingAdd(loadingKey);

    // Get auth data
    try {
      authResponse = await getSsoAuthResponse(gemoUserObj);
    } catch (err) {
      return this.failSsoGemo(null, loadingKey);
    }

    /**
     * Moved authentication below. There is risky, can produce an infinite loop (if there is an error after auth inside the code)
     * TODO: Check if infinite loop can be avoided 
     */
    // this.userAuth(authResponse);

    switch (op) {
      case 'LOGIN':
        runInAction(() => {
          this.userAuth(authResponse);
          this.routeChange(route_index);
        });
        break;
      case 'DOC':
        // Join path parts
        const documentRoute = [
          route_document,
          n_anno,
          c_numeratore,
          n_ordine
        ].reduce((acc, part) => {
          // replace backslashes with forwardslashes, then remove heading and trailing forwardslashes
          part = part.replace(/\\/g, '/').replace(/^[\/]|[\/]$/g, '');

          return [acc, part].join("/");
        }, '');

        runInAction(() => {
          this.userAuth(authResponse);
          this.routeChange(documentRoute);
        });
        break;
    }

    this.loadingRemove(loadingKey);
  } catch (err) {
    console.error(err);
    this.failSsoGemo(null, loadingKey);
  }
}


export {
  failSsoGemo,
  setSsoPageLoading,
  getSsoGemoParams,
  getSsoGemoUsers,
  loginSsoGemo
}