import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'


export default inject("rootstore")(observer((props) => {
  return(
    <div className="ts_loading_wrap">

      <div id="cssload-pgloading">
        <div className="cssload-loadingwrap">
          <ul className="cssload-bokeh">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
      
    </div>
  )
}));