import React from 'react';
import ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';

// Devextreme
import itMessages from "devextreme/localization/messages/it.json";
import frMessages from "devextreme/localization/messages/fr.json";
import deMessages from "devextreme/localization/messages/de.json";
import nlMessages from "devextreme/localization/messages/nl.json";
import esMessages from "devextreme/localization/messages/es.json";

import { locale, loadMessages, formatMessage } from 'devextreme/localization';
//import 'devextreme-intl'; -- already present in devextreme since v19.2

// Routers
import { RouterContent, RouterNavigation, RouterSidebar, RouterPopup } from '../components/router.jsx';

// Views
import Notifications from '../components/notifications.jsx';
import SidebarMenu from '../components/sidebarmenu.jsx';
import SidebarMenuMobile from '../components/sidebarmenu_mobile.jsx';
import UserOptions from '../components/useroptions.jsx';
import Bookmark from '../components/bookmark.jsx';
import Offline from '../components/offline.jsx';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

const Base = inject("rootstore", "langstore")(observer(

	class Base extends React.Component {

		constructor(props) {
			super(props);
			loadMessages(itMessages);
			loadMessages(frMessages);
			loadMessages(deMessages);
			loadMessages(nlMessages);
			loadMessages(esMessages);
		}

		render() {
			const { rootstore } = this.props;
			const {
				config,
				bodyClassCurrent,
				uiSidebarExpanded
			} = rootstore;

			let props = this.props;
			locale(props.rootstore.language || navigator.language || navigator.browserLanguage);
			let scrollInfo = props.rootstore.uiScrollInfo > 1 ? 'active' : 'inactive';

			const routes = props.rootstore.routes;
			const { routeId } = props.rootstore.routeParamsCurrent;

			const {
				navigation,
				sidebar
			} = routes.find(r => routeId === r.id) || {};

			const sidebarClass = `ts_sidebar col ${uiSidebarExpanded === true ? "ts_sidebar_logo col-3 ts_sidebar_expanded" : "col ts_sidebar_minimized"}`;
			const fullLogo = config.paths.logoExpanded;
			const miniLogo = config.paths.logoSquare;

			return (

				<div className={`ts_base ${bodyClassCurrent}`}>

					<RouterPopup />
					<Offline />

					{navigation &&
						<div className="row no-gutters ts_header_row">
							<aside className={sidebarClass}>
								<div className="ts_sidebar_header">
									{config.app.clientLinkUrl &&
										<a
											target="_blank"
											className="ts_sidebar_header_link"
											href={config.app.clientLinkUrl}
										>{config.app.clientLinkLabel}</a>
									}
									<div
										className={`d-none ${uiSidebarExpanded ? "d-md-block" : ""}`}
										onClick={() => rootstore.routeChange(config.routes.index)}
									>
										<img src={fullLogo} />
									</div>

									<div
										className={`d-block ${uiSidebarExpanded ? "d-md-none" : ""}`}
										onClick={() => rootstore.routeChange(config.routes.index)}
									>
										<img className="anticollapse_image" src={miniLogo} />
										<img className="actual_image" src={miniLogo} />
									</div>
								</div>
							</aside>

							<div className="col">

								<div className={"row no-gutters ts_content_header " + scrollInfo}>

									<div className="col d-none d-md-flex align-items-center">

										<Bookmark />
										<RouterNavigation />

									</div>

									<div className="col d-flex justify-content-end">
										<UserOptions />
									</div>

								</div>

							</div>


						</div>
					}

					<div className="row no-gutters ts_content_row">
						{sidebar &&
							<aside className={props.rootstore.uiSidebarExpanded === true ? "ts_sidebar col-md-3 col-12 ts_sidebar_expanded" : "ts_sidebar col ts_sidebar_minimized"}>
								<div className="ts_sidebar_body">

									<RouterSidebar />

								</div>
							</aside>
						}

						<section className="ts_body col-12 col-md">

							<div className="ts_content_body">

								<RouterContent />

								<Notifications />

							</div>

						</section>

					</div>
				</div>

			)
		}
	}

));

export default Base;