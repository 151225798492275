import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'


export default inject("rootstore")(observer((props) => {
  return(
    <div className="ts_development_banner">
      <i className="icon-wrench icon_m"/>
    	<div style={{
        color: props.rootstore.config.development.alertColor, 
        fontSize: props.rootstore.config.development.alertFontSize,
        backgroundColor: props.rootstore.config.development.alertBackground,
        opacity: props.rootstore.config.development.alertOpacity
      }}
      className="ts_development_banner_info">
        Questo è un server di test all'indirizzo {props.rootstore.config.app.path}</div>
    </div>
  )
}));