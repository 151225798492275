import { Request, Response } from '../../system/utilities/network.js';
import { toJS, runInAction } from 'mobx';
import moment from 'moment';
import langstore from '../../system/i18n/translator.js';

const mailModalOpen = async function(params,options){
  runInAction( ()=> {
    this.modalOpen("ModalEmail", params);
  });
}

const orderEmailSend = async function(params,options){
  this.notificationAdd(langstore.t("sending_email","Invio email in corso"),"sendingmail");

  const _params = {
    ...params
  }

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'email/send/order',
    cacheAge: 1,
    params: _params,
    userToken: this.userToken
  });

  // business logic
  runInAction( ()=> {
    if(response){
      this.notificationAdd(langstore.t("email_sent","Email inviata correttamente"),"emailsent");
      this.modalClose();
    } else {
      this.notificationAdd(langstore.t("email__error","Errore invio mail"),"emailnotsent", null, 'error');
    }
  });
}

const clientEmailSend = async function(params,options){
  this.notificationAdd(langstore.t("sending_email","Invio email in corso"),"sendingmail");

  const _params = {
    ...params
  }

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'email/send/clientcorrespondent',
    cacheAge: 1,
    params: _params,
    userToken: this.userToken
  });

  // business logic
  runInAction( ()=> {
    if(response){
      this.notificationAdd(langstore.t("email_sent","Email inviata correttamente"),"emailsent");
      this.modalClose();
    } else {
      this.notificationAdd(langstore.t("email__error","Errore invio mail"),"emailnotsent", null, 'error');
    }
  });
}

const invoiceEmailSend = async function(params,options){
  this.notificationAdd(langstore.t("sending_email","Invio email in corso"),"sendingmail");

  const _params = {
    ...params
  }

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'email/send/invoice',
    cacheAge: 1,
    params: _params,
    userToken: this.userToken
  });

  // business logic
  runInAction( ()=> {
    if(response){
      this.notificationAdd(langstore.t("email_sent","Email inviata correttamente"),"emailsent");
      this.modalClose();
    } else {
      this.notificationAdd(langstore.t("email__error","Errore invio mail"),"emailnotsent", null, 'error');
    }
  });
}

const sendCorrespondentMail  = async function(params,options){
  this.notificationAdd(langstore.t("sending_email","Invio email in corso"),"sendingmail");

  const _params = {
    ...params
  }

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'email/send/correspondent',
    cacheAge: 1,
    params: _params,
    userToken: this.userToken
  });

  runInAction( ()=> {
    if(response){
      this.notificationAdd(langstore.t("email_sent","Email inviata correttamente"),"emailsent");
      this.modalClose();
    } else {
      this.notificationAdd(langstore.t("email__error","Errore invio mail"),"emailnotsent", null, 'error');
    }
  });
}

/* const sendGenericMail = async function(params,options){
  this.notificationAdd(langstore.t("sending_email","Invio email in corso"),"sendingmail");

  const _params = {
    ...params
  }

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'email/send',
    cacheAge: 1,
    params: _params,
    userToken: this.userToken
  });

  /-* runInAction( ()=> {
    if(response){
      this.notificationAdd(langstore.t("email_sent","Email inviata correttamente"),"emailsent");
      this.modalClose();
    } else {
      this.notificationAdd(langstore.t("email__error","Errore invio mail"),"emailnotsent", null, 'error');
    }
  }); *-/
} */

export {
  clientEmailSend,
  mailModalOpen,
  orderEmailSend,
  invoiceEmailSend,
  sendCorrespondentMail/* ,
  sendGenericMail */
}