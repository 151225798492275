import { runInAction } from "mobx";


const eldomInit = async function (params, options) {
  let loadingKey = "eldomInit";

  const { noLoad } = options || {};

  try {

    // spinner
    if (!noLoad) {
      this.loadingAdd(loadingKey);
    }

    await Promise.all([
      // this.eldom(null, { noLoad: true }),
      this.eldomTypes(null, { cacheAge: 1, noLoad: true }),
      this.eldomBrands(null, { cacheAge: 1, noLoad: true }),
      this.eldomDiscounts(null, { cacheAge: 1, noLoad: true })
    ]);
  } catch (err) {
    console.error(err);
  } finally {
    this.loadingRemove(loadingKey);
  }
}

const eldom = async function (params, options) {
  const cacheID = "eldom";
  let loadingKey = "eldom";

  const {
    config,
    userToken
  } = this;
  const { paths, cacheAPI } = config || {}
  const { cacheAge, noLoad } = options || {};

  const {
    PageNumber,
    tipologia,
    marca,
    descrizione
  } = this.routeParamsCacheGet(cacheID);



  const _params = {
    "PageNumber": PageNumber || 1
  }

  if (tipologia) {
    _params["tipologie"] = tipologia;
  }

  if (marca) {
    _params["marche"] = marca;
  }

  if (descrizione) {
    _params["descrizione"] = descrizione;
  }

  if (!noLoad) {
    // spinner
    this.loadingAdd(loadingKey);
  }

  // API call
  const response = await this.dataLayer({
    url: `${paths.apiURL}eldom`,
    cacheAge: cacheAge || cacheAPI.default,
    params: _params,
    userToken
  });

  // business logic
  runInAction(() => {
    if (response) {


      this.routeParamsCacheSave2(cacheID, _params);

      this.dataEldom = response.data.eldom;
    }
    if (!noLoad) {
      this.loadingRemove(loadingKey);
    }
  });

}

const eldomTypes = async function (params, options) {
  let loadingKey = "eldomTypes";

  const {
    config,
    userToken
  } = this;

  const { paths, cacheAPI } = config || {}
  const { cacheAge, noLoad } = options || {};

  if (!noLoad) {
    // spinner
    this.loadingAdd(loadingKey);
  }

  // API call
  const response = await this.dataLayer({
    url: `${paths.apiURL}eldom/tipologie`,
    cacheAge: cacheAge || cacheAPI.default,
    userToken
  });

  // business logic
  runInAction(() => {
    if (response) {
      this.dataEldomTipologie = response.data.tipologie;
    }

    if (!noLoad) {
      this.loadingRemove(loadingKey);
    }
  });
}

const eldomBrands = async function (params, options) {
  let loadingKey = "eldomBrands";

  const {
    config,
    userToken
  } = this;

  const { paths, cacheAPI } = config || {}
  const { cacheAge, noLoad } = options || {};

  if (!noLoad) {
    // spinner
    this.loadingAdd(loadingKey);
  }

  // API call
  const response = await this.dataLayer({
    url: `${paths.apiURL}eldom/marchi`,
    cacheAge: cacheAge || cacheAPI.default,
    userToken
  });

  // business logic
  runInAction(() => {
    if (response) {
      this.dataEldomMarchi = response.data.marchi;
    }

    if (!noLoad) {
      this.loadingRemove(loadingKey);
    }
  });
}

const eldomDiscounts = async function (params, options) {
  let loadingKey = "eldomDiscounts";

  const {
    config,
    userToken
  } = this;

  const { paths, cacheAPI } = config || {}
  const { cacheAge, noLoad } = options || {};

  if (!noLoad) {
    // spinner
    this.loadingAdd(loadingKey);
  }

  // API call
  const response = await this.dataLayer({
    url: `${paths.apiURL}eldom/testosconti`,
    cacheAge: cacheAge || cacheAPI.default,
    userToken
  });

  // business logic
  runInAction(() => {
    if (response) {
      this.dataEldomSconti = response.data.testo;
    }

    if (!noLoad) {
      this.loadingRemove(loadingKey);
    }
  });
}

const showEldomFile = async function (params) {
  const { apiURL } = this.config.paths;
  let location = `${apiURL}eldom/download`;

  const requestData = {
    c_articolo: params.c_articolo,
    mode: "p",
    t: this.userToken
  };

  var windowReference = window.open();

  const queryString = Object.entries(requestData).reduce((acc, [key, value]) => `${acc}${acc ? "&" : ""}${key}=${value}`, "");

  location = `${location}?${queryString}`

  setTimeout(() => {
    windowReference.document.title = "Visualizza PDF";
    windowReference.location = location;
  }, 10);
}

const eldomGeneralCatalogInfo = async function (params, options) {
  let loadingKey = "eldomGeneralCatalogInfo";

  const { config, userToken } = this;
  const { paths, cacheAPI } = config || {}
  const { cacheAge, noLoad } = options || {};

  if (!noLoad) {
    // spinner
    this.loadingAdd(loadingKey);
  }

  // API call
  const response = await this.dataLayer({
    url: `${paths.apiURL}eldom/infolistinogenerale`,
    cacheAge: cacheAge || cacheAPI.default,
    userToken,
    options: { silent: true }
  });

  // business logic
  runInAction(() => {
    if (response) {
      this.dataInfoEldomListinoGenerale = response.data.info;
    }

    if (!noLoad) {
      this.loadingRemove(loadingKey);
    }
  });
}

const eldomGeneralCatalog = async function (params, options) {
  let loadingKey = "eldomGeneralCatalog";

  const { config, userToken } = this;
  const { paths, cacheAPI } = config || {}
  const { cacheAge, noLoad } = options || {};

  if (!noLoad) {
    // spinner
    this.loadingAdd(loadingKey);
  }

  // API call
  const response = await this.dataLayer({
    url: `${paths.apiURL}eldom/listinogenerale`,
    cacheAge: cacheAge || cacheAPI.default,
    userToken
  });

  // business logic
  runInAction(() => {
    if (response) {
      this.dataEldomListinoGenerale = response.data.listino;
    }

    if (!noLoad) {
      this.loadingRemove(loadingKey);
    }
  });
}

export {
  eldomInit,
  eldom,
  eldomBrands,
  eldomTypes,
  eldomDiscounts,
  showEldomFile,
  eldomGeneralCatalogInfo,
  eldomGeneralCatalog
}