import React from 'react';
import ReactDOM from 'react-dom';
import {inject, observer} from 'mobx-react';
import DevelopmentBanner from '../components/developmentbanner.jsx';
import Tooltip from 'rc-tooltip';


export default inject("rootstore","langstore")(observer((props) => {
	const {rootstore, langstore} = props;
  const isComunicazioniActive = rootstore.config && rootstore.config.comunicazioni === true;
	const {
		selfCliente,
        comunicazioniUnread
	} = rootstore;

  return(
    <div className="ts_user_info text-right">
      {props.rootstore.config.development.production === false && props.rootstore.config.development.showAlert === true && <DevelopmentBanner />}

      {props.rootstore.viewportCurrent === 'mobile' &&
        <span className="ts_user_option" onClick={ ()=> props.rootstore.uiSidebarToggle() }>
          <i className="icon-menu icon_m"/>
        </span>
      }

      {selfCliente &&
        <Tooltip placement="bottom" overlay={langstore.t('header_selfUserProjection','Vai al tuo portale cliente')}>
          <span className="ts_user_option" onClick={()=> props.rootstore.loginasclient({c_nome: selfCliente}) }>
            <i className="icon-person icon_m" />
          </span>
        </Tooltip>
      }

      {isComunicazioniActive &&
        <Tooltip placement="bottom" overlay={langstore.t('header_notifications','Notifiche')}>
          <span className="ts_user_option" onClick={()=> props.rootstore.routeChange("/comunicazioni") }>
            <i className="icon-notifications icon_m" />
            {comunicazioniUnread.length >= 1 && <span className="ts_user_option_count">{comunicazioniUnread.length}</span>}
          </span>
        </Tooltip>
      }

      <Tooltip placement="bottom" overlay={langstore.t('header_dashboard','Dashboard')}>
        <span
          onClick={()=> props.rootstore.routeChange(props.rootstore.config.routes.index) }
          className="ts_user_option "
        >
          <i className="icon-apps icon_m" />
        </span>
      </Tooltip>

			<Tooltip placement="bottom" overlay={langstore.t('header_profile','Profilo')}>
        <span className="ts_user_option" id="ts_user_option_login" onClick={()=> props.rootstore.routeChange("/profile") }>
          <i className="icon-account_circle icon_m" />
        </span>
			</Tooltip>

      {props.rootstore.userTokenRoot !== null &&
				<Tooltip placement="bottom" overlay={langstore.t('header_projection','Proiezione')}>
          <span className="ts_user_option" onClick={()=> props.rootstore.routeChange("/projection") }>
            <i className="icon-people icon_m" />
          </span>
				</Tooltip>   	
      }
    </div>
  )
}));


