import { runInAction } from "mobx";

const cacheId = "statParams";

const getOrdersStats = async function (params, options) {
  const { cacheAge, noLoad } = options || {};

  const {
    userToken,
    config
  } = this

  const {
    format,
    paths,
    cacheAPI
  } = config;

  const loadingKey = 'getOrdersStats';
  const localParams = this.routeParamsCacheGet(cacheId);

  const dates = this.rangeToDates(format.dateRangeDefault);

  if (!noLoad) {
    // spinner
    this.loadingAdd(loadingKey);
  }

  const _params = {
    da_dt_ordine_cliente: localParams.da_data || dates.fr,
    a_dt_ordine_cliente: localParams.a_data || dates.to
  }

  // API call
  const response = await this.dataLayer({
    url: `${paths.apiURL}statistics/orders`,
    cacheAge: cacheAge || cacheAPI.default,
    params: _params,
    userToken
  });

  runInAction(() => {
    if (response) {
      this.dataOrderStats = response.data;
    }

    this.loadingRemove(loadingKey);
  });
}


const getInvoicesStats = async function (params, options) {
  const { cacheAge, noLoad } = options || {};

  const {
    userToken,
    config
  } = this

  const {
    format,
    paths,
    cacheAPI
  } = config;

  const loadingKey = 'getInvoiceStats';
  const localParams = this.routeParamsCacheGet(cacheId);

  const dates = this.rangeToDates(format.dateRangeDefault);

  if (!noLoad) {
    // spinner
    this.loadingAdd(loadingKey);
  }

  const _params = {
    da_dt_fattura: localParams.da_data || dates.fr,
    a_dt_fattura: localParams.a_data || dates.to
  }

  // API call
  const response = await this.dataLayer({
    url: `${paths.apiURL}statistics/invoices`,
    cacheAge: cacheAge || cacheAPI.default,
    params: _params,
    userToken
  });

  runInAction(() => {
    if (response) {
      this.dataInvoiceStats = response.data;
    }

    this.loadingRemove(loadingKey);
  });
}

const getStats = async function (params, options) {
  const loadingKey = 'getStats';

  try {
    const { cacheAge, noLoad } = options || {};

    if (!noLoad) {
      // spinner
      this.loadingAdd(loadingKey);
    }

    const localParams = this.routeParamsCacheGet(cacheId);

    const { statType = "orders" } = localParams;

    switch (statType) {
      case "orders":
        await this.getOrdersStats(null, { noLoad: true, cacheAge });
        break;
      case "invoices":
        await this.getInvoicesStats(null, { noLoad: true, cacheAge });
        break;
    }
  } finally {
    this.loadingRemove(loadingKey);
  }
}

export {
  getOrdersStats,
  getInvoicesStats,
  getStats
}