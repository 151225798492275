import {
  orderEventsInfoCurrent,
  findOrderEventInfoAll,
  orderEventNotificactionCurrent,
  activeOrderEvents
} from '../../src/application/computed/orderEvents.js';


// Application state: plain values

const state = {
  data: {
    ssoClientsUsers: [],
    ssoAgentsUsers: []
  },

  // coda richieste ordine

  dataOrderStates: [],

  // dati delle viste

  dataEventiOrdine: [],
  dataEventiNotifiche: [],

  dataClients: [],
  dataClientsParams: [],
  dataClient: {},

  dataContacts: [],
  dataContactsParams: [],

  dataOrders: [],
  dataOrdersParams: {},
  dataOrdersClient: [],
  dataOrder: {},
  dataOrderVestizione: {},
  dataOrderMessages: [],
  dataOrderMessagesVisible: false,

  
  
  mediaData: [],
  mediaStructure: {},
  mediaTreeView: "grid",
  mediaCurrentPath: [],
  mediaTreeIndex: [],
  mediaTreeLoading: [], // ? NEEDED ?
  mediaTreeItemsData: {},
  mediaItem: null,
  
  

  messages: [],

  dataOrderSets: [],

  dataOrderRelations: {},

  colliOrdine: [],

  dataReclamo: {
    type: null,
    section: 0,
    rows: [],
    pictures: [],
    note: ""
  },

  data3dviewer: {},
  dataAutorizzazioni: {},

  dataInvoices: [],
  dataInvoicesParams: {},
  dataInvoicesParams2: {},
  dataInvoicePreview: "",

  dataPayments: [],
  dataPaymentsParams: {},

  dataUserDetails: {},

  dataDDT: [],
  dataDDTParams: {},

  dataSostituzioni: [],
  //dataSostituzioniParams: {},

  dataPortafoglioordini: [],

  dataPortafoglioreclami: [],

  // info file delle pratiche

  dataDocumentActive: [],
  dataDocumentInfo: {},
  ordersetsParams: {},

  filterBlocked: false,
  filterPending: false,

  gemoSsoStatus: {},

  dataMessaggioComunicazione: ""
}

// Application state: computed values
const computedState = {
  orderEventsInfoCurrent,
  findOrderEventInfoAll,
  orderEventNotificactionCurrent,
  activeOrderEvents
}

export { state, computedState };