import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'


export default inject("rootstore","langstore")(observer((props) => {

  const fontSizes = [
    {name: props.langstore.t("font_xs","Molto piccola"), value: 1},
    {name: props.langstore.t("font_s","Piccola"), value: 2},
    {name: props.langstore.t("font_m","Media"), value: 3},
    {name: props.langstore.t("font_l","Grande"), value: 4},
    {name: props.langstore.t("font_xl","Molto grande"), value: 5}
  ]

  return(
    <div className="ts_forms">

      {props.rootstore.userTokenRoot === null && 
        <div className="row align-items-top">
          <div className="col-md-8">
            <h1 className="style_page_h1 ts_mb8">{ props.langstore.t("user_noprojection","Esegui una proiezione") }<br />{props.rootstore.userInfo.name}</h1>
          </div>
          <div className="col-md-12">          
            TODO lista utenti
          </div>
          <div className="col-md-12">          
            <button className="ts_button_medium1hollow"><i className="icon-people icon_m"/>{props.langstore.t("user_projectionstart","Avvia proiezione")}</button>
          </div>
        </div>
      }

      {props.rootstore.userTokenRoot !== null && 
        <div className="row align-items-top">
          <div className="col-md-8">
            <h1 className="ts_t7 ts_t300 ts_mb8">{ props.langstore.t("user_projectionas","Sei proiettato come") }<br />{props.rootstore.userInfo.name}</h1>
          </div>
          <div className="col-md-12">          
            <button 
              onClick={()=>props.rootstore.userProjectionClose()}
              className="style_button_medium style_button_light"><i className="icon-exit_to_app icon_m"/>{props.langstore.t("user_projectionend","Torna all'utente normale")}</button>
          </div>
        </div>
      }

    </div>

  )
}));