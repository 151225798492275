const menuIcons = {
  clienti: "icon-assignment_ind",
  lista_clienti: "icon-assignment_ind",
  rubrica_clienti: "icon-assignment_ind",
  ordini: "icon-drafts",
  ordini_attivi: "icon-drafts",
  sostituzioni_attive: "icon-drafts",
  portafoglio_ordini: "icon-drafts",
  portafoglio_insiemi_ordini: "icon-drafts",
  ordini_estero: "icon-drafts",
  ordini_attivi_estero: "icon-drafts",
  sostituzioni_attive_estero: "icon-drafts",
  portafoglio_ordini_estero: "icon-drafts",
  portafoglio_insiemi_ordini_estero: "icon-drafts",
  portafoglio_reclami: "icon-drafts",
  ddt: "icon-drafts",
  fatture: "icon-assignment",
  elenco_fatture: "icon-assignment",
  note_accredito: "icon-assignment",
  tweb: "icon-filter_none",
  tweb_redirect_neworder: "icon-filter_none",
  tweb_incomplete_orders: "icon-filter_none",
  tweb_sent_orders: "icon-filter_none",
  estratto_conto: "icon-assignment",
  totalizzazioni_ordini: "icon-drafts",
  reports: "icon-stats-dots",
  elettrodomestici: "icon-home_appliances",
  statistiche: "icon-stats-dots",
  sso: "icon-key",
  sso_clienti: "icon-user",
  sso_agenti: "icon-user",
  documenti: "icon-file-text2"
};


export default menuIcons;