import { runInAction } from 'mobx';
import { Request, Response } from '../../system/utilities/network.js';
import { toJS } from 'mobx';
import moment from 'moment';
import langstore from '../../system/i18n/translator.js';
  
var saveData = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    return function (blob, fileName) {
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName; 
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

const invoicePreview = async function(){

  let loadingKey = 'invoicePreview';
  this.loadingAdd(loadingKey);

  let params = this.routeParamsCurrent;

  let data = {
      n_anno_fattura: parseInt(params.n_anno_fattura),
      c_numeratore_fattura: params.c_numeratore_fattura,
      n_fattura: parseInt(params.n_fattura)
  }
  let p = {
    time: new Date().toLocaleString(),
    clientVersion: "0.0",
    data: data,
  }

  try {
    let xml = await fetch(this.config.paths.apiURL + 'fattura/elettronica',{
      method: "POST",
      body: JSON.stringify(p),
      headers: {
        'x-access-token':this.userToken,
        "Content-Type": "application/json"
      },
    });

    let preview = await xml.json();
    preview = preview.data;

    if(!(preview && preview.fatturaElettronica && preview.foglioStile)){
      throw {
        id: "fileinvalid2",
        message: langstore.t("file_invalid","File non trovato")
      }
    }
  
    var stile = preview.foglioStile.replace(/\u21b5/g,'');
    var fattura = preview.fatturaElettronica.replace(/\u21b5/g,'');
    let style = new window.DOMParser().parseFromString(stile, "text/xml");
    let invoice = new window.DOMParser().parseFromString(fattura, "text/xml");
    var xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(style);
    let fragment = xsltProcessor.transformToFragment(invoice, document);
    setTimeout(()=>{
      document.getElementById("ts_preview_xml").innerHTML = "";
      document.getElementById("ts_preview_xml").appendChild(fragment);
    },100);
  } catch (e) {
    let error = {
      id: "invoicepreviewerror",
      message: langstore.t("invoice_preview_error","Si è verificato un errore")
    }

    if(e && e.id && e.message) {
      error = e;
    }

    this.notificationAdd(error.message, error.id,3000,"error");
    this.routeChange("/fatture");
  }

  this.loadingRemove(loadingKey);
}

const invoiceDownload = async function(params){

  let data = {
      n_anno_fattura: parseInt(params.n_anno_fattura),
      c_numeratore_fattura: params.c_numeratore_fattura,
      n_fattura: parseInt(params.n_fattura)
  }
  let p = {
    time: new Date().toLocaleString(),
    clientVersion: "0.0",
    data: data,
  }

  let xml = await fetch(this.config.paths.apiURL + '/fattura/elettronica/download',{
    method: "POST",
    body: JSON.stringify(p),
    headers: {
      'x-access-token':this.userToken,
      "Content-Type": "application/json"
    },
  });

  let headers = xml.headers;
  let blob = await xml.blob();
  let _filename = headers.get("Content-Disposition");
  let filename = _filename ? _filename.split("filename=").pop() : "temp";

  if(blob.size < 200){
    this.notificationAdd(langstore.t("file_invalid","File non trovato"),"fileinvalid2",3000,"error");
  }
  else{
    this.notificationAdd(langstore.t("download_ready","Download pronto"),"fileinvalid1");
    saveData(blob,filename);
  }
}

const invoiceList = async function(params,options){

  let loadingKey = 'invoiceList';
  let localParams = this.routeParamsCacheGet("invoicesParams"); 
  let dates = this.rangeToDates(this.config.format.dateRangeDefault);

  let _params = {
    "PageNumber": localParams.PageNumber || 1,
    "da_dt_fattura": localParams.da_dt_fattura || dates.fr,
    "a_dt_fattura": localParams.a_dt_fattura || dates.to,
    "tipi_fattura":localParams.tipi_fattura || [1, 3, 4, 5]
  }

  // spinner
  this.loadingAdd(loadingKey);

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'fattura',
    cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
    params: _params,
    userToken: this.userToken
  });

  // business logic
  runInAction( ()=> {
    if(response){
      this.dataInvoices = response.data;
    }
    this.loadingRemove(loadingKey);
  });
}

const paymentList = async function(params,options){

  let loadingKey = 'paymentlist';
  let localParams = this.routeParamsCacheGet("paymentsParams"); 

  let dates = this.rangeToDates(this.config.format.dateRangeDefault);

  let _params = {
    "PageNumber": localParams.PageNumber || 1,
    "da_dt_fattura": localParams.da_dt_fattura || dates.fr,
    "a_dt_fattura": localParams.a_dt_fattura || dates.to,
    "tipi_fattura": localParams.tipi_fattura || [2]
  }


  // spinner
  this.loadingAdd(loadingKey);

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'fattura',
    cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
    params: _params,
    userToken: this.userToken
  });

  // business logic
  runInAction( ()=> {
    if(response){
      this.dataPayments = response.data;
    }
    this.loadingRemove(loadingKey);
  });
}

export {
  invoiceList,
  paymentList,
  invoicePreview,
  invoiceDownload }