import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'

export default inject("rootstore","langstore")(observer((props) => {
  return(
     <div className="ts_forms">
      <div className="row">
        <div className="col-md-6">
          <h1>{ props.langstore.t("ui_errortitle","C'è stato un problema") }</h1>
          <p>{ props.langstore.t("ui_errorsubtitle","Qualcosa non ha funzionato.") }</p>
        </div>
      </div> 
    </div>
  )
}));