import langstore from '../../system/i18n/translator.js';
import { runInAction } from 'mobx';
import { toJS } from 'mobx';


const updateComunicazioni = async function (options) {

  const { dataLayerOptions } = options || {};

  let isComunicazioniActive = this.config && this.config.comunicazioni === true;
  let url = this.config.paths.apiURL + "/comunicazioni";

  if (isComunicazioniActive) {
    let response = await this.dataLayer({
      url: url,
      method: "POST",
      cacheAge: 0,
      userToken: this.userToken,
      options: dataLayerOptions
    });

    if (response && response.data) {
      this.messages = response.data;
    }
  }
};

const updateComunicazioniInit = async function () {
  let isComunicazioniActive = this.config && this.config.comunicazioni === true;
  if (isComunicazioniActive) {
    this.loadingAdd("updateComunicazioniInit");
    await this.updateComunicazioni();
    this.loadingRemove("updateComunicazioniInit");
  }
};

const comunicazioniRead = async function (message) {

  this.loadingAdd("comunicazioniRead");

  let url = this.config.paths.apiURL + "/comunicazioni/detail";

  let response = await this.dataLayer({
    url: url,
    method: "POST",
    cacheAge: 0,
    params: {
      id: message.id_comunicazione
    },
    userToken: this.userToken
  });

  if (response.data) {
    this.modalOpen("ModalMessage", response.data);
  }

  this.loadingRemove("comunicazioniRead");

  // ricarico dati in background
  await this.updateComunicazioni();
};

export {
  updateComunicazioni,
  comunicazioniRead,
  updateComunicazioniInit
}
