import { runInAction } from 'mobx';
import langstore from "System/i18n/translator";

async function getPassword(params, options) {
  const loadingKey = 'getPassword';
  try {
    const { cacheAge, noLoad } = options || {};

    if (!noLoad) {
      this.loadingAdd(loadingKey);
    }

    const { paths, cacheAPI } = this.config;
    const defaultCacheApi = cacheAPI.default;
    const { apiURL } = paths || {};

    // API call
    const { data } = await this.dataLayer({
      url: `${apiURL}password/get`,
      cacheAge: cacheAge || defaultCacheApi,
      params,
      userToken: this.userToken
    });

    return data;
  } finally {
    this.loadingRemove(loadingKey);
  }
}

export {
  getPassword
}
