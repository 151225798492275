import { runInAction } from 'mobx';
import langstore from "System/i18n/translator";

async function getSsoClientsUsers(params, options) {
  const loadingKey = 'getSsoClientsUsers';

  try {
    const { cacheAge, noLoad } = options || {};

    if (!noLoad) {
      this.loadingAdd(loadingKey);
    }

    const { paths, cacheAPI } = this.config;
    const defaultCacheApi = cacheAPI.default;
    const { apiURL } = paths || {};

    // API call
    const response = await this.dataLayer({
      url: `${apiURL}sso/utenti/clienti`,
      cacheAge: cacheAge || defaultCacheApi,
      params,
      userToken: this.userToken
    });

    runInAction(() => {
      this.data.ssoClientsUsers = response.data.utentiClienteSso;
    });
  } finally {
    this.loadingRemove(loadingKey);
  }
}

async function getSsoAgentsUsers(params, options) {
  const loadingKey = 'getSsoAgentsUsers';

  try {
    const { cacheAge, noLoad } = options || {};

    if (!noLoad) {
      this.loadingAdd(loadingKey);
    }

    const { paths, cacheAPI } = this.config;
    const defaultCacheApi = cacheAPI.default;
    const { apiURL } = paths || {};

    // API call
    const response = await this.dataLayer({
      url: `${apiURL}sso/utenti/agenti`,
      cacheAge: cacheAge || defaultCacheApi,
      params,
      userToken: this.userToken
    });

    runInAction(() => {
      this.data.ssoAgentsUsers = response.data.utentiAgenteSso;
    });
  } finally {
    this.loadingRemove(loadingKey);
  }
}

async function getSsoToken(params, options) {
  const loadingKey = 'getSsoToken';

  try {
    const { cacheAge, noLoad } = options || {};

    if (!noLoad) {
      this.loadingAdd(loadingKey);
    }

    const { paths, cacheAPI } = this.config;
    const defaultCacheApi = cacheAPI.default;
    const { apiURL } = paths || {};

    // API call
    const { data } = await this.dataLayer({
      url: `${apiURL}sso/utenti/gettoken`,
      cacheAge: cacheAge || defaultCacheApi,
      params,
      userToken: this.userToken
    });

    return data.sso_token;
  } finally {
    this.loadingRemove(loadingKey);
  }
}

export {
  getSsoClientsUsers,
  getSsoAgentsUsers,
  getSsoToken
}
