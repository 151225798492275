import { computed, toJS, comparer } from 'mobx';
import Route from 'route-parser';

const comunicazioniUnread = function(){
  return this.messages.filter(m=> m.fg_letto === 0)
}

const viewportCurrent = function(){

  let uiDocumentWidth = this.uiDocumentWidth;
  let uiContentWidth = this.uiContentWidth;
  let viewports = this.config.viewports;
  let viewport = 'desktop_large';

  if(uiDocumentWidth >= viewports.desktop_large){
    viewport = 'desktop_large';
  }
  else if(uiDocumentWidth >= viewports.desktop_small){
    viewport = 'desktop_small';
  }
  else if(uiDocumentWidth >= viewports.tablet){
    viewport = 'tablet';
  }
  else{
    viewport = 'mobile';
  }

  return viewport;

}

const routeHierarchy = function(routes){

  let parents = [];
  let children = [];

  // divide parents, children
  for (let i = 0; i < routes.length; i++) {
    let r = routes[i];
    if(r.parent){
      children.push(r);
    }
    else{
      parents.push(r);
    }
  }

  // assign children to parents
  for (let i = 0; i < parents.length; i++) {
    let p = parents[i];
    for (let ii = 0; ii < children.length; ii++) {
      let c= children[ii];
      if(c.parent === p.idMenu){
        if(p.children){
          p.children.push(c);
        }
        else{
          p.children = [c];
        }
      }
    }
  }

  return parents;

}

// create hierarchy; todo: recursive
const routesTreeCurrent = function(){

  let _routes = toJS(this.menus);
  let routesMain = [];
  let routesUser = [];

  if(_routes !== null){
    // filter routes for menu
    for (let i = 0; i < _routes.length; i++) {
      let r = _routes[i];
      if(r.menu === 'main'){
        routesMain.push(r);
      }
    }
    for (let i = 0; i < _routes.length; i++) {
      let r = _routes[i];
      if(r.menu === 'user'){
        routesUser.push(r);
      }
    }

    routesMain = routeHierarchy(routesMain);
    routesUser = routeHierarchy(routesUser);
  }

  return {
    main: routesMain,
    user: routesUser
  }

}


// translate route string in app params
const routeParamsCurrent = function(){

    // default route if no route is matching
    let params = {
      routeId: 'index',
      routeObject: null
    };

    let routes = this.routes;
    let route = this.route;
    let loading =  this.networkLoading;

    if(route === ""){
      route = "/";
    }

    // route matching
    // - route ID
    // - route params
    for (var i = 0; i < routes.length; i++) {
      let r = routes[i];
      let path = new Route(r.path);
      if(r.path){
        let test = path.match(route);
        if(test){
          params = test;
          params.routeId = r.id;
          params.title = r.title || r.id;
          params.unproctected = r.unproctected || false;
          params.routeObject = r;
          break;
        }        
      }
    }

    return params;

}

// translate route string in app params
const routePopupParamsCurrent = function(){

    // default route if no route is matching
    let params = {
      routeId: null
    };

    let routes = this.routes;
    let route = this.routePopup || null;
    let loading =  this.networkLoading;

    // route matching
    // - route ID
    // - route params
    for (var i = 0; i < routes.length; i++) {
      let r = routes[i];
      let path = new Route(r.path);
      if(r.path){
        let test = path.match(route);
        if(test){
          params = test;
          params.routeId = r.id;
          params.title = r.title || r.id;
          break;
        }        
      }
    }

    return params;

}

const userTokenProjectionCurrent = function(){
    var token = this.userToken;
    var tokenProjection = this.tokenProjection;
    if(tokenProjection !== null){
      token = tokenProjection;
    }
    return tokenProjection !== null ? tokenProjection : token;
}


// current view or loading
const routeIdCurrent = function(){
    var routeCurrent = this.routeParamsCurrent.routeId;
    if(this.networkLoading.length >= 1){
      routeCurrent = "loading";
    }
    return routeCurrent;
}

const addParents = function(idMenu,menus,breadcrumb,stop){
  for (var i = 0; i < menus.length; i++) {
    if (menus[i].idMenu === idMenu){
      let r = toJS(menus[i]);
      breadcrumb.push(r);
      if(r.parent){
        addParents(r.parent,menus,breadcrumb);
      }
      break;
    }
  }
}

const pathCurrent = function(){

  var id = this.routeParamsCurrent.routeId;
  var route = null;
  var menu = null;
  var routes = toJS(this.routes);
  var menus = toJS(this.menus);
  var breadcrumb = [];

  if(menus !== null){
    for (var i = menus.length - 1; i >= 0 ; i--) {
      if (menus[i].routeId === id){
        menu = menus[i];
        break;
      }
    }
  }

  if(menu){
    addParents(menu.idMenu,menus,breadcrumb);
  }

  // fallback to current route if no menu detected
  else{

    for (var i = 0; i < routes.length; i++) {
      if (routes[i].id === id){
        route = routes[i];
        break;
      }
    }

    route.idMenu = route.id;
    breadcrumb.push(route);

  }

  breadcrumb.reverse();
  return breadcrumb;
}

// dynamic body class for style pourposes
const bodyClassCurrent = function(){
  return computed( ()=> {
    
    const rootClass = "tesy_app";
    const routeIdCurrent = "tesy_view_" + this.routeIdCurrent;
    const viewportCurrent = "tesy_viewport_" + this.viewportCurrent;
    const userLoggedIn = this.userToken !== null ? "tesy_loggedin" : "tesy_loggedout";
    const uiTableFontSize = "tesy_tablefontsize_" + this.uiTableFontSize;
    const uiFilter = this.uiFilter;

    return [
      rootClass,
      routeIdCurrent,
      userLoggedIn,
      uiTableFontSize,
      viewportCurrent,
      uiFilter
    ].join(" ");
    
  })
}

export { 
  comunicazioniUnread,
  viewportCurrent, 
  routeIdCurrent, 
  bodyClassCurrent, 
  routeParamsCurrent, 
  routesTreeCurrent, 
  pathCurrent, 
  userTokenProjectionCurrent }