import * as common from 'System/actions/common.js';
import * as routes from 'System/actions/routes.js';
import * as user from 'System/actions/user.js';
import * as notifications from 'System/actions/notifications.js';
import * as ui from 'System/actions/ui.js';
import * as files from 'System/actions/files.js';
import * as modals from 'System/actions/modals.js';
import * as developer from 'System/actions/developer.js';
import * as network from 'System/actions/network.js';
import * as comunicazioni from 'System/actions/comunicazioni.js';
import * as sso from 'System/actions/sso.js';
import * as messages from 'System/actions/messages.js';
import * as menu from 'System/actions/menu.js';

const actions = {
  ...common,
  ...routes,
  ...user,
  ...notifications,
  ...ui,
  ...files,
  ...modals,
  ...developer,
  ...network,
  ...comunicazioni,
  ...sso,
  ...messages,
  ...menu
}


export default actions;