import { runInAction, toJS } from "mobx";
import langstore from 'System/i18n/translator.js';

const options = { dataLayerOptions: { noLogoutNotification: true } };

const menuModifiers = async function () {
  const documentiItem = this.menus.find(({ c_menu }) => c_menu === "documenti");

  if (documentiItem) {
    await this.getNewMediaCount(options);

    runInAction(() => {
      documentiItem.badge = {
        label: langstore.t("documents_newCount", { defaultValue: "{{count}} nuovo documento", defaultValue_plural: "{{count}} nuovi documenti", count: this.dataNewMediaCount }),
        labelShort: langstore.t("documents_newCountShort", { defaultValue: "{{count}} nuovo", defaultValue_plural: "{{count}} nuovi", count: this.dataNewMediaCount }),
        count: this.dataNewMediaCount
      }
    });
  }
};


export {
  menuModifiers
}