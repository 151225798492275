import langstore from 'System/i18n/translator.js';

const routes = [{
  id: 'home',
  path: '/',
  navigation: "NavigationTitle",
  content: "Dashboard",
  sidebar: 'SidebarMenu',
  title: 'Index',
  titleKey: "dashboard_favorites",
  titleString: "Preferiti"
}, {
  id: 'reclami',
  path: '/reclamo/:orderYear/:orderNumber/:orderId',
  navigation: "NavigationTitle",
  content: "Dashboard2",
  sidebar: 'SidebarMenu',
  title: 'Nuovo reclamo',
  titleKey: "reclamo_new",
  titleString: "Nuovo reclamo",
  unproctected: true
}, {
  id: 'userprofile',
  path: '/profile',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Profile",
  title: 'Profilo utente',
  titleKey: "user_profile",
  titleString: "Profilo"
}, {
  id: 'comunicazioni',
  path: '/comunicazioni',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Comunicazioni",
  title: 'Comunicazioni',
  action: "updateComunicazioniInit"
}, {
  id: 'userlogin',
  path: '/login',
  action: 'trySsoLogin',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Login",
  title: 'Accedi',
  titleKey: "user_login",
  titleString: "Accedi al portale",
  unproctected: true,
}, {
  id: 'resetpassword',
  path: '/passwordreset',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "PasswordReset",
  title: langstore.t("section_passworsreset", 'Recupero password'),
  titleKey: "section_passworsreset",
  titleString: "Recupero password",
  unproctected: true,
}, {
  id: 'passwordresetrequestok',
  path: '/passwordresetrequestok',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "PasswordResetRequestOk",
  title: langstore.t("section_passworsreset", 'Recupero password'),
  titleKey: "section_passworsreset",
  titleString: "Recupero password",
  unproctected: true,
}, {
  id: 'downloads',
  path: '/downloads',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Downloads",
  title: 'Download'
}, {
  id: 'projection',
  path: '/projection',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Projection",
  title: 'Proiezione'
}, {
  id: 'loading',
  path: "/loading",
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Loading",
  title: 'Caricamento..',
  titleKey: "section_loading",
  titleString: "Caricamento.."
}, {
  id: 'reference',
  path: '/reference',
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Reference",
  title: 'CSS reference',
  unproctected: true,
}, {
  id: 'preview',
  path: '/preview/:n_anno_fattura/:c_numeratore_fattura/:n_fattura',
  title: "Anteprima fattura",
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Preview",
  action: "invoicePreview"
}, {
  id: 'previewfile',
  path: '/previewfile',
  title: "Anteprima fattura",
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Previewfile"
}, {
  id: 'previewfiledata',
  path: '/previewfile/:filedata',
  title: "Anteprima fattura",
  navigation: "NavigationTitle",
  sidebar: 'SidebarMenu',
  content: "Previewfile",
  action: "filePreview"
},
/* Rotte SSO Gemo */
{
  id: 'gemo_sso_page',
  path: '/sso/gemo',
  navigation: "NavigationTitle",
  content: "SsoGemoPage",
  action: "getSsoGemoUsers",
  sidebar: 'SidebarMenu',
  title: 'Login',
  titleKey: "ssogemo_actions",
  titleString: "Login",
  unproctected: true
}
  /* END rotte SSO Gemo */

]


export default routes;