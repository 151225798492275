import { toJS, runInAction } from 'mobx';

const portafoglioordini = async function (params, options) {

	if (options && options.noLoad === true) {
		this.uiGridOptionsExpanded = true;
		return;
	}

	let cacheAge = options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default;
	const cacheID = "portafoglioordiniParams";
	let loadingKey = 'portafoglioordini';
	let localParams = this.routeParamsCacheGet(cacheID);
	let dates = this.rangeToDates(this.config.format.dateRangeDefault);

	await this.updateEventiOrdini();
	if (this.dataEventiOrdine.length >= 1) {
		cacheAge = 1;
	}

	let _params = {
		"PageNumber": localParams.PageNumber || 1,
		"da_dt_aquisizione": localParams.da_dt_aquisizione || dates.fr,
		"a_dt_aquisizione": localParams.a_dt_aquisizione || dates.to
	}

	if (localParams.n_anno) {
		_params['n_anno'] = parseInt(localParams.n_anno);
	}
	if (localParams.c_numeratore) {
		_params['c_numeratore'] = Array.isArray(toJS(localParams.c_numeratore)) ? localParams.c_numeratore : [localParams.c_numeratore];
	}
	if (localParams.n_ordine) {
		_params['n_ordine'] = parseInt(localParams.n_ordine);
	}
	if (localParams.c_stato_ordine) {
		_params['fg_stato_ordine'] = toJS(localParams.c_stato_ordine);
	}
	if (localParams.c_stato_ordine_portale) {
		_params['stati_ordine_portale'] = toJS(localParams.c_stato_ordine_portale);
	}
	if (localParams.c_nome) {
		_params['c_nome'] = [parseInt(localParams.c_nome)];
	}
	if (localParams.ragione_sociale) {
		_params['ragione_sociale'] = [toJS(localParams.ragione_sociale)];
	}
	if (localParams.c_cliente_gruppo) {
		_params['c_cliente_gruppo'] = toJS(localParams.c_cliente_gruppo);
	}
	if (localParams.riferimento_cliente) {
		_params['riferimento_cliente'] = localParams.riferimento_cliente;
	}

	this.loadingAdd(loadingKey);

	let response = await this.dataLayer({
		url: this.config.paths.apiURL + 'custom/pun/ordine/portafoglio',
		cacheAge: cacheAge,
		params: _params,
		userToken: this.userToken
	});

	runInAction(() => {
		if (response) {
			this.routeParamsCacheSave2(cacheID, _params);

			this.dataPortafoglioordini = response.data;
		}
		this.loadingRemove(loadingKey);
	});

}

export {
	portafoglioordini
}