import { runInAction } from "mobx";

const communicationMessage = async function (options) {
  let loadingKey = "communicationMessage";

  const {
    config,
    userToken
  } = this;

  const { paths, cacheAPI } = config || {}
  const { cacheAge, noLoad } = options || {};

  if (!noLoad) {
    // spinner
    this.loadingAdd(loadingKey);
  }

  // API call
  const response = await this.dataLayer({
    url: `${paths.apiURL}common/messaggiocomunicazione`,
    cacheAge: cacheAge || cacheAPI.default,
    userToken
  });

  // business logic
  runInAction(() => {
    if (response) {
      this.dataMessaggioComunicazione = response.data.testo;
    }

    if (!noLoad) {
      this.loadingRemove(loadingKey);
    }
  });
};

export {
  communicationMessage
}
