import { runInAction } from 'mobx';
import { is_touch_device } from '../../system/utilities/devices.js'

const reports = async function (params, options) {
  let loadingKey = 'reports';

  this.loadingAdd(loadingKey);

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'reports/list',
    cacheAge: 1,
    userToken: this.userToken
  });

  runInAction(() => {
    if (response) {
      this.dataReports = response.data;
    }
    this.loadingRemove(loadingKey);
  });
}

const downloadReport = async function (params, options) {
  var location = this.config.paths.apiURL + "reports/download" + "?";

  let requestData = {
    files: encodeURIComponent(JSON.stringify(params)),
    t: this.userToken
  }

  Object.keys(requestData).map(k => {
    location += "&" + k + "=" + requestData[k];
  });

  console.log(location)

  var a = document.getElementById("ts_download_link");
  a.href = location;
  if (is_touch_device()) {
    a.target = "_blank";
  }

  setTimeout(() => {
    a.click();
  }, 10);

  return;
}

export {
  reports,
  downloadReport
}