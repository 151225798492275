import { runInAction } from 'mobx';
import langstore from '../../system/i18n/translator.js';

const uiChangeFilter = function (filter, options) {
  const { noNotification } = options;

  runInAction(() => {
    this.uiFilter = filter;
    if (!noNotification) {
      this.notificationAdd(langstore.t("profile_settingsaved", "Impostazione salvata"), 'uifilter');
    }
  });
}

const uiChangeTableFontSize = function (size, options) {
  const { noNotification } = options;

  runInAction(() => {
    this.uiTableFontSize = size;
    if (!noNotification) {
      this.notificationAdd(langstore.t("profile_settingsaved", "Impostazione salvata"), 'tablefontsize');
    }
  });
}

const uiSidebarToggle = function (bool) {
  let state = !this.uiSidebarExpanded;
  if (typeof bool != 'undefined') {
    state = bool;
  }
  runInAction(() => {
    this.uiSidebarExpanded = state;
  });
}

const uiAreasToggle = function () {
  runInAction(() => {
    this.uiAreasExpanded = !this.uiAreasExpanded;
  });
}

const uiViewModeChange = function (mode) {
  runInAction(() => {
    this.uiViewMode = mode;
  });
}

const uiGridOptionsToggle = function () {
  runInAction(() => {
    let uiGridOptionsExpanded = this.uiGridOptionsExpanded;
    this.uiGridOptionsExpanded = !uiGridOptionsExpanded;
  });
}

const uiGridEventsToggle = function () {
  runInAction(() => {
    let uiGridEventsExpanded = this.uiGridEventsExpanded;
    this.uiGridEventsExpanded = !uiGridEventsExpanded;
  });
}

const uiGridTotalizationsToggle = function () {
  runInAction(() => {
    let uiGridTotalizationsExpanded = this.uiGridTotalizationsExpanded;
    this.uiGridTotalizationsExpanded = !uiGridTotalizationsExpanded;
  });
}

const uiScrollTop = function (selector) {
  document.querySelector(selector).scrollTop = 0
}

const uiScrollInfoUpdate = function (px) {
  this.uiScrollInfo = 50 * Math.floor(px / 50);
}

const scrollIntoView = function (selector) {
  document.querySelector(selector).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
}


export {
  uiSidebarToggle,
  uiAreasToggle,
  uiViewModeChange,
  uiGridOptionsToggle,
  uiGridTotalizationsToggle,
  uiChangeTableFontSize,
  uiScrollTop,
  uiScrollInfoUpdate,
  uiChangeFilter,
  uiGridEventsToggle,
  scrollIntoView
}