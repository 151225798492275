import React from 'react';
import { inject, observer } from 'mobx-react';


const NavigationPath = inject("rootstore", "langstore")(observer(({
  rootstore,
  langstore
}) => {
  const { mediaCurrentPath, mediaData } = rootstore;
  const { titleKey, titleString } = rootstore.pathCurrent[0];

  const onSelectNodeHandler = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    rootstore.mediaItem = null;
    rootstore.selectNode(id);
  }

  return (
    <div className="ts_breadcrumb">
      {mediaCurrentPath.map((nodeId, i) => {
        const isLastElement = mediaCurrentPath.length === (i + 1);

        const parentItemId = i > 0 ? mediaCurrentPath[i - 1] : null;

        const availableItemNodes = !parentItemId
          ? [{
            id: "root",
            id_nodo: "root",
            text: langstore.t(titleKey, titleString)
          }]
          : mediaData[parentItemId];

        if (!availableItemNodes) {
          return <React.Fragment key={`navigation_error_${i}`} />
        }

        const item = availableItemNodes.find(({ id_nodo }) => id_nodo === nodeId);
        // const childrenItems = mediaData[nodeId];
        // const hasChildrenItems = childrenItems && childrenItems.length >= 1 || false;

        /* if (isLastElement) {
          return (
            <p
              className="ts_breadcrumb_inactive ts_label1"
              key={item.id}
            >
              <span>{item.text}</span>
            </p>
          );
        } */

        // 
        if (!item) {
          return <></>
        }

        return (
          <p
            // className={`ts_breadcrumb_active ts_label1 ${hasChildrenItems ? " ts_breadcrumb_menu" : ""}`}
            className="ts_breadcrumb_active ts_label1"
            key={item.id}
          >
            <a
              href="#"
              onClick={(e) => onSelectNodeHandler(e, item)}
            >
              <span className="spantext">{item.text}</span>
              {/* {hasChildrenItems &&
                <span className="ts_breadcrumb_menu_items">
                  {childrenItems.map((i) => {
                    return (
                      <span
                        key={i.id}
                        onClick={(e) => onSelectNodeHandler(e, i)}
                        data-active={nodeId == i.id}
                        className="ts_breadcrumb_menu_item"
                      >
                        {i.text}
                      </span>
                    );
                  })}
                </span>
              } */}
            </a>
            {!isLastElement &&
              <i className="icon-keyboard_arrow_right icon_s" />
            }
          </p>
        );
      })}
    </div >
  )
}));

export default NavigationPath;
